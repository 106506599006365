import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../../colors"; // Importar los colores definidos
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";



export const InnovaSpecificMultiVerticalBars = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate;
  const diaFin = endDate;


  // const stateReportMap = {};
  function capitalizeWords(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }


  let tipoCentroTrabajoMap = {};
  // Transformar el mapa original
  let transformedMap = {};


  chartData.data[0].data[2].forEach((subArray) => {
    let tipoCentro = "unknown";
    let tipoDenuncia = "unknown";
    let catalogo = "unknown";

    subArray.forEach((item) => {
      // Filtra únicamente los centros de trabajo que contengan 'innovasport'
      if (item.key === "C::OWN::donde_sucedieron_los_hechos" && item.value && item.value.includes("INNO")) {
        tipoCentro = capitalizeWords(item.value.replace(/type::/g, "").replace(/_/g, " ").split("::").pop());
        tipoCentro = capitalizeWords(item.value.replace(/type::/g, "").replace(/_/g, " ").split("::")[0]);
      }

      // Identificar el catálogo (padre) y el tipo de denuncia
      if (["C::OWN::tipo_de_reporte"].includes(item.key) && item.value) {
        catalogo = item.key.replace("C::OWN::", ""); // Extrae el nombre del catálogo
        tipoDenuncia = capitalizeWords(item.value.replace(/type::/g, "").replace(/_/g, " "));
      }
    });

    if (tipoCentro.includes("INNO") && catalogo !== "unknown" && tipoDenuncia !== "unknown") {

      // // Verifica si la denuncia es "Conflicto De Interés"
      if (tipoDenuncia === "Conflicto De Interés") {
        tipoDenuncia = "Falta de Probidad::Conflicto De Interés"
      }

      // Si el catálogo no existe, lo inicializamos
      if (!tipoCentroTrabajoMap[catalogo]) {
        tipoCentroTrabajoMap[catalogo] = {};
      }

      // Si el tipo de denuncia no existe, lo inicializamos
      if (!tipoCentroTrabajoMap[catalogo][tipoDenuncia]) {
        tipoCentroTrabajoMap[catalogo][tipoDenuncia] = 0;
      }

      // console.log("TIPO DENTROSNDFKSDFASd", tipoDenuncia);


      // Incrementamos el contador para la categoría general
      tipoCentroTrabajoMap[catalogo][tipoDenuncia]++;
    }

  });

  // Transformar datos y calcular totales
  for (const key in tipoCentroTrabajoMap.tipo_de_reporte) {
    const value = tipoCentroTrabajoMap.tipo_de_reporte[key];

    // Divide las claves que contienen "::"
    const [generalCategory, subCategory] = key.split("::");

    if (!transformedMap[generalCategory]) {
      transformedMap[generalCategory] = {};
    }

    transformedMap[generalCategory][subCategory || ""] = value;
  }

  // Agregar totales por categoría al transformedMap
  for (const generalCategory in transformedMap) {
    let total = 0;

    // Sumar valores de todas las subcategorías
    for (const subCategory in transformedMap[generalCategory]) {
      total += transformedMap[generalCategory][subCategory];
    }

    // Agregar el total al inicio con el mismo nombre de la categoría
    transformedMap[generalCategory][generalCategory] = total;
  }

  // Mostrar el resultado final
  // console.log("Mapa Transformado con Totales por Categoría:", transformedMap);



  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {

        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        root.setThemes([am5themes_Animated.new(root)]);


        const newChart = root.container.children.push(am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          layout: root.horizontalLayout,
          paddingLeft: 0
        }));

        // Leyenda
        const legendData = [];
        const legend = newChart.children.push(am5.Legend.new(root, {
          nameField: "name",
          fillField: "color",
          strokeField: "color",
          marginLeft: 20,
          y: 20,
          layout: root.verticalLayout,
          clickTarget: "none"
        }));

        // Datos (ejemplo simplificado)
        const data = [];

        for (let centro in transformedMap) {
          for (let tipoDenuncia in transformedMap[centro]) {
            data.push({
              centroTrabajo: centro,
              tipoDenuncia: tipoDenuncia,
              count: transformedMap[centro][tipoDenuncia]
            });
          }
        }



        // Eje Y
        const yAxis = newChart.yAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: "tipoDenuncia",
          renderer: am5xy.AxisRendererY.new(root, {
            minGridDistance: 10,
            minorGridEnabled: true
          }),
          // tooltip: am5.Tooltip.new(root, {})
        }));

        yAxis.get("renderer").labels.template.setAll({
          fontSize: 12,
          location: 0.5
        });

        yAxis.data.setAll(data);

        // Eje X
        const xAxis = newChart.xAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {})
        }));

        // Serie de Columnas
        const series = newChart.series.push(am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "count",
          categoryYField: "tipoDenuncia",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal"
          })
        }));

        series.columns.template.setAll({
          tooltipText: "{categoryY}: [bold]{valueX}[/]",
          width: am5.percent(90),
          strokeOpacity: 0
        });

        // Adaptador de Color por Tipo
        series.columns.template.adapters.add("fill", function (fill, target) {
          if (target.dataItem) {
            // console.log("TARGET DATA ITEM", target.dataItem.dataContext.centroTrabajo);

            switch (target.dataItem.dataContext.centroTrabajo) {
              case "Falta de Probidad":
                return newChart.get("colors").getIndex(0);
              case "Diversidad e Inclusión":
                return newChart.get("colors").getIndex(1);
              case "Dignidad Humana":
                return newChart.get("colors").getIndex(2);
              default:
                return newChart.get("colors").getIndex(3);
            }
          }
          return fill;
        });

        series.data.setAll(data);

        // Rango y Leyenda para los Tipos
        function createRange(label, category, color) {
          const rangeDataItem = yAxis.makeDataItem({ category: category });
          const range = yAxis.createAxisRange(rangeDataItem);

          rangeDataItem.get("label").setAll({
            fill: color,
            text: label,
            location: 1,
            fontWeight: "bold",
            dx: -130
          });

          rangeDataItem.get("grid").setAll({
            stroke: color,
            strokeOpacity: 1,
            location: 1
          });

          rangeDataItem.get("tick").setAll({
            stroke: color,
            strokeOpacity: 1,
            location: 1,
            visible: true,
            length: 130
          });

          legendData.push({ name: label, color: color });
        }

        // Crear rangos y asignar colores en la leyenda
        createRange("Falta de Probidad", "Falta de Probidad", newChart.get("colors").getIndex(0));
        createRange("Diversidad e Inclusión", "Discriminacion", newChart.get("colors").getIndex(1));
        createRange("Dignidad Humana", "Dignidad Humana", newChart.get("colors").getIndex(2));


        legend.data.setAll(legendData);

        var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineX.set("visible", false);
        cursor.lineY.set("visible", false);


        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          })
        });

        //   series.appear();
        newChart.appear(1000, 100);


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });


      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};


export default InnovaSpecificMultiVerticalBars;