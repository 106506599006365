import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { MCButton } from "../MainComponents/Button/Button";
import { Row, Col } from "react-bootstrap";

import { MCLabel } from "../MainComponents/Label/Label";
import { MCCheckbox, MCInput } from "../MainComponents/Input/Input";
import { MCSelect } from "../MainComponents/Select/Select";
import { MCTooltip } from "../MainComponents/Tooltip/Tooltip";

import { utilitiesDashboard, createDashboard, getGraphicsDashboard } from "../../apis/apiDashboard";
import { Loading } from "../Loading/String/Loading";
import { catalogByPart, login } from "../../apis";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { ArrowLeft, TrashCan } from "../Icon";
import "./Modal.scss";









import { NewFilterComponentStacked } from "./StackFilters"
const lang = localStorage.getItem("lang");
const SelectChild = ({ options, selected = null, ...rest }) => {
  return (
    <>
      <MCSelect
        className="m-1"
        options={options}
        {...rest}
        value={options.find((item) => item.selected === true)}
      />
      {selected !== null && (
        <SelectChild
          options={options[selected].childs[0].options}
          selected={options[selected].childs[0].selected}
          {...rest}
        />
      )}
    </>
  );
};

const NewFilterComponent = ({
  getOptionsFromUtilities,
  handleShowNewFilter,
  handleNewFilter,
}) => {
  const [t] = useTranslation("components");
  // Para el titulo del filtro
  const [filterTitle, setFilterTitle] = useState("");
  // Los tipos de filtros que se pueden usar
  const [filterType, setFilterType] = useState([
    { label: t("ModalGraph.filter_by_status"), value: 1, selected: false },
    { label: t("ModalGraph.filter_by_field_form"), value: 2, selected: false },
    { label: t("ModalGraph.filter_by_indicator"), value: 3, selected: false },
    { label: t("ModalGraph.filter_by_entryway"), value: 4, selected: false },
  ]);
  // State para el typo filtro seleccionado
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  // State para las opciones de los filtros
  const [filterOptions, setFilterOptions] = useState([]);
  // State para el filtro seleccionado
  const [filterSelected, setFilterSelected] = useState(null);

  // State para los catalogos origen
  const [catalogue, setCatalogue] = useState("");
  const [is_own, setIsOwn] = useState(false);

  // State para los catalogos anidados
  const [selects, setSelects] = useState([]);

  // State para el valor del filtro aniadido seleccionado
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);

  // State para activar o desactivar el boton de agregar filtro
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // State para agregar un filtro para cada hijo en la ultima seleccion
  const [addEveryChild, setAddEveryChild] = useState(false);


  useEffect(() => {
    // Asignar opciones de filtro
    setFilterOptions([]);
  }, []);

  /**
   * Funcion que permite guardar el titulo
   * del filtro
   */
  const handleFilterTitle = (e) => {
    setFilterTitle(e.target.value);
    if (e.target.value === "") setIsButtonDisabled(true);
    else {
      if ((selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && filterSelected !== null)
        setIsButtonDisabled(false);
      else if (
        selectedFilterType === 2 &&
        filterSelected !== null &&
        selectedFilterValue !== null
      )
        setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite mostar el filtro
   * seleccionado
   */
  const changeFilterType = (e) => {
    // Asignar un valor vacio a los selects
    setSelects([]);
    // Desactivar el boton de agregar filtro
    setIsButtonDisabled(true);

    var filterTypeTmp = _.cloneDeep(filterType);
    setFilterType(
      filterTypeTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar un valor null al filtro seleccionado
    setFilterSelected(null);


    // Asignar opciones de filtro
    if (e.value === 1)
      setFilterOptions(getOptionsFromUtilities("filter_by_status"));


    if (e.value === 2)
      setFilterOptions(getOptionsFromUtilities("filter_fields_report"));

    if (e.value === 3)
      setFilterOptions(getOptionsFromUtilities("filter_by_indicator"));
    if (e.value === 4)
      setFilterOptions(getOptionsFromUtilities("filter_by_entryway"));


    // Asignar filtro seleccionado al estado
    setSelectedFilterType(e.value);
  };


  /**
   * Funcion que permite cambiar el filtro seleccionado
   */
  const changeFilterSelected = (e) => {
    resetCatalogueChilds(e);

    var filterOptionsTmp = _.cloneDeep(filterOptions);
    setFilterOptions(
      filterOptionsTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar filtro seleccionado al estado
    setFilterSelected(e.value);
    // Aqui es donde hay que ver que tipo de filtro es para
    // activar o mantener desactivado el boton de agregar filtro
    if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) {
      if (filterTitle !== "") setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite agregar un nuevo
   * select al filtro
   */
  const handleNestedSelect = async (select_father) => {
    // Valor del filtro seleccionado
    setSelectedFilterValue(select_father.value);

    const origin = select_father.path;
    const itemIndex = origin.split(".").pop();
    const selectedPathArray = origin.split(".");
    var newElements = null;

    selectedPathArray.pop();
    selectedPathArray.pop();

    var selectedPath = "";

    for (let i = 0; i < selectedPathArray.length; i++) {
      if (i === 0) selectedPath = selectedPathArray[i];
      else selectedPath += `.${selectedPathArray[i]}`;
    }

    const newSelects = removeNestedSelects(_.cloneDeep(selects), selectedPath);

    if (select_father.continue !== "") {
      await catalogByPart({
        catalogue: catalogue,
        is_own: is_own,
        path: select_father.value,
      }).then((response) => {
        newElements = response.data;
      });
    }

    _.set(newSelects, `${origin}.selected`, true);

    if (newElements !== null) {
      _.set(newSelects, `${selectedPath}.selected`, itemIndex);
      _.set(newSelects, `${origin}.childs`, [
        createOptionsSelects(newElements, origin),
      ]);
    }

    setSelects(newSelects);
    // Aqui es donde se debe activar el boton de agregar filtro
    if (filterTitle !== "") setIsButtonDisabled(false);
  };

  /**
   * Funcion que permite remover los selects
   * anidados y regresar el valor de selected
   * a false de cada uno de los elementos
   */
  const removeNestedSelects = (data, path) => {
    const selectsTmp = _.get(data, `${path}.options`);

    _.set(
      data,
      `${path}.options`,
      selectsTmp.map((opt) => {
        opt.childs = [];
        opt.selected = false;
        return opt;
      })
    );

    _.set(data, `${path}.selected`, null);

    return data;
  };

  /**
   * Funcion que permite resetear los datos del
   * catalogo seleccionado de la lista de filtros
   * campos del reporte
   */
  const resetCatalogueChilds = (data) => {
    setCatalogue("");
    setIsOwn(false);
    setSelectedFilterValue(null);
    if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) return;

    const originCatalogue = data.value.split(":::").pop().split("::");
    const catalogueLocal = originCatalogue.pop();
    const isOwnLocal = originCatalogue.pop() === "OWN" ? true : false;
    const pathLocal = "/";
    setCatalogue(catalogueLocal);
    setIsOwn(isOwnLocal);

    catalogByPart({
      catalogue: catalogueLocal,
      is_own: isOwnLocal,
      path: pathLocal,
    }).then((response) => {
      setSelects([createOptionsSelects(response.data)]);
    });
  };

  /**
   * Funcion que permite crear las opciones
   * de los selects anidados
   */
  const createOptionsSelects = (data, origin = null, selected = null) => {
    return {
      selected: selected,
      options: data.map((item, idx) => {
        return {
          label: item.label,
          value: item.path_locales,
          continue: item.path,
          path: origin
            ? `${origin}.childs.0.options.${idx}`
            : `0.options.${idx}`,
          childs: [],
          selected: false,
        };
      }),
    };
  };

  /**
   * Funcion que recolecta los datos del filtro
   * y los agrega a la lista de filtros
   */
  const handleAddFilter = () => {
    handleNewFilter({
      label: filterTitle,
      function:
        (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4)
          ? filterSelected
          : `${filterSelected}?${selectedFilterValue}`,
    });
    // Aqui es donde se regresa a la pantanlla del
    // cuerpo del filtro
    handleShowNewFilter(false);
  };
  /**
   * Funcion para buscar en el schema el ultimo
   * scope de hijos que hay en las selecciones
   */
  const recursiveReturnFilters = (childs, lastOptions) => {
    if (Array.isArray(childs) && childs.length > 0) {
      const options = childs[0].options || [];
      const idxOpt = options.findIndex((opt) => opt.selected === true);
      if (idxOpt >= 0) {
        return recursiveReturnFilters(options[idxOpt].childs, options);
      } else {
        return options;
      }
    } else {
      return lastOptions;
    }
  };
  /**
   * Funcion para agregar un filtro
   * por cada hijo que halla en el primer scope
   */
  const handleAddFilterForEveryChild = () => {
    const currentFilters = recursiveReturnFilters(selects);
    let allFilters;
    if (addEveryChild && Array.isArray(currentFilters)) {
      allFilters = currentFilters.map((item) => {
        return {
          // label: `${filterTitle} - ${item.label}`,
          label: `${item.label}`,
          function:
            (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4)
              ? filterSelected
              : `${filterSelected}?${item.value}`,
        };
      });
    }
    if (allFilters) {
      handleNewFilter(allFilters, true);
      handleShowNewFilter(false);
    }
  };
  /**
   * Retorna un valor para habilitar o inhabilitar el
   * boton para agregar un filtro por cada hijo
   */
  const isButtonAddFilterForEveryChildDisabled = () => {
    if (filterTitle.trim() === "") {
      return true;
    }
    if (filterSelected === null) {
      return true;
    }
    if (!Array.isArray(selects[0]?.options)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="filter-modal">
        <MCLabel text={`${t("ModalGraph.data_series_name")}:`} />
        <MCInput
          type="text"
          name="title"
          className="m-1"
          placeholder={t("ModalGraph.enter_category_name")}
          autoComplete="off"
          value={filterTitle}
          onChange={handleFilterTitle}
        />
      </div>

      <div>
        <MCLabel text={`${t("ModalGraph.select_filter")}:`} />
        <MCSelect
          className="m-1"
          options={filterType}
          onChange={(e) => changeFilterType(e)}
          value={filterType.find((item) => item.selected === true)}
        />
      </div>

      {(selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && (
        <div>
          <MCLabel text={`${t("ModalGraph.filter_by_status")}:`} />
          <MCSelect
            className="m-1"
            options={filterOptions}
            onChange={(e) => changeFilterSelected(e)}
            value={filterOptions.find((item) => item.selected === true)}
          />
        </div>
      )}

      {selectedFilterType === 2 && (
        <div>
          <div className="d-flex justify-content-between">
            <MCLabel text={`${t("ModalGraph.filter_by_field_form")}:`} />
            <div className="d-flex align-items-center">
              <MCCheckbox
                id="agregar_un_filtro_por_cada_opcion_del_catalogo"
                className="me-2"
                checked={addEveryChild}
                onChange={(e) => setAddEveryChild(e.target.checked)}
              />
              <MCLabel
                htmlFor="agregar_un_filtro_por_cada_opcion_del_catalogo"
                text={t("ModalGraph.include_all")}
              />
            </div>
          </div>
          <MCSelect
            className="m-1"
            options={filterOptions}
            onChange={(e) => changeFilterSelected(e)}
            value={filterOptions.find((item) => item.selected === true)}
          />
        </div>
      )}

      {selects.length > 0 && <hr className="my-4" />}

      {selects.map((item) => {
        return (
          <SelectChild
            options={item.options}
            selected={item.selected}
            onChange={(e) => handleNestedSelect(e)}
          />
        );
      })}
      <br />
      <div className="add-indicator">
        {!addEveryChild ? (
          <MCButton
            label={t("ModalGraph.create")}
            variant={isButtonDisabled ? "secondary" : "success"}
            onClick={handleAddFilter}
            disabled={isButtonDisabled}
          />
        ) : (
          <MCButton
            label={t("ModalGraph.add")}
            variant={
              isButtonAddFilterForEveryChildDisabled() ? "secondary" : "success"
            }
            onClick={handleAddFilterForEveryChild}
            disabled={isButtonAddFilterForEveryChildDisabled()}
          />
        )}
      </div>
    </>
  );
};

/**
 * componente que permite construir los indicadores
 */
const IndicatorComponent = ({
  getOptionsFromUtilities,
  showIndicator = null,
  listIndicators = null,
  fnLoadingIndicator = null,
  fnCloseModal = null,
  setShowMainOptions,
}) => {
  const [t] = useTranslation("components");
  // Para el titulo del filtro
  const [filterTitle, setFilterTitle] = useState("");
  // Los tipos de filtros que se pueden usar
  const [filterType, setFilterType] = useState([
    {
      label: `${t("ModalGraph.filter_by_status")}`,
      value: 1,
      selected: false,
    },
    {
      label: `${t("ModalGraph.filter_by_field_form")}`,
      value: 2,
      selected: false,
    },
    {
      label: `${t("ModalGraph.filter_by_indicator")}`,
      value: 3,
      selected: false,
    },
    {
      label: `${t("ModalGraph.filter_by_entryway")}`,
      value: 4,
      selected: false,
    },
  ]);
  // State para el typo filtro seleccionado
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  // State para las opciones de los filtros
  const [filterOptions, setFilterOptions] = useState([]);
  // State para el filtro seleccionado
  const [filterSelected, setFilterSelected] = useState(null);

  // State para los catalogos origen
  const [catalogue, setCatalogue] = useState("");
  const [is_own, setIsOwn] = useState(false);

  // State para los catalogos anidados
  const [selects, setSelects] = useState([]);

  // State para el valor del filtro aniadido seleccionado
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);

  // State para mostrar el cuerpo del filtro
  const [showFilterBody, setShowFilterBody] = useState(false);

  // State para activar o desactivar el boton de agregar filtro
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // State para activar o desactivar el boton de crear indicador
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);



  // State donde se almacenan los filtros de los indicadores
  var [stackFilters, setStackFilters] = useState([]);

  useEffect(() => {
    // Asignar opciones de filtro
    setFilterOptions([]);
  }, []);

  /**
   * Funcion que permite guardar el titulo
   * del filtro
   */
  const handleFilterTitle = (e) => {
    setFilterTitle(e.target.value);
    if (e.target.value === "") {
      setIsButtonDisabled(true);
      setIsCreateButtonDisabled(true);
    } else {
      if (stackFilters.length > 0) setIsCreateButtonDisabled(false);
      if ((selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && filterSelected !== null)
        setIsButtonDisabled(false);
      else if (
        selectedFilterType === 2 &&
        filterSelected !== null &&
        selectedFilterValue !== null
      )
        setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite mostar el filtro
   * seleccionado
   */
  const changeFilterType = (e) => {
    // Asignar un valor vacio a los selects
    setSelects([]);
    // Desactivar el boton de agregar filtro
    setIsButtonDisabled(true);

    var filterTypeTmp = _.cloneDeep(filterType);
    setFilterType(
      filterTypeTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar un valor null al filtro seleccionado
    setFilterSelected(null);

    // Asignar opciones de filtro
    if (e.value === 1)
      setFilterOptions(getOptionsFromUtilities("filter_by_status"));
    if (e.value === 2)
      setFilterOptions(getOptionsFromUtilities("filter_fields_report"));
    if (e.value === 3)
      setFilterOptions(getOptionsFromUtilities("filter_by_indicator"));
    if (e.value === 4)
      setFilterOptions(getOptionsFromUtilities("filter_by_entryway"));

    // Asignar filtro seleccionado al estado
    setSelectedFilterType(e.value);
  };

  /**
   * Funcion que permite cambiar el filtro seleccionado
   */
  const changeFilterSelected = (e) => {
    resetCatalogueChilds(e);

    var filterOptionsTmp = _.cloneDeep(filterOptions);
    setFilterOptions(
      filterOptionsTmp.map((item) => {
        if (item.value === e.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    // Asignar filtro seleccionado al estado
    setFilterSelected(e);
    // Aqui es donde hay que ver que tipo de filtro es para
    // activar o mantener desactivado el boton de agregar filtro
    if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) {
      if (filterTitle !== "") setIsButtonDisabled(false);
    }
  };

  /**
   * Funcion que permite agregar un nuevo
   * select al filtro
   */
  const handleNestedSelect = async (select_father) => {
    // Valor del filtro seleccionado
    setSelectedFilterValue(select_father);

    const origin = select_father.path;
    const itemIndex = origin.split(".").pop();
    const selectedPathArray = origin.split(".");
    var newElements = null;

    selectedPathArray.pop();
    selectedPathArray.pop();

    var selectedPath = "";

    for (let i = 0; i < selectedPathArray.length; i++) {
      if (i === 0) selectedPath = selectedPathArray[i];
      else selectedPath += `.${selectedPathArray[i]}`;
    }

    const newSelects = removeNestedSelects(_.cloneDeep(selects), selectedPath);

    if (select_father.continue !== "") {
      await catalogByPart({
        catalogue: catalogue,
        is_own: is_own,
        path: select_father.value,
      }).then((response) => {
        newElements = response.data;
      });
    }

    _.set(newSelects, `${origin}.selected`, true);

    if (newElements !== null) {
      _.set(newSelects, `${selectedPath}.selected`, itemIndex);
      _.set(newSelects, `${origin}.childs`, [
        createOptionsSelects(newElements, origin),
      ]);
    }

    setSelects(newSelects);
    // Aqui es donde se debe activar el boton de agregar filtro
    if (filterTitle !== "") setIsButtonDisabled(false);
  };

  /**
   * Funcion que permite remover los selects
   * anidados y regresar el valor de selected
   * a false de cada uno de los elementos
   */
  const removeNestedSelects = (data, path) => {
    const selectsTmp = _.get(data, `${path}.options`);

    _.set(
      data,
      `${path}.options`,
      selectsTmp.map((opt) => {
        opt.childs = [];
        opt.selected = false;
        return opt;
      })
    );

    _.set(data, `${path}.selected`, null);

    return data;
  };

  /**
   * Funcion que permite resetear los datos del
   * catalogo seleccionado de la lista de filtros
   * campos del reporte
   */
  const resetCatalogueChilds = (data) => {
    setCatalogue("");
    setIsOwn(false);
    setSelectedFilterValue(null);

    if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) return;

    const originCatalogue = data.value.split(":::").pop().split("::");
    const catalogueLocal = originCatalogue.pop();
    const isOwnLocal = originCatalogue.pop() === "OWN" ? true : false;
    const pathLocal = "/";
    setCatalogue(catalogueLocal);
    setIsOwn(isOwnLocal);

    catalogByPart({
      catalogue: catalogueLocal,
      is_own: isOwnLocal,
      path: pathLocal,
    }).then((response) => {
      setSelects([createOptionsSelects(response.data)]);
    });
  };

  /**
   * Funcion que permite crear las opciones
   * de los selects anidados
   */
  const createOptionsSelects = (data, origin = null, selected = null) => {
    return {
      selected: selected,
      options: data.map((item, idx) => {
        return {
          label: item.label,
          value: item.path_locales,
          continue: item.path,
          path: origin
            ? `${origin}.childs.0.options.${idx}`
            : `0.options.${idx}`,
          childs: [],
          selected: false,
        };
      }),
    };
  };

  /**
   * Funcion que permite resetear los valores
   */
  const resetValues = () => {
    setFilterType(
      filterType.map((item) => {
        item.selected = false;
        return item;
      })
    );
    setSelectedFilterType(null);
    setFilterSelected(null);
    setSelectedFilterValue(null);
    setSelects([]);
    setShowFilterBody(false);
    setIsButtonDisabled(true);
  };

  /**
   * Funcion que recolecta los datos del filtro
   * y los agrega a la lista de filtros
   */
  const handleAddFilter = () => {
    if ((selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && filterSelected !== null) {
      setStackFilters([
        ...stackFilters,
        {
          label: filterSelected.label,
          value: filterSelected.value,
        },
      ]);
    } else if (
      selectedFilterType === 2 &&
      filterSelected !== null &&
      selectedFilterValue !== null
    ) {
      setStackFilters([
        ...stackFilters,
        {
          label: `${filterSelected.label} -> ${selectedFilterValue.label}`,
          value: `${filterSelected.value}?${selectedFilterValue.value}`,
        },
      ]);
    }

    // Resetear los valores de los filtros
    resetValues();
    // Activar el boton de crear
    setIsCreateButtonDisabled(false);
  };

  /**
   * Funcion que permite eliminar un filtro
   * de la lista de filtros
   */
  const handleDeleteFilter = (filter) => {
    setStackFilters(stackFilters.filter((item) => item.value !== filter.value));
    if (stackFilters.length === 1) {
      setIsCreateButtonDisabled(true);
    }
  };

  /**
   * Funcion que permite crear el indicador
   */
  const handleCreateIndicator = async () => {
    const title = filterTitle;
    var filter_param = stackFilters.map((item) => item.value);
    const show_indicator = "show_indicators#type#num";
    const metadata = {};
    const indicatorType = typeFilterFilter;
    var SpecialIndicator = "";

    if (indicatorType !== 1) {
      if (indicatorType === 2) {
        filter_param = ['filter_fields_report#type#business_unit:::C::OWN::business_units?type::primeraunidaprueba'];
        SpecialIndicator = "SpecialIndicator_MoreUnitBusiness";
      } else if (indicatorType === 3) {
        filter_param = ['filter_fields_report#type#business_unit:::C::OWN::business_units?type::primeraunidaprueba'];
        SpecialIndicator = "SpecialIndicator_MoreChannelUsed";
      } else if (indicatorType === 4) {
        filter_param = ['filter_fields_report#type#business_unit:::C::OWN::business_units?type::primeraunidaprueba'];
        SpecialIndicator = "SpecialIndicator_PercentajeSuccesfuly";
      }
    }

    const indicatorItem = {
      title,
      filter_param,
      show_indicator,
      metadata,
      SpecialIndicator
    };

    if (showIndicator !== null && listIndicators !== null) {
      listIndicators(indicatorItem);
      showIndicator(false);
    } else {
      const data = {
        graphics: [],
        indicators: [indicatorItem],
        default_graphics: [],
      };


      const response = await createDashboard(data);
      if (response !== undefined && fnLoadingIndicator !== null) {
        if (fnCloseModal !== null) fnCloseModal(true);
        fnLoadingIndicator(true);
      }
    }
  };

  /**
   * Funcion que permite cancelar la creacion
   */
  const handleCancel = () => {
    if (showIndicator !== null) showIndicator(false);
  };



  const [typeOfDefaultFilter, setDefaultFilter] = useState("");
  const [typeFilterFilter, setTypeFilterFilter] = useState(null); // Como se va a mostrar el grafico

  function getOptionsDefaultFilter(type) {
    if (type === "get_default_filters") {
      return [
        { label: "Filtro personalizado", value: 1 },
        // { label: "Unidad con mas reportes", value: 2 },
        // { label: "Mayor medio de entrada", value: 3 },
        // { label: "Porcentaje resolución de casos", value: 4 },
      ];
    }
  }

  const handleTypeDataFilter = (event) => {
    const selectedValue = event.value;
    setDefaultFilter(event);
    setTypeFilterFilter(selectedValue);
    setDefaultFilter(event);

  };


  return (
    <>
      <Row className="px-1 my-2">
        <MCLabel text={`${t("ModalGraph.indicator_name")}:`} />
        <MCInput
          type="text"
          name="title"
          placeholder={t("ModalGraph.enter_indicator_name")}
          autoComplete="off"
          value={filterTitle}
          onChange={handleFilterTitle}
          className="m-1"
        />
      </Row>
      {!showFilterBody && (
        <Row className="px-2">
          <div className="container-btn-new-filter">
            <MCSelect
              className="m-1"
              options={getOptionsDefaultFilter("get_default_filters")}
              onChange={(e) => handleTypeDataFilter(e)}
              value={typeOfDefaultFilter}
            />
            {typeFilterFilter == 1 && typeFilterFilter != null && (
              <>
                <MCLabel
                  text={`${t("ModalGraph.convine_filters")}:`}
                  helptip={t("ModalGraph.you_can_add_filters")}
                />
                <MCButton
                  label={t("ModalGraph.add_filter")}
                  variant="primary"
                  outline
                  onClick={() => setShowFilterBody(true)}
                  className="px-4"
                />
              </>
            )}
          </div>
        </Row>
      )}

      {showFilterBody && (
        <>
          <Row className="px-1 my-2">
            <MCLabel text={`${t("ModalGraph.select_filter")}:`} />
            <MCSelect
              options={filterType}
              className="m-1"
              onChange={(e) => changeFilterType(e)}
              value={filterType.find((item) => item.selected === true)}
            />
          </Row>

          {(selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && (
            <Row className="px-1 my-2">
              <MCLabel text={`${t("ModalGraph.filter_by_status")}:`} />
              <MCSelect
                className="m-1"
                options={filterOptions}
                onChange={(e) => changeFilterSelected(e)}
                value={filterOptions.find((item) => item.selected === true)}
              />
            </Row>
          )}

          {selectedFilterType === 2 && (
            <Row className="px-1 my-2">
              <MCLabel text={`${t("ModalGraph.filter_by_field_form")}:`} />
              <MCSelect
                className="m-1"
                options={filterOptions}
                onChange={(e) => changeFilterSelected(e)}
                value={filterOptions.find((item) => item.selected === true)}
              />
            </Row>
          )}

          {selects.map((item) => {
            return (
              <Row>
                <SelectChild
                  options={item.options}
                  selected={item.selected}
                  onChange={(e) => handleNestedSelect(e)}
                />
              </Row>
            );
          })}

          <br />
          <hr />
          <div className="add-indicator">

            <MCButton
              label={t("ModalGraph.add_filter")}
              variant="primary"
              outline
              onClick={handleAddFilter}
              disabled={isButtonDisabled}
            />

            <MCButton
              label={t("ModalGraph.cancel")}
              variant="danger"
              className="mx-2"
              outline
              onClick={resetValues}
            />
          </div>
        </>
      )}

      {stackFilters.length > 0 && !showFilterBody && (
        <Row>
          <div className="stack-filters-container">
            {stackFilters.map((item, idx) => {
              return (
                <div className="item-filter">
                  <MCLabel text={item.label} />
                  <MCTooltip text={t("ModalGraph.delete")} position="bottom">
                    <div>
                      <TrashCan
                        className="trash"
                        onClick={() => handleDeleteFilter(item)}
                      />
                    </div>
                  </MCTooltip>
                </div>
              );
            })}
          </div>
        </Row>
      )}

      {!showFilterBody && (
        <>
          <hr />
          <div className="footer-modal">
            {!showIndicator ? (
              <div>
                <MCTooltip text={t("ModalGraph.back")} position="top">
                  <button
                    id="return"
                    label={t("ModalGraph.back")}
                    onClick={() => setShowMainOptions(true)}
                    className="turnBack"
                  >
                    <ArrowLeft width={25} heigth={30} />
                  </button>
                </MCTooltip>
              </div>
            ) : (
              <div>{""}</div>
            )}
            <div>
              {typeFilterFilter == 1 && typeFilterFilter != null && (
                <>
                  <MCButton
                    label={t("ModalGraph.create")}
                    variant={isCreateButtonDisabled ? "secondary" : "success"}
                    disabled={isCreateButtonDisabled}
                    onClick={handleCreateIndicator}
                  />
                </>
              )}
              {typeFilterFilter != null && typeFilterFilter != 1 && (
                <>
                  <MCButton
                    label={t("ModalGraph.create")}
                    variant={isCreateButtonDisabled ? "secondary" : "success"}
                    onClick={handleCreateIndicator}
                  />
                </>
              )}
              {showIndicator !== null && (
                <MCButton
                  label={t("ModalGraph.cancel")}
                  variant="danger"
                  className="mx-2"
                  outline
                  onClick={handleCancel}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ModalGraph = (props) => {
  const [t] = useTranslation("components");
  const { gTheme } = useSelector((state) => state.theme);
  const theme = gTheme !== "light" ? "dark" : "";

  const [showMainOptions, setShowMainOptions] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [stateData, setStateData] = useState(null); // Aqui es donde se almacena las utilidades del dashboard

  /**
   * Aqui es donde iran los datos que se van a mostrar en el grafico
   */
  const [titleGrafic, setTitleGrafic] = useState(""); // Titulo del grafico
  const [typeDataGrafic, setTypeDataGrafic] = useState("get_data#type#count"); // Que tipo de datos se van a mostrar en el grafico
  //mucho cuidado con el estado de setTypeDataGrafic, originalmente se inicializa en null, pero debido a que se comentó ese select, para evitar modificar todo el código innecesariamente
  //solamente se colocó otro estado incial para que funcione el código =)
  const [typeGrafic, setTypeGrafic] = useState(null); // Como se va a mostrar el grafico
  const [typeGraficGrafic, setTypeGraficGrafic] = useState(null); // Como se va a mostrar el grafico

  const [showNewFilter, setShowNewFilter] = useState(false); // Para mostrar el modal de nuevo filtro
  const [showNewIndicator, setShowNewIndicator] = useState(false); // Para mostrar el modal de nuevo indicador

  var [stackFilters, setStackFilters] = useState([]); // Aqui se almacenan los filtros que se van a aplicar al grafico
  const [stackIndicators, setStackIndicators] = useState([]); // Aqui se almacenan los indicadores que se van a aplicar al grafico

  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Para desactivar el boton de agregar filtro


  const [typeOfBar, setTypeOfBar] = useState(null);
  const [typeOfCircleChart, setCircleTypeOfChart] = useState("");
  const [typeOfBarChart, setBarTypeOfChart] = useState("");
  const [typeOfHistoricChart, setHistoricTypeOfChart] = useState("");
  const [typeOfRadarChart, setRadarTypeOfChart] = useState("");
  const [typeOfGeographicChart, setGeographicTypeOfChart] = useState("");
  const [typeOfSpecialChart, setSpecialTypeOfChart] = useState("");
  let [contadorGrafica, setContadorGrafica] = useState(0);
  const limiteGrafica = 99;
  let [contadorIndicador, setContadorIndicador] = useState(0);
  const limiteIndicador = 99;
  const [showNewFilterStacked, setShowNewFilterStacked] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedIndexStacked, setSelectedIndexStacked] = useState(null);



  const [addTwoColumns, setAddTwoColumns] = useState(true);

  const handleShowNewFilterStacked = (label, i) => {
    setSelectedLabel(label);
    setSelectedIndexStacked(i);
    setShowNewFilterStacked(true);

  };



  /**
   * Funciones
   */

  const [elementOptions, setElementOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseGraphics = await getGraphicsDashboard();

        setContadorIndicador(Object.keys(responseGraphics.indicators).length)
        setContadorGrafica(Object.keys(responseGraphics.graphics).length);


        const options = [];

        if (Object.keys(responseGraphics.graphics).length < limiteGrafica) {
          options.push({ label: t("ModalGraph.graph"), value: 1, selected: false });
        } else {
          options.push({ label: t("ModalGraph.graph"), value: 1, selected: false, limit: true, isDisabled: true });
        }

        // Agrega opción "indicator" con la propiedad "isDisabled" si se excede el límite
        if (Object.keys(responseGraphics.indicators).length < limiteIndicador) {
          options.push({ label: t("ModalGraph.indicator"), value: 2, selected: false });
        } else {
          options.push({ label: t("ModalGraph.indicator"), value: 2, selected: false, limit: true, isDisabled: true });
        }


        setElementOptions(options);

      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Asegúrate de manejar los errores apropiadamente
      }

    }
    utilitiesDashboard().then((response) => {
      setStateData(response);
      
      setIsLoading(false);
    });
    fetchData();


  }, []);

  const handleSelectOption = (option) => {
    setElementOptions(
      elementOptions.map((item) => {
        if (item.value === option.value) item.selected = true;
        else item.selected = false;
        return item;
      })
    );

    setShowMainOptions(false);
  };

  function getOptionsCircleChart(type) {
    if (type === "get_data_circle") {
      return [
        { label: t("ModalGraph.pie_chart"), value: 1 },
        { label: t("ModalGraph.donut_chart"), value: 2 },
        { label: t("ModalGraph.double_chart"), value: 8 },
      ];
    } else if (type === "get_data_bar") {
      return [
        { label: t("ModalGraph.horizontal_chart"), value: 3 },
        { label: t("ModalGraph.vertical_chart"), value: 4 },
        { label: t("ModalGraph.pareto_chart"), value: 5 },
      ];
    } else if (type === "get_data_historic") {
      return [
        { label: t("ModalGraph.lines_chart_with_scale"), value: 6 },
        { label: t("ModalGraph.lines_chart_without_scale"), value: 7 },
        { label: t("ModalGraph.steps_chart"), value: 9 },
        { label: t("ModalGraph.bars_chart"), value: 11 },
        { label: "Distribución de las denuncias a traves de los meses", value: 23 },
        { label: "Distribución de las denuncias a traves de los meses (Gráfica de Barras)", value: 100 }
      ];
    } else if (type === "get_data_radar") {
      return [
        { label: t("ModalGraph.radar_chart"), value: 10 },
      ];
    // } else if (type === "get_data_geographic") {
    //   return [
    //     { label: t("ModalGraph.world_chart"), value: 12 },
    //     { label: t("ModalGraph.mexico_chart"), value: 13 },
    //   ];
    } else if (type === "get_data_special") {
      return [
        { label: t("ModalGraph.invalid_reports"), value: 14 },
        // { label: t("ModalGraph.severe_complaints_by_state"), value: 16 },
        { label: t("ModalGraph.double_lines_open_closed"), value: 17 },
        // { label: t("ModalGraph.reports_by_severity"), value: 18 },
        // { label: t("ModalGraph.stacked_state_bars"), value: 19 },
        // { label: t("ModalGraph.gender_chart"), value: 20 },
        // { label: t("ModalGraph.state_severity_distribution"), value: 21 },
        // { label: t("ModalGraph.state_report_type_distribution"), value: 22 },

        // {label: "Análisis Denuncia por Estado Grupo Innovasport (Gráfica de distribución)", value:23},
        // {label: "GRAFICA INNVICTUS", value:24},
        // {label: "GRAFICA OVERTIME", value:25},
        // {label: "GRAFICA CULTO", value:26},
        // {label: "GRAFICA AmeShop", value:27},
        {label: "Tipo de Infractor (Gráfica de Pastel)", value:28},
        // {label: "GRAFICA Ciruclar INNVICTUS", value:29},
        // {label: "GRAFICA Ciruclar OVERTIME", value:30},
        // {label: "GRAFICA Ciruclar CULTO", value:31},
        // {label: "GRAFICA Ciruclar AmeShop", value:32},
        // {label: "Denuncias por Estado Grupo Innovasport (Mapa de México)", value:33},
        // {label: "GRAFICA MEXICO INNVICTUS", value:34},
        // {label: "GRAFICA MEXICO OVERTIME", value:35},
        // {label: "GRAFICA MEXICO CULTO", value:36},
        // {label: "GRAFICA MEXICO AmeShop", value:37},
        // {label: "Gravedad por Estado Grupo Innovasport (Mapa de México)", value:38},
        // {label: "GRAFICA STACKED INNVICTUS", value:39},
        // {label: "GRAFICA STACKED OVERTIME", value:40},
        // {label: "GRAFICA STACKED CULTO", value:41},
        // {label: "GRAFICA STACKED AmeShop", value:42},
        // {label: "Tipo de Denuncia General Grupo Innovasport", value:43},
        // {label: "Tipo de Denuncia Especifica Grupo Innovasport", value:44},
        // {label: "Denuncias por Zona Grupo Innovasport", value:45},
        // {label: "Análisis Denuncia por Tienda CDMX Grupo Innovasport (Gráfica de distribución)", value:46},
        // { label: "Distribución de las denuncias a traves de los meses", value: 99 }

      ];
    }
  }



  const handleTypeDataGraficBar = (event) => {
    const selectedValue = event.value;
    setBarTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setBarTypeOfChart(event);
  };

  const handleTypeDataGraficHistoric = (event) => {
    const selectedValue = event.value;
    setHistoricTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setHistoricTypeOfChart(event);
  };
  const handleTypeDataGraficRadar = (event) => {
    const selectedValue = event.value;
    setRadarTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setRadarTypeOfChart(event);
  };
  const handleTypeDataGraficGeographic = (event) => {
    const selectedValue = event.value;
    setGeographicTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setGeographicTypeOfChart(event);
  };
  const handleTypeDataGraficSpecial = (event) => {
    const selectedValue = event.value;
    setSpecialTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setSpecialTypeOfChart(event);
  };

  const handleTypeDataGraficCircle = (event) => {
    const selectedValue = event.value;
    setCircleTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setCircleTypeOfChart(event);
  };

  const getOptionsFromElementUtilities = (path) => {


    const options = getElementFromUtilities(path).childs.map((item) => {
      if (item.path !== 'filter_by_entryway#field#catalogue:::C::EG::RD-102106') {
        return {
          label: item.locales[lang] ? item.locales[lang] : item.label,
          value: item.path,
          key: path,
          selected: false,
        };
      }
      return null;
    }).filter(option => option !== null);
    return options;
  };

  const getElementFromUtilities = (path) => {
    return stateData["utilities"].filter((item) => item.path === path).pop();
  };

  const handleShowNewFilter = (is_active) => {
    setShowNewFilter(is_active);
  };

  // const handleNewFilter = (filter, addEachChild = false) => {
  //   if (addEachChild) {
  //     setStackFilters([...stackFilters, ...filter]);
  //   } else {
  //     setStackFilters([...stackFilters, filter]);
  //   }
  //   if (titleGrafic !== "" && typeGrafic !== null && typeDataGrafic !== null)
  //     setIsButtonDisabled(false);
  //   else setIsButtonDisabled(true);
  // };


  const handleNewFilter = (filter, addEachChild = false) => {
    setStackFilters((prevStackFilters) => {
      // Verificamos si filter tiene selectedIndexStacked
      const selectedIndex = filter.selectedIndexStacked;
      if (selectedIndex !== undefined && prevStackFilters[selectedIndex]) {
        // Clonamos el filtro existente y el nuevo
        const originalFilter = prevStackFilters[selectedIndex];
        // Actualizamos el filtro en la posición indicada con los nuevos valores combinados
        const updatedFilter = {
          ...originalFilter,
          function: `${originalFilter.function} & ${filter.function}`,
          label: `${originalFilter.label} & ${filter.label}`,
        };
        setShowNewFilterStacked(false);
        // Retornamos el stackFilters actualizado con el nuevo filtro combinado
        return prevStackFilters.map((item, index) =>
          index === selectedIndex ? updatedFilter : item
        );
      } else {
        // Si no se especifica selectedIndexStacked o el índice no existe, añadimos los filtros
        if (addEachChild) {
          return [...prevStackFilters, ...filter];
        } else {
          return [...prevStackFilters, filter];
        }
      }
    });


    // Actualizamos el estado del botón de acuerdo a las condiciones
    if (titleGrafic !== "" && typeGrafic !== null && typeDataGrafic !== null) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }

  };

  useEffect(() => {
    setShowNewFilterStacked(false);


  }, [stackFilters])


  const handleNewIndicator = (indicator) => {
    setStackIndicators([...stackIndicators, indicator]);
  };

  const handleDeleteFilter = (filter) => {
    setStackFilters(stackFilters.filter((item) => item.label !== filter.label));
    if (
      titleGrafic !== "" &&
      typeGrafic !== null &&
      typeGraficGrafic !== null &&
      typeDataGrafic !== null &&
      stackFilters.length > 1
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleDeleteIndicator = (indicator) => {
    setStackIndicators(
      stackIndicators.filter((item) => item.title !== indicator.title)
    );
  };

  const handleTitleGrafic = (e) => {
    setTitleGrafic(e.target.value);
    if (
      e.target.value !== "" &&
      typeGrafic !== null &&
      typeGraficGrafic !== null &&
      typeDataGrafic !== null &&
      stackFilters.length > 0
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleTypeGrafic = (e) => {
    setTypeGrafic(e);
    if (
      titleGrafic !== "" &&
      typeDataGrafic !== null &&
      stackFilters.length > 0
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);

    setTypeOfBar(e.value);
  };

  const handleTypeDataGrafic = (e) => {
    setTypeDataGrafic(e);
    if (titleGrafic !== "" && typeGrafic !== null && stackFilters.length > 0 && typeDataGrafic !== null)
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleCreateGrafic = async () => {
    if (typeGraficGrafic === 14) {
      stackFilters = [
        { label: "improcedentes", function: 'filter_by_status#type#contar_IMPROCEDENTES' }
      ]
    } else if (typeGraficGrafic === 16) {
      stackFilters = [
        { label: "estado", function: 'filter_by_status#type#contar_IMPROCEDENTES' }
      ]
    } else if (typeGraficGrafic === 17) {
      stackFilters = [
        { label: "doble", function: 'filter_by_status#type#contar_CERRADO' }
      ]
    } else if (typeGraficGrafic === 18 || typeGraficGrafic === 12 || typeGraficGrafic === 13 || typeGraficGrafic === 20 || typeGraficGrafic === 21) {
      stackFilters = [
        { label: "todos", function: 'filter_by_status#type#contar_TODOS' }
      ]
    } else if (typeGraficGrafic === 23 || typeGraficGrafic === 24 || typeGraficGrafic === 25 || typeGraficGrafic === 26 || typeGraficGrafic === 27 || typeGraficGrafic === 28 || typeGraficGrafic === 29 || typeGraficGrafic === 30 || typeGraficGrafic === 31 || 
      typeGraficGrafic === 32 || typeGraficGrafic === 33 || typeGraficGrafic === 34 || typeGraficGrafic === 35 || typeGraficGrafic === 36 || typeGraficGrafic === 37 || typeGraficGrafic === 38 || typeGraficGrafic === 39 || typeGraficGrafic === 40 || 
      typeGraficGrafic === 41 || typeGraficGrafic === 42 || typeGraficGrafic === 43 || typeGraficGrafic === 44 || typeGraficGrafic === 45 || typeGraficGrafic === 46 || typeGraficGrafic === 99) { 
      stackFilters = [
        { label: "Todos los reportes", function: 'filter_by_status#type#contar_TODOS' }
      ]
    }



    let data = {
      graphics: [
        {
          title: titleGrafic,
          filter_params: stackFilters,
          show_graphic: typeGrafic.value,
          show_graphic_graphic: typeGraficGrafic,
          get_data: typeDataGrafic,
          // get_data: typeDataGrafic.value,
          indicators: stackIndicators,
          metadata: {},
          is_active: true,
          addTwoColumns: addTwoColumns
        },
      ],
      indicators: [],
      default_graphics: []
    };

    console.log("DATATATATATATAATATAT", data);
    


    const response = await createDashboard(data);

    if (response !== undefined) {
      props.onHide();
      props.stateLoadingGraphics(true);
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalContainer"
        contentClassName={`modal-example-dialog ${theme}`}
        animation={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className={theme === "dark" ? "btn-close-white" : ""}
        >
          <Modal.Title bsPrefix={`cus-modal-title ${theme}`}>
            <h3>{t("ModalGraph.add_chart_or_indicator")}</h3>
            <h6 className={`cus-modal-title ${theme}`}>
              {t("ModalGraph.use_filters_to_create")}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="container-body">
          {isLoading ? (
            <Loading />
          ) : showMainOptions ? (
            <div className="main-component">
              <div className="w-50">
                <MCLabel text={t("ModalGraph.what_want_create")} />
                {contadorGrafica >= limiteGrafica ? (
                  <h6> {t("ModalGraph.graphic_limit")} {contadorGrafica} </h6>
                )
                  : (
                    <></>
                  )}
                {contadorIndicador >= limiteIndicador ? (
                  <h6> {t("ModalGraph.indicator_limit")} {contadorIndicador} </h6>
                )
                  : (
                    <></>
                  )}

                <MCSelect
                  className="m-1"
                  options={elementOptions}
                  onChange={(e) => handleSelectOption(e)}
                />
              </div>
            </div>
          ) : !showMainOptions && elementOptions[0].selected ? (
            <>
              {showNewFilter ? (

                <NewFilterComponent
                  getOptionsFromUtilities={getOptionsFromElementUtilities}
                  handleShowNewFilter={handleShowNewFilter}
                  handleNewFilter={handleNewFilter}
                />
              ) : showNewIndicator ? (
                <IndicatorComponent
                  getOptionsFromUtilities={getOptionsFromElementUtilities}
                  showIndicator={setShowNewIndicator}
                  listIndicators={handleNewIndicator}
                />
              ) : (
                <>

                  <Row>
                    <Col>
                      <MCLabel text={`${t("ModalGraph.graph_name")}:`} />
                      <MCInput
                        type="text"
                        name="title"
                        className="m-1"
                        placeholder={t("ModalGraph.name_your_chart")}
                        value={titleGrafic}
                        onChange={(e) => handleTitleGrafic(e)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <MCLabel text={`${t("ModalGraph.graph_type")}:`} />
                      <MCSelect
                        className="m-1"
                        options={getOptionsFromElementUtilities("show_graphic")}
                        onChange={(e) => handleTypeGrafic(e)}
                        value={typeGrafic}
                      />

                      {typeOfBar == "show_graphic#type#circles" && (
                        <div>
                          <MCLabel text={`Circular`} />
                          <MCSelect
                            className="m-1"
                            options={getOptionsCircleChart("get_data_circle")}
                            onChange={(e) => handleTypeDataGraficCircle(e)}
                            value={typeOfCircleChart}
                          />
                        </div>
                      )}
                      {typeOfBar == "show_graphic#type#bars" && (
                        <div>
                          <MCLabel text={`Barrras`} />
                          <MCSelect
                            className="m-1"
                            options={getOptionsCircleChart("get_data_bar")}
                            onChange={(e) => handleTypeDataGraficBar(e)}
                            value={typeOfBarChart}
                          />
                        </div>
                      )}

                      {typeOfBar == "show_graphic#type#historic" && (
                        <div>
                          <MCLabel text={`Histórico`} />
                          <MCSelect
                            className="m-1"
                            options={getOptionsCircleChart("get_data_historic")}
                            onChange={(e) => handleTypeDataGraficHistoric(e)}
                            value={typeOfHistoricChart}
                          />
                        </div>
                      )}
                      {typeOfBar == "show_graphic#type#radar" && (
                        <div>
                          <MCLabel text={`Radar`} />
                          <MCSelect
                            className="m-1"
                            options={getOptionsCircleChart("get_data_radar")}
                            onChange={(e) => handleTypeDataGraficRadar(e)}
                            value={typeOfRadarChart}
                          />
                        </div>
                      )}
                      {typeOfBar == "show_graphic#type#geographic" && (
                        <div>
                          <MCLabel text={`Geográfica`} />
                          <MCSelect
                            className="m-1"
                            options={getOptionsCircleChart("get_data_geographic")}
                            onChange={(e) => handleTypeDataGraficGeographic(e)}
                            value={typeOfGeographicChart}
                          />
                        </div>
                      )}
                      {typeOfBar == "show_graphic#type#special" && (
                        <div>
                          <MCLabel text={`Especial`} />
                          <MCSelect
                            className="m-1"
                            options={getOptionsCircleChart("get_data_special")}
                            onChange={(e) => handleTypeDataGraficSpecial(e)}
                            value={typeOfSpecialChart}
                          />
                        </div>
                      )}
                    </Col>
                    {/* <Col>
                        <MCLabel
                            text={`${t("ModalGraph.graph_data_representation")}:`}
                        />
                        <MCSelect
                            className="m-1"
                            options={getOptionsFromElementUtilities("get_data")}
                            onChange={(e) => handleTypeDataGrafic(e)}
                            value={typeDataGrafic}
                        />
                    </Col> */}
                  </Row>
                  <Row>
                    {typeOfBar != "show_graphic#type#historic" && typeOfBar != "show_graphic#type#special" && typeOfBar != "show_graphic#type#geographic" && typeOfBar != null && (
                      <Col>
                        <div className="head-container-filter">
                          <MCButton
                            label={t("ModalGraph.add_data_series")}
                            variant="primary"
                            outline
                            onClick={() => handleShowNewFilter(true)}
                          />
                        </div>
                      </Col>
                    )}
                    {typeOfBar == "show_graphic#type#historic" && typeOfHistoricChart != "" && (
                      <Col>
                        <div className="head-container-filter">
                          <MCButton
                            id="saved"
                            label={t("ModalGraph.create")}
                            variant={"success"}
                            onClick={() => handleCreateGrafic()}
                          />
                        </div>
                      </Col>

                    )}
                    {typeOfBar == "show_graphic#type#special" && typeOfSpecialChart != "" && titleGrafic != "" && (
                      <Col>
                        <div className="head-container-filter">
                          <MCButton
                            id="saved"
                            label={t("ModalGraph.create")}
                            variant={"success"}
                            onClick={() => handleCreateGrafic()}
                          />
                        </div>
                      </Col>

                    )}
                    {typeOfBar == "show_graphic#type#geographic" && typeOfGeographicChart != "" && titleGrafic != "" && (
                      <Col>
                        <div className="head-container-filter">
                          <MCButton
                            id="saved"
                            label={t("ModalGraph.create")}
                            variant={"success"}
                            onClick={() => handleCreateGrafic()}
                          />
                        </div>
                      </Col>

                    )}
                    {stackFilters.length !== 0 && (
                      <Col>
                        <div className="head-container-filter">
                          <MCLabel
                            className="px-2"
                            text={`${t("ModalGraph.configure_indicators")}...`}
                          />
                          <MCButton
                            label="+"
                            variant="primary"
                            outline
                            onClick={() => setShowNewIndicator(true)}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  {stackFilters.length !== 0 && (
                    <Row>

                      {/* <Col>
                        <div className="filter-container">
                          {stackFilters.map((item, i) => {
                            return (
                              <div className="item-filter" key={i}>
                                <MCLabel text={item.label} />
                                <MCTooltip
                                  text={t("ModalGraph.delete")}
                                  position="bottom"
                                >
                                  <div>
                                    <TrashCan
                                      className="trash"
                                      onClick={() => handleDeleteFilter(item)}
                                    />
                                  </div>
                                </MCTooltip>
                              </div>
                            );
                          })}
                        </div>
                      </Col> */}

                      <Col>
                        <div className="filter-container">
                          {stackFilters.map((item, i) => (
                            <div className="item-filter" key={i}>
                              <MCLabel text={item.label} />

                              <div style={{ display: "flex" }}>

                                {/* Botón "+" */}
                                <MCButton
                                  label="+"
                                  variant="primary"
                                  outline
                                  onClick={() => handleShowNewFilterStacked(item.label, i)}
                                  className="add-filter"
                                />

                                <MCTooltip
                                  text={t("ModalGraph.delete")}
                                  position="bottom"
                                >
                                  <div>
                                    <TrashCan
                                      className="trash"
                                      onClick={() => handleDeleteFilter(item)}
                                    />
                                  </div>
                                </MCTooltip>
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Renderiza NewFilterComponentStacked solo si showNewFilterStacked es true */}
                        {showNewFilterStacked && selectedLabel != false && (
                          <NewFilterComponentStacked
                            label={selectedLabel}
                            selectedIndexStacked={selectedIndexStacked}
                            stackFilters={stackFilters}
                            getOptionsFromUtilities={getOptionsFromElementUtilities}
                            handleShowNewFilter={handleShowNewFilterStacked}
                            handleNewFilter={handleNewFilter}
                          />
                        )}
                      </Col>

                      {stackIndicators.length !== 0 && (
                        <Col>
                          <div className="filter-container">
                            {stackIndicators.map((item) => {
                              return (
                                <div className="item-filter">
                                  <MCLabel text={item.title} />
                                  <MCTooltip
                                    text={t(
                                      "dashboard.modals.addGraphOrInd.buttons.delete"
                                    )}
                                    position="bottom"
                                  >
                                    <div>
                                      <TrashCan
                                        className="trash"
                                        onClick={() =>
                                          handleDeleteIndicator(item)
                                        }
                                      />
                                    </div>
                                  </MCTooltip>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                  <hr />
                  <div className="footer-modal">
                    <div>
                      <MCTooltip text={t("ModalGraph.back")} position="top">
                        <button
                          id="return"
                          label={t("ModalGraph.back")}
                          onClick={() => setShowMainOptions(true)}
                          className="turnBack"
                        >
                          <ArrowLeft width={25} heigth={30} />
                        </button>
                      </MCTooltip>
                    </div>
                    <div>

                      <MCCheckbox
                        id="agregar_un_filtro_por_cada_opcion_del_catalogo"
                        className="me-2 mt-2"
                        checked={addTwoColumns}
                        onChange={(e) => setAddTwoColumns(e.target.checked)}
                      />
                      <MCLabel
                        htmlFor="agregar_un_filtro_por_cada_opcion_del_catalogo"
                        text={t("ModalGraph.addTwoColumns")}

                        className="me-3"
                      />

                      <MCButton
                        id="cancel"
                        label={t("ModalGraph.cancel")}
                        variant="danger"
                        outline
                        onClick={props.onHide}
                      />
                      <MCButton
                        id="saved"
                        label={t("ModalGraph.create")}
                        variant={isButtonDisabled ? "secondary" : "success"}
                        disabled={isButtonDisabled}
                        onClick={() => handleCreateGrafic()}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            !showMainOptions &&
            elementOptions[1].selected && (
              <>
                <IndicatorComponent
                  getOptionsFromUtilities={getOptionsFromElementUtilities}
                  fnLoadingIndicator={props.stateLoadingIndicators}
                  fnCloseModal={props.onHide}
                  setShowMainOptions={setShowMainOptions}
                />
              </>
            )
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalGraph;
