import { useEffect, useState, useMemo } from "react";



import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PieChart } from "./charts/PieChart/PieChart";
import { Row, Col, FormCheck } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import { EGButton } from "../../MainComponents/Button/EGButton/EGButton";
import { EGIconButton } from "../../MainComponents/Button/IconButton/IconButton";
import {
  deleteElement,
  getDataGraphicByPath,
  oneColumnChange,
  modifyOrder
} from "../../../apis/apiDashboard";
import BarChart from "./charts/BarChart/BarChart";
import { GraphicComplements } from "./complements/GraphicComplements";
import "./graphics.scss";


// import { DatePicker } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Button, Popover, TextField } from '@mui/material';
import { format } from 'date-fns';


import Modal from 'react-bootstrap/Modal';
// import { PieChart } from "./charts/PieChart/PieChart";
import { DonutChart } from "./charts/PieChart/DonutChart";
import { DoubleChart } from "./charts/PieChart/DoubleChart";
import { LineChart } from "./charts/LineChart/historicLineChart";
import { LineChartSteps } from "./charts/LineChart/LineChartSteps";
import { LineChartWhitOutScales } from "./charts/LineChart/LineChartWithoutScale";
import { MonthsReportsHistoric } from "./charts/LineChart/MonthsReportsHistoric";
import { MonthsReportsHistoricBarChart } from "./charts/LineChart/MonthsReportsHistoricBarChart";
import { LineDoubleOpenClose } from "./charts/SpecialChart/LineDoubleOpenClose";
import { DispersionChart } from "./charts/SpecialChart/GraficaDispersion";
import { BarChartHistoric } from "./charts/LineChart/BarChartHistoric";
import { RadarChart } from "./charts/RadarChart/RadarChart";
import { WorldBubbleChart } from "./charts/GeoChart/WorldBubbleChart";
import { GeoXTypeChart } from "./charts/SpecialChart/GeoXTypeChart";
import { MexicoChart } from "./charts/GeoChart/MexicoGeoChart";
import { GeneroChart } from "./charts/SpecialChart/GeneroChart";
import { BarrasEstadoStack } from "./charts/SpecialChart/BarrasEstadoStackChart";
import { DistibutionStateSeverity } from "./charts/SpecialChart/DistributionStateSeverity";
import { DistibutionStateType } from "./charts/SpecialChart/DistributionStateType";
// import BarChart from "./charts/BarChart/BarChart";
import ParetoChart from "./charts/BarChart/ParetoChart";
import BarChartHorizontal from "./charts/BarChart/BarChartHorizontal";
import { LineOpenCloseChart } from "./charts/SpecialChart/LineOpenCloseChart";
import { InnovaDistribution} from "./charts/SpecialChart/InnovaCharts/innovaDistribution";
import { InnovaDistributionCDMX } from "./charts/SpecialChart/InnovaCharts/InnovaDistributionCDMX";
import { InnovaDonutChart } from "./charts/SpecialChart/InnovaCharts/InnovaDonutChart";
import { InnovaMexicoGeoChart } from "./charts/SpecialChart/InnovaCharts/InnovaMexicoGeoChart";
import { InnovaZonaMexicoChart } from "./charts/SpecialChart/InnovaCharts/InnovaZonaMexicoChart";
import { InnovaBarrasEstadoStack } from "./charts/SpecialChart/InnovaCharts/InnovaBarrasEstadoStackChart";
import { InnovaMultiVerticalBars } from "./charts/SpecialChart/InnovaCharts/InnovaMultiVerticalBars";
import { InnovaSpecificMultiVerticalBars } from "./charts/SpecialChart/InnovaCharts/InnovaSpecificMultiVerticalBars";


import Icon from "../../Icon/Icon";

import { useSnackbar } from "notistack";
import { LoadingGraphic } from "../../Loading/Graphic/Graphic";
import { useTranslation } from "react-i18next";

export const Graphics = ({ graphicsData, stateLoading = null, order = {} }) => {
  const [t] = useTranslation("dashboard");
  const { gTheme } = useSelector((state) => state.theme);
  const theme = gTheme === "dark" ? "dark" : "light";
  const { enqueueSnackbar } = useSnackbar();

  const [graphics, setGraphics] = useState([]);
  const [deleting, setDeleting] = useState("");
  const [replaceData, setReplaceData] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [elementVisibility, setElementVisibility] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customRange, setCustomRange] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState(30);
  const [customRangeText, setCustomRangeText] = useState("");

  // console.log("GRAPHICS DATA", graphicsData);
  


  const [currentGraphicIndex, setCurrentGraphicIndex] = useState(null);
  const today = new Date(); // Fecha actual

  const [Graphicsorder, setGraphicsOrder] = useState(order || {}); // Estado local para manejar `order`



  useEffect(() => {
    if (!replaceData) {
      setOriginalData(Object.values(graphicsData));
      setGraphics(Object.values(graphicsData));
      setReplaceData(true);
    }
  }, []);

  const deleteGraphic = async (graphicPath) => {
    setDeleting(graphicPath);    

    const response = await deleteElement({ path: graphicPath });
    if (stateLoading !== null && response !== undefined) stateLoading(true);
  };


  const [elementVisibilityIndicators, setElementVisibilityIndicators] = useState([])
  const toggleElementVisibility = (index) => {
    console.log("INDEX", index);
    
    const updatedElementVisibility = [...elementVisibility];
    updatedElementVisibility[index] = !updatedElementVisibility[index];
    setElementVisibility(updatedElementVisibility);
  };


  const toggleElementVisibilityIndicator = (index) => {
    const updatedElementVisibilityIndicators = [...elementVisibilityIndicators];
    updatedElementVisibilityIndicators[index] = !updatedElementVisibilityIndicators[index];
    setElementVisibilityIndicators(updatedElementVisibilityIndicators);
  };


  // Funcion para obtener los datos de la grafica
  // Con filtros de los ultimos 30, 60 y 90 dias
  const getGraphicData = async (graphicPath, days, index, rowIndex) => {
    setCurrentIndex(index);
    setIsLoading(true);


    // Obtener las fechas actuales de r_start y r_end si no están definidas
    let requestData;
    if (days.r_start && days.r_end) {
      requestData = { path: graphicPath, r_start: days.r_start, r_end: days.r_end };
    } else {
      const today = new Date();
      const r_end = today.toISOString().split("T")[0]; // Fecha de hoy en formato 'YYYY-MM-DD'
      const r_start = new Date(today.setDate(today.getDate() - days))
        .toISOString()
        .split("T")[0]; // Restar el número de días de 'days' a la fecha actual
      requestData = { path: graphicPath, r_start, r_end };
    }


    const response = await getDataGraphicByPath(requestData);

    if (response.status === 204) {
      enqueueSnackbar(t("Graphics.no_data"), {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 404) {
      enqueueSnackbar(response.data, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 200) {
      enqueueSnackbar(t("Graphics.obtained_data"), {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });

      const updatedGraphicKey = response.data.path.split("::")[1];

      const tmpGraphics = graphics.map((graphic) => {
        const graphicKey = graphic.path.split("::")[1]; 
        if (graphicKey === updatedGraphicKey) {
          return response.data; 
        }
        return graphic; 
      });


      setGraphics(tmpGraphics); 
    }
    setCurrentIndex(-1);
    setIsLoading(false);
  };




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // Resetear las fechas temporales al cerrar el pop-up
    setTempStartDate(null);
    setTempEndDate(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-popover' : undefined;

  const handleShow = (graphicItem) => {

    const index = graphicItem?.index    
    
    setCurrentGraphicIndex(index);
    setFullscreen(true);
    setShow(true);
  }

  const [currentIndexSelected, setCurrentIndexSelected] = useState(null);
  const [currentPathSelected, setCurrentPathSelected] = useState(null);
  const [currentDateSelected, setCurrentDateSelected] = useState({});





  const oneColumn = async (path) => {
    const response = await oneColumnChange({ path: path, addTwoColumns: false });


    if (response.status === 204) {
      enqueueSnackbar(t("Graphics.no_data"), {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 404) {
      enqueueSnackbar(response.data, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
    if (response.status === 200) {
      enqueueSnackbar(t("Graphics.obtained_data"), {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      window.location.reload();

    }

  }
  const twoColumn = async (path) => {
    const response = await oneColumnChange({ path: path, addTwoColumns: true });


    if (response.status === 204) {
      enqueueSnackbar(t("Graphics.no_data"), {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    }
    if (response.status === 404) {
      enqueueSnackbar(response.data, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    }
    if (response.status === 200) {
      enqueueSnackbar(t("Graphics.obtained_data"), {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      window.location.reload();
    }
  }




  // const organizedGraphics = useMemo(() => {
  //   const orderedGraphics = (Graphicsorder && Object.keys(Graphicsorder).length > 0
  //     ? Object.keys(Graphicsorder)
  //       .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
  //       .map(orderKey => {
  //         const graphicId = Graphicsorder[orderKey];
  //         const graphic = graphics.find(graphic => {
  //           const pathId = graphic.path.split("::")[1];
  //           return pathId === graphicId;
  //         });
  //         if (graphic) {
  //           graphic.ordered = true;
  //         }
  //         return graphic;
  //       })
  //       .filter(graphicItem => !!graphicItem)
  //     : []
  //   );

  //   const unorderedGraphics = graphics.filter(graphic => {
  //     const pathId = graphic.path.split("::")[1];
  //     const isOrdered = Object.values(Graphicsorder || {}).includes(pathId);
  //     if (!isOrdered) {
  //       graphic.ordered = false;
  //     }
  //     return !isOrdered;
  //   });

  //   // Combinar y organizar gráficas
  //   const combinedGraphics = [...orderedGraphics, ...unorderedGraphics];

  //   return combinedGraphics.reduce((rows, graphicItem) => {
  //     const columnLimit = 2;
  //     const itemColumns = graphicItem.addTwoColumns ? 2 : 1;

  //     let currentRow = rows[rows.length - 1];

  //     if (!currentRow || currentRow.totalColumns + itemColumns > columnLimit) {
  //       currentRow = { totalColumns: 0, items: [] };
  //       rows.push(currentRow);
  //     }

  //     currentRow.items.push(graphicItem);
  //     currentRow.totalColumns += itemColumns;
      
  //     return rows;
  //   }, []).map(row => row.items);
  // }, [graphics, Graphicsorder]);

  const organizedGraphics = useMemo(() => {
    const orderedGraphics = (Graphicsorder && Object.keys(Graphicsorder).length > 0
      ? Object.keys(Graphicsorder)
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .map(orderKey => {
          const graphicId = Graphicsorder[orderKey];
          const graphic = graphics.find(graphic => {
            const pathId = graphic.path.split("::")[1];
            return pathId === graphicId;
          });
          if (graphic) {
            graphic.ordered = true;
          }
          return graphic;
        })
        .filter(graphicItem => !!graphicItem)
      : []
    );
  
    const unorderedGraphics = graphics.filter(graphic => {
      const pathId = graphic.path.split("::")[1];
      const isOrdered = Object.values(Graphicsorder || {}).includes(pathId);
      if (!isOrdered) {
        graphic.ordered = false;
      }
      return !isOrdered;
    });
  
    // Combinar y organizar gráficas
    const combinedGraphics = [...orderedGraphics, ...unorderedGraphics]
      .map((graphic, index) => ({ ...graphic, index })); // Asigna la clave index con la posición actual
  
    return combinedGraphics.reduce((rows, graphicItem) => {
      const columnLimit = 2;
      const itemColumns = graphicItem.addTwoColumns ? 2 : 1;
  
      let currentRow = rows[rows.length - 1];
  
      if (!currentRow || currentRow.totalColumns + itemColumns > columnLimit) {
        currentRow = { totalColumns: 0, items: [] };
        rows.push(currentRow);
      }
  
      currentRow.items.push(graphicItem);
      currentRow.totalColumns += itemColumns;
  
      return rows;
    }, []).map(row => row.items);
  }, [graphics, Graphicsorder]);
  const reorderGraphics = (graphics, Graphicsorder) => {
    if (!Graphicsorder || Object.keys(Graphicsorder).length === 0) {
      return graphics; // Si no hay orden definido, no modificamos nada
    }
  
    const orderedGraphics = Object.keys(Graphicsorder)
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .map(orderKey => {
        const graphicId = Graphicsorder[orderKey];
        const graphic = graphics.find(graphic => {
          const pathId = graphic.path.split("::")[1];
          return pathId === graphicId;
        });
        if (graphic) {
          graphic.ordered = true;
        }
        return graphic;
      })
      .filter(graphicItem => !!graphicItem); // Filtrar elementos válidos
  
    const unorderedGraphics = graphics.filter(graphic => {
      const pathId = graphic.path.split("::")[1];
      const isOrdered = Object.values(Graphicsorder).includes(pathId);
      if (!isOrdered) {
        graphic.ordered = false;
      }
      return !isOrdered;
    });
  
    return [...orderedGraphics, ...unorderedGraphics];
  };





  const modifyOrderUp = (path) => {

    const graphicId = path.split("::")[1];
    const currentIndex = Object.keys(Graphicsorder).find(key => Graphicsorder[key] === graphicId);

    if (currentIndex && parseInt(currentIndex, 10) > 1) {
      const prevIndex = parseInt(currentIndex, 10) - 1;

      const newOrder = { ...Graphicsorder };
      [newOrder[prevIndex], newOrder[currentIndex]] = [newOrder[currentIndex], newOrder[prevIndex]];

      modifyOrder({ path: path, direction: 'up' })

      setGraphicsOrder(newOrder);
    }
  };

  const modifyOrderDown = (path) => {
    const graphicId = path.split("::")[1];
    const currentIndex = Object.keys(Graphicsorder).find(key => Graphicsorder[key] === graphicId);

    if (currentIndex && parseInt(currentIndex, 10) < Object.keys(Graphicsorder).length) {
      const nextIndex = parseInt(currentIndex, 10) + 1;

      const newOrder = { ...Graphicsorder };
      [newOrder[nextIndex], newOrder[currentIndex]] = [newOrder[currentIndex], newOrder[nextIndex]];


      modifyOrder({ path: path, direction: 'down' })


      setGraphicsOrder(newOrder);
    }
  };


  const activateOrder = (path) => {
    const graphicId = path.split("::")[1];

    const isAlreadyOrdered = Object.values(Graphicsorder).includes(graphicId);

    if (!isAlreadyOrdered) {
      const newOrder = { ...Graphicsorder };

      const newIndex = Object.keys(newOrder).length + 1;
      newOrder[newIndex] = graphicId;

      modifyOrder({ path: path, direction: 'addIndice' });

      setGraphicsOrder(newOrder);
    }
  };



  useEffect(() => {
    const graphicsInBrute = Object.values(graphicsData);
    
    const updatedGraphics = reorderGraphics(graphicsInBrute, Graphicsorder);
    
    setGraphics(updatedGraphics); // Actualiza el estado con las gráficas reordenadas
  }, [Graphicsorder]);
  
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {organizedGraphics.map((rowGraphics, rowIndex) => (
        <Row key={rowIndex} className="graphic-row">
          {rowGraphics.map((graphicItem, index) => (
            <Col
              key={((rowIndex * 2) + index)}
              sm={graphicItem.addTwoColumns ? 12 : 6}
              md={graphicItem.addTwoColumns ? 12 : 6}
              lg={graphicItem.addTwoColumns ? 12 : 6}
              className={`graphic-content ${theme} `}
            >
              <div className={`graphic-header ${theme} ${elementVisibilityIndicators[((rowIndex * 2) + index)] ? 'col-lg-8' : 'col-lg-12'}`}>
                <Col className="subtitle" lg={8}>
                  {graphicItem.title === deleting
                    ? `${t("Graphics.deleting")}...`
                    : graphicItem.title}
                </Col>

                <Col lg={4} className={`options ${theme}`}>
                  <Col sm={10}>
                    <MCTooltip text={t("Graphics.get_results")} position="bottom">
                      <Form.Select
                        size="sm"
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setCurrentIndexSelected(((rowIndex * 2) + index));
                          setCurrentPathSelected(graphicItem.path);

                          if (selectedValue === 'custom') {
                            setCustomRange(true);
                            handleClick(e);
                          } else {
                            setCustomRange(false); // Ocultar el calendario
                            getGraphicData(graphicItem.path, selectedValue, ((rowIndex * 2) + index), rowIndex); // Obtener los datos gráficos
                          }
                        }}
                      >
                        <option value={30}>{t("Graphics.last_30")}</option>
                        <option value={60}>{t("Graphics.last_60")}</option>
                        <option value={90}>{t("Graphics.last_90")}</option>
                        <option value={183}>{t("Graphics.past_six_months")}</option>
                        <option value={365}>{t("Graphics.last_year")}</option>
                        <option value="custom">{currentDateSelected[((rowIndex * 2) + index)] || t("Graphics.custom_range")}</option>
                      </Form.Select>
                    </MCTooltip>
                  </Col>


                  {graphicItem.ordered == true ? (
                    <>
                      <EGIconButton
                        iconSize={16}
                        className="graphic-option"
                        iconName="arrow_up_4"
                        onClick={() => {
                          modifyOrderUp(graphicItem.path);
                        }}
                        title={t("Graphics.move_up")}
                      />
                      <EGIconButton
                        iconSize={16}
                        className="graphic-option"
                        iconName="arrow_down"
                        onClick={() => {
                          modifyOrderDown(graphicItem.path);
                        }}
                        title={t("Graphics.move_down")}
                      />
                    </>
                  ) : (
                    <>
                      <EGIconButton
                        iconSize={16}
                        className="graphic-option"
                        iconName="arrow_swap"
                        onClick={() => {
                          activateOrder(graphicItem.path);
                        }}
                        title={t("Graphics.activate_order")}
                      />
                    </>
                  )
                  }

                  {customRange && (
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <DatePicker
                          label={t("Graphics.start_date")}
                          value={tempStartDate}
                          onChange={(newValue) => setTempStartDate(newValue)}
                          maxDate={today}
                          textField={(params) => <TextField {...params} />}
                        />
                        <Box sx={{ my: 2 }} />
                        <DatePicker
                          label={t("Graphics.end_date")}
                          value={tempEndDate}
                          onChange={(newValue) => setTempEndDate(newValue)}
                          minDate={tempStartDate}
                          maxDate={today}
                          textField={(params) => <TextField {...params} />}
                        />
                        <EGButton
                          label={t("Graphics.apply_custom_range")}
                          color="primary"
                          sx={{
                            display: 'flex', justifyContent: 'flex-end', mt: 2

                          }} onClick={() => {
                            const formattedStartDate = format(tempStartDate, 'yyyy-MM-dd');
                            const formattedEndDate = format(tempEndDate, 'yyyy-MM-dd');
                            const visibleStartDate = format(tempStartDate, 'dd-MM-yyyy');
                            const visibleEndDate = format(tempEndDate, 'dd-MM-yyyy');

                            setCurrentDateSelected(prevState => ({
                              ...prevState,
                              [currentIndexSelected]: `${visibleStartDate} - ${visibleEndDate}`
                            }));

                            setSelectedRange("custom");
                            setCustomRangeText(`${visibleStartDate} - ${visibleEndDate}`);
                            getGraphicData(currentPathSelected, { r_start: formattedStartDate, r_end: formattedEndDate }, currentIndexSelected); // Envía fechas formateadas
                            handleClose();
                          }}

                          disabled={!tempStartDate || !tempEndDate || tempEndDate < tempStartDate}
                        />

                      </Box>
                    </Popover>
                  )}

                  <button
                    // onClick={() => handleShow((index)}
                    onClick={() => handleShow(graphicItem)}
                    className="maximize-button"
                  >
                    <Icon
                      size={20}
                      name="outline_maximize"
                    />
                  </button>
                  <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>{graphics[currentGraphicIndex]?.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {graphics[currentGraphicIndex] && (
                        <div className="graphic-element">
                          {graphics[currentGraphicIndex].show_graphic === "bars" ? (
                            <>
                              {graphics[currentGraphicIndex].show_graphic_graphic == 3 ? (
                                <BarChartHorizontal chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 4 ? (
                                <BarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 5 ? (
                                <ParetoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : (
                                <BarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              )}
                            </>
                          ) : graphics[currentGraphicIndex].show_graphic === "circles" ? (
                            <>
                              {graphics[currentGraphicIndex].show_graphic_graphic == 1 ? (
                                <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 2 ? (
                                <DonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 8 ? (
                                <DoubleChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : (
                                <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              )}
                            </>

                          ) : graphics[currentGraphicIndex].show_graphic === "historic" ? (
                            <>
                              {graphics[currentGraphicIndex].show_graphic_graphic == 6 ? (
                                <LineChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 7 ? (
                                <LineChartWhitOutScales chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 9 ? (
                                <LineChartSteps chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 11 ? (
                                <BarChartHistoric chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 23 ? (
                                <MonthsReportsHistoric chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 100 ? (
                                <MonthsReportsHistoricBarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : (
                                <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              )}
                            </>
                          ) : graphics[currentGraphicIndex].show_graphic === "radar" ? (
                            <>
                              {graphics[currentGraphicIndex].show_graphic_graphic == 10 ? (
                                <RadarChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : (
                                <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              )}
                            </>
                          ) : graphics[currentGraphicIndex].show_graphic === "special" ? (
                            <>
                              {graphics[currentGraphicIndex].show_graphic_graphic == 14 ? (
                                <BarChartHorizontal chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 15 ? (
                                <LineOpenCloseChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 16 ? (
                                <GeoXTypeChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 17 ? (
                                <LineDoubleOpenClose chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 18 ? (
                                <DispersionChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 19 ? (
                                <BarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 20 ? (
                                <GeneroChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 21 ? (
                                <DistibutionStateSeverity chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 22 ? (
                                <DistibutionStateType chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 22 ? (
                                  <DistibutionStateType chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 23 ? (
                                  <InnovaDistribution chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 24 ? (
                                  <InnovaDistribution chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 25 ? (
                                  <InnovaDistribution chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 26 ? (
                                  <InnovaDistribution chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 27 ? (
                                  <InnovaDistribution chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 28 ? (
                                  <InnovaDonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 29 ? (
                                  <InnovaDonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 30 ? (
                                  <InnovaDonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 31 ? (
                                  <InnovaDonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 32 ? (
                                  <InnovaDonutChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 33 ? (
                                  <InnovaMexicoGeoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 34 ? (
                                  <InnovaMexicoGeoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 35 ? (
                                  <InnovaMexicoGeoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 36 ? (
                                  <InnovaMexicoGeoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 37 ? (
                                  <InnovaMexicoGeoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 38 ? (
                                  <InnovaBarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 39 ? (
                                  <InnovaBarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 40 ? (
                                  <InnovaBarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 41 ? (
                                  <InnovaBarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 42 ? (
                                  <InnovaBarrasEstadoStack chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 43 ? (
                                  <InnovaMultiVerticalBars chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 44 ? (
                                  <InnovaSpecificMultiVerticalBars chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 45 ? (
                                  <InnovaZonaMexicoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                                ) : graphics[currentGraphicIndex].show_graphic_graphic == 46 ? (
                                  <InnovaDistributionCDMX chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : (
                                <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              )}
                            </>
                          ) : graphics[currentGraphicIndex].show_graphic === "geographic" ? (
                            <>
                              {graphics[currentGraphicIndex].show_graphic_graphic == 12 ? (
                                <WorldBubbleChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : graphics[currentGraphicIndex].show_graphic_graphic == 13 ? (
                                <MexicoChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              ) : (
                                <PieChart chartData={graphics[currentGraphicIndex]} startDate={startDate} endDate={endDate} maximize={true} />
                              )}
                            </>
                          ) : (
                            <>
                              <LineChartWhitOutScales chartData={graphics[currentGraphicIndex]} maximize={true} />
                            </>
                          )}
                        </div>
                      )}
                    </Modal.Body>
                  </Modal>

                  <Col sm={2} className={`option-menu ${theme} `}>
                    <div className={`graphic-options ${theme}`}>
                      <MCTooltip text={t("Graphics.options")} position="bottom">
                        <DropdownButton
                          key="end"
                          id="dropdown-button-drop-end"
                          drop="end"
                          title="ooo"
                        >
                          {/* <Dropdown.Item eventKey="1">
                                {t("dashboard.graphics.dropdown.changeRange")}
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="2">
                                {t("dashboard.graphics.dropdown.asignRangue")}
                              </Dropdown.Item>
                              <Dropdown.Divider /> */}
                          <Dropdown.Item
                            eventKey="4"
                            onClick={() => deleteGraphic(graphicItem.path)}
                          >
                            {t("Graphics.delete")}
                          </Dropdown.Item>
                          {graphicItem.addTwoColumns ? (
                            <Dropdown.Item
                              eventKey="4"
                              onClick={() => oneColumn(graphicItem.path)}
                            >
                              {t("Graphics.add_one_column")}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              eventKey="4"
                              onClick={() => twoColumn(graphicItem.path)}
                            >
                              {t("Graphics.add_two_column")}
                            </Dropdown.Item>
                          )}
                        </DropdownButton>
                      </MCTooltip>
                    </div>
                  </Col>
                </Col>
              </div>

              {Object.keys(graphicItem.indicators).length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`graphic-container ${isLoading && currentIndex === index ? "show-loading" : ""
                        }`}
                    >
                      <LoadingGraphic />
                      <Row className="graphic-body">
                        {graphicItem.show_graphic_graphic !== "19" && graphicItem.show_graphic_graphic !== "16" && graphicItem.show_graphic_graphic !== "22" ? (
                          <Col lg={elementVisibilityIndicators[index] ? 8 : 12}>
                            <div className="graphic-switch ">
                              <Icon
                                size={16}
                                className="graphic-option"
                                name="bar_chart_bold"
                              />
                              <FormCheck
                                type="switch"
                                checked={elementVisibilityIndicators[graphicItem.index]}
                                onChange={() => toggleElementVisibilityIndicator(graphicItem.index)}
                              />
                              <Icon
                                size={16}
                                className="data-option"
                                name="outline_chart_bold"
                              />
                            </div>
                          </Col>
                        ) :
                          (
                            <></>
                          )}
                        {elementVisibility[graphicItem.index] ? (
                          <div className="graphic-indicators">
                            <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} />
                          </div>
                        ) : (
                          <Col lg={elementVisibilityIndicators[graphicItem.index] ? 8 : 12} className="graphic-element">
                            {graphicItem.show_graphic === "bars" ? (
                              <>
                                {graphicItem.show_graphic_graphic == 3 ? (
                                  <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 4 ? (
                                  <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 5 ? (
                                  <ParetoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : (
                                  <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                )}
                              </>
                            ) : graphicItem.show_graphic === "circles" ? (
                              <>
                                {graphicItem.show_graphic_graphic == 1 ? (
                                  <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 2 ? (
                                  <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 8 ? (
                                  <DoubleChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : (
                                  <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                )}
                              </>
                            ) : graphicItem.show_graphic === "historic" ? (
                              <>
                                {graphicItem.show_graphic_graphic == 6 ? (
                                  <LineChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 7 ? (
                                  <LineChartWhitOutScales chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 9 ? (
                                  <LineChartSteps chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 11 ? (
                                  <BarChartHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 23 ? (
                                  <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 100 ? (
                                  <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : (
                                  <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                )}
                              </>
                            ) : graphicItem.show_graphic === "radar" ? (
                              <>
                                {graphicItem.show_graphic_graphic == 10 ? (
                                  <RadarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : (
                                  <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                )}
                              </>
                            ) : graphicItem.show_graphic === "special" ? (
                              <>
                                {graphicItem.show_graphic_graphic == 14 ? (
                                  <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 15 ? (
                                  <LineOpenCloseChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 16 ? (
                                  <GeoXTypeChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 17 ? (
                                  <LineDoubleOpenClose chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 18 ? (
                                  <DispersionChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 19 ? (
                                  <BarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 20 ? (
                                  <GeneroChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 21 ? (
                                  <DistibutionStateSeverity chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 22 ? (
                                  <DistibutionStateType chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                ) : graphicItem.show_graphic_graphic == 100 ? (
                                  <MonthsReportsHistoricBarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 23 ? (
                                    <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 24 ? (
                                    <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 25 ? (
                                    <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 26 ? (
                                    <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 27 ? (
                                    <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 28 ? (
                                    <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 29 ? (
                                    <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 30 ? (
                                    <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 31 ? (
                                    <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 32 ? (
                                    <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 33 ? (
                                    <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 34 ? (
                                    <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 35 ? (
                                    <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 36 ? (
                                    <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 37 ? (
                                    <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 38 ? (
                                    <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 39 ? (
                                    <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 40 ? (
                                    <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 41 ? (
                                    <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 42 ? (
                                    <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 43 ? (
                                    <InnovaMultiVerticalBars chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 44 ? (
                                    <InnovaSpecificMultiVerticalBars chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 45 ? (
                                    <InnovaZonaMexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                  ) : graphicItem.show_graphic_graphic == 46 ? (
                                    <InnovaDistributionCDMX chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                    
                                ) : (
                                  <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                )}
                              </>
                            ) : graphicItem.show_graphic === "geographic" ? (
                              <>
                                {graphicItem.show_graphic_graphic == 12 ? (
                                  <WorldBubbleChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                ) : graphicItem.show_graphic_graphic == 13 ? (
                                  <MexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                ) : (
                                  <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                )}
                              </>
                            ) : (
                              <>
                                <LineChartWhitOutScales chartData={graphicItem} />
                              </>
                            )}
                          </Col>
                        )}
                        {elementVisibilityIndicators[index] ? (
                          <Col lg={4}>
                            <div className="graphic-indicaors-right" t>
                              <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} right={true} />
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`graphic-container ${isLoading && currentIndex === index ? "show-loading" : ""
                    }`}
                >
                  <LoadingGraphic />
                  <Row className="graphic-body">
                    {graphicItem.show_graphic_graphic !== "19" && graphicItem.show_graphic_graphic !== "16" && graphicItem.show_graphic_graphic !== "22" ? (
                      <div className="graphic-switch">
                        <Icon
                          size={16}
                          className="graphic-option"
                          name="bar_chart_bold"
                        />
                        <FormCheck
                          type="switch"
                          checked={elementVisibility[graphicItem.index]}
                          onChange={() => toggleElementVisibility(graphicItem.index)}
                        />
                        <Icon
                          size={16}
                          className="data-option"
                          name="outline_chart_bold"
                        />
                      </div>
                    ) :
                      (
                        <></>
                      )}
                    {elementVisibility[graphicItem.index] ? (
                      <div className="graphic-indicators">
                        <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} />
                      </div>
                    ) : (
                      <Col lg={12} className="graphic-element">
                        {graphicItem.show_graphic === "bars" ? (
                          <>
                            {graphicItem.show_graphic_graphic == 3 ? (
                              <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 4 ? (
                              <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 5 ? (
                              <ParetoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : (
                              <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            )}
                          </>
                        ) : graphicItem.show_graphic === "circles" ? (
                          <>
                            {graphicItem.show_graphic_graphic == 1 ? (
                              <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 2 ? (
                              <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 8 ? (
                              <DoubleChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : (
                              <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            )}
                          </>
                        ) : graphicItem.show_graphic === "historic" ? (
                          <>
                            {graphicItem.show_graphic_graphic == 6 ? (
                              <LineChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 7 ? (
                              <LineChartWhitOutScales chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 9 ? (
                              <LineChartSteps chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 11 ? (
                              <BarChartHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 23 ? (
                              <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 100 ? (
                              <MonthsReportsHistoricBarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : (
                              <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            )}
                          </>
                        ) : graphicItem.show_graphic === "radar" ? (
                          <>
                            {graphicItem.show_graphic_graphic == 10 ? (
                              <RadarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : (
                              <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            )}
                          </>
                        ) : graphicItem.show_graphic === "special" ? (
                          <>
                            {graphicItem.show_graphic_graphic == 14 ? (
                              <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 15 ? (
                              <LineOpenCloseChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 16 ? (
                              <GeoXTypeChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 17 ? (
                              <LineDoubleOpenClose chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 18 ? (
                              <DispersionChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 19 ? (
                              <BarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 20 ? (
                              <GeneroChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 21 ? (
                              <DistibutionStateSeverity chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : graphicItem.show_graphic_graphic == 22 ? (
                              <DistibutionStateType chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 23 ? (
                                <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 24 ? (
                                <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 25 ? (
                                <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 26 ? (
                                <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 27 ? (
                                <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 28 ? (
                                <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 29 ? (
                                <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 30 ? (
                                <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 31 ? (
                                <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 32 ? (
                                <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 33 ? (
                                <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 34 ? (
                                <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 35 ? (
                                <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 36 ? (
                                <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 37 ? (
                                <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 38 ? (
                                <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 39 ? (
                                <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 40 ? (
                                <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 41 ? (
                                <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 42 ? (
                                <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 43 ? (
                                <InnovaMultiVerticalBars chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 44 ? (
                                <InnovaSpecificMultiVerticalBars chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 45 ? (
                                <InnovaZonaMexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                              ) : graphicItem.show_graphic_graphic == 46 ? (
                                <InnovaDistributionCDMX chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            ) : (
                              <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                            )}
                          </>
                        ) : graphicItem.show_graphic === "geographic" ? (
                          <>
                            {graphicItem.show_graphic_graphic == 12 ? (
                              <WorldBubbleChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                            ) : graphicItem.show_graphic_graphic == 13 ? (
                              <MexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                            ) : (
                              <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                            )}
                          </>
                        ) : (
                          <>
                            <LineChartWhitOutScales chartData={graphicItem} />
                          </>
                        )}
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </Col>
          ))}
        </Row>
      ))
      }
    </LocalizationProvider>
  );
};
