

export const reportByMonth = (dataTable) => {

    // Función para calcular el mes y año en base a los días transcurridos
    function calculateMonthYearFromDays(days) {
        const today = new Date();
        const reportDate = new Date();
        reportDate.setDate(today.getDate() - days);
        const month = reportDate.toLocaleString('default', { month: 'long' }).toUpperCase();
        const year = reportDate.getFullYear();
        return { month, year };
    }

    const reportByYear = {};

    // Iterar sobre dataTable.data[0].data[1]
    dataTable.data[0].data[1].forEach((daysOld) => {
        const { month, year } = calculateMonthYearFromDays(daysOld);

        // Crear la estructura por año
        if (!reportByYear[year]) {
            reportByYear[year] = {};
        }

        // Crear el mes dentro del año
        if (!reportByYear[year][month]) {
            reportByYear[year][month] = 0;
        }

        // Incrementa el conteo del mes correspondiente
        reportByYear[year][month]++;
    });

    // Ordenar los años
    const sortedYears = Object.keys(reportByYear).sort((a, b) => parseInt(a) - parseInt(b));

    // Ordenar los meses dentro de cada año
    const monthOrder = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

    const sortedReportByYearMonth = sortedYears.reduce((sorted, year) => {
        const sortedMonths = Object.keys(reportByYear[year])
            .sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b))
            .reduce((monthsSorted, month) => {
                monthsSorted[month] = reportByYear[year][month];
                return monthsSorted;
            }, {});

        sorted[year] = sortedMonths;
        return sorted;
    }, {});

    

    return sortedReportByYearMonth;
};
