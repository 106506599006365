import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../../colors"; // Importar los colores definidos


// NO ENCUENTRO LOS CATALOGOS

export const InnovaDonutChart = ({ chartData, startDate, endDate }) => {
    
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`innovadonutchart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate






    let catalogo = "";


    if (chartData.show_graphic_graphic) {
        // let newCatalogo = "";
        let newCatalogo = "donde_sucedieron_los_hechos";
        // const tipo = chartData.show_graphic_graphic;

        // switch (tipo) {
        //     case '28':
        //         newCatalogo = "estado_innovasport";
        //         break;
        //     case '29':
        //         newCatalogo = "estado_invictus";
        //         break;
        //     case '30':
        //         newCatalogo = "estado_overtime";
        //         break;
        //     case '31':
        //         newCatalogo = "estado_culto";
        //         break;
        //     case '32':
        //         newCatalogo = "ameshop_estado";
        //         break;
        //     default:
        //         newCatalogo = catalogo;
        // }

        if (newCatalogo && newCatalogo !== catalogo) {
            catalogo = 'C::OWN::' + newCatalogo;
        }
    }




    const processDenuncias = (chartData, catalogo) => {
        const reportMap = {};
    
        // Función para capitalizar la primera letra de cada palabra
        const capitalizeWords = (str) => {
            return str
                .toLowerCase() // Aseguramos que el texto esté en minúsculas primero
                .split(' ') // Separamos las palabras
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizamos la primera letra
                .join(' '); // Volvemos a unir las palabras
        };
    
        chartData.data[0].data[2].forEach((subArray) => {
            let tipoDenuncia = "unknown";
            let catalogoEncontrado = false;


    
            subArray.forEach((item) => {
                // Verificamos si el item coincide con el catálogo actual
                if (item.key === catalogo) {
                    catalogoEncontrado = true;  
                }
                
                if (item.key === 'C::EG::RD-102101') {
                    tipoDenuncia = item.value.replace(/type::/g, ''); 
                }
            });
    
            // Solo procesamos si se encontró el catálogo
            if (catalogoEncontrado) {
                // Capitalizar tipoDenuncia antes de agregar al reportMap
                tipoDenuncia = capitalizeWords(tipoDenuncia);
    
                if (!reportMap[tipoDenuncia]) {
                    reportMap[tipoDenuncia] = 0;
                }
    
                reportMap[tipoDenuncia]++;
            }
        });
    
        return reportMap;
    };
    // Uso de la función processDenuncias:
    const reportMap = processDenuncias(chartData, catalogo);




    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {
                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);
        
                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);
        
                newChart = root.container.children.push(
                    am5percent.PieChart.new(root, {
                        layout: root.verticalLayout,
                    })
                );
        
                const pieSeries = newChart.series.push(
                    am5percent.PieSeries.new(root, {
                        name: "Series",
                        valueField: "value",
                        categoryField: "category",
                    })
                );
        
                // Procesar los datos y ordenarlos por porcentaje
                const reportMap = processDenuncias(chartData, catalogo); // Asegúrate de que esta función devuelve el reportMap correctamente
        
                // Calcular el total de denuncias
                const totalDenuncias = Object.values(reportMap).reduce((acc, count) => acc + count, 0);
        
                // Crear un array para ordenar por porcentaje
                const reportArray = Object.entries(reportMap).map(([key, count]) => {
                    const percentage = (count / totalDenuncias) * 100;
                    return { tipoDenuncia: key, count, percentage };
                });
        
                // Ordenar por porcentaje de forma descendente
                reportArray.sort((a, b) => b.percentage - a.percentage);
        
                // Establecer los datos en la pieSeries
                pieSeries.data.setAll(
                    reportArray.map(({ tipoDenuncia, count }) => ({
                        category: tipoDenuncia,
                        value: count
                    }))
                );
        
                // Configurar las etiquetas
                pieSeries.labels.template.setAll({
                    fontSize: 16,  // Establecer el tamaño de fuente a 16
                    fill: am5.color(0x000000), // Cambiar el color si es necesario
                });
        
                // Add legend
                newChart.legend = am5.Legend.new(root, {});
        
                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });
        
                if (chart) {
                    chart.dispose();
                }
        
                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        generateChart();

        return () => {


            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });

            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default InnovaDonutChart;