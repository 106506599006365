import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as icon from "./../../../../../assets/icons/icon-map.js";
// import * as icon from "./../../../../../assets/icons/vuesax-outline-security-time.svg"
import open from "./../../../../../assets/icons/vuesax-bold-folder-open.svg"
import close from "./../../../../../assets/icons/vuesax-bold-close-circle.svg"
import alarm from "./../../../../../assets/icons/vuesax-outline-alarm.svg"
import received from "./../../../../../assets/icons/vuesax-outline-receive-square.svg"
import expired from "./../../../../../assets/icons/vuesax-outline-ticket-expired.svg"
import time from "./../../../../../assets/icons/vuesax-outline-timer-2.svg"
import assigned from "./../../../../../assets/icons/vuesax-outline-tag-user.svg"
import resolved from "./../../../../../assets/icons/vuesax-outline-tick-circle.svg"
import investigation from "./../../../../../assets/icons/vuesax-outline-glass.svg"
import question from "./../../../../../assets/icons/vuesax-outline-message-question.svg"
import eraser from "./../../../../../assets/icons/vuesax-outline-eraser.svg"
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos
{/* <script src="https://cdn.amcharts.com/lib/5/plugins/exporting.js"></script> */ }

export const BarChart = ({ chartData, startDate, endDate, maximize }) => {
    
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`horizontal-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const graphicTitle = chartData.title
    
    const diaInicio = startDate
    const diaFin = endDate
    const maximizes = maximize

    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);





                const newDataValue = chartData.data
                    .map((item) => ({
                        category: item.label,
                        value: item.data[0].filter((value, index) => {
                            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                            if (startDate === 0 && endDate === 0) {
                                return true;
                            } else {
                                return date >= startDate && date <= endDatePlusOneDay;
                            }
                        }).length,
                    }))
                    .filter(item => item.value > 0);

                let circlesView = true;
                const newData = chartData.filter_params.map((item, index) => {
                    let pictureSrc = "https://imgs.search.brave.com/pHNxVXruRk9h42oa1iA12pSEXJzg0zlhLZ2ijck5ses/rs:fit:500:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAzLzAwLzI0LzU5/LzM2MF9GXzMwMDI0/NTk1M19TdmloMURZ/SmJ3NHYydDkyRWhE/T1R3VzRMSXFNZ2ht/eS5qcGc";

                    // Condición para cambiar la imagen según algún criterio
                    if (item.function == "contar_ABIERTOS") {
                        pictureSrc = open;
                    } else if (item.function == "contar_CERRADO") {
                        pictureSrc = close;
                    } else if (item.function == "contar_GRAVES") {
                        pictureSrc = alarm;
                    } else if (item.function == "contar_RECIBIDO") {
                        pictureSrc = received;
                    } else if (item.function == "contar_EXPIRADOS") {
                        pictureSrc = expired;
                    } else if (item.function == "contar_PROX_EXPIR") {
                        pictureSrc = time;
                    } else if (item.function == "contar_ASIGNACIÓN") {
                        pictureSrc = assigned;
                    } else if (item.function == "contar_RESOLUCIÓN") {
                        pictureSrc = resolved;
                    } else if (item.function == "contar_INVESTIGACIÓN") {
                        pictureSrc = investigation;
                    } else if (item.function == "contar_BORRADOR") {
                        pictureSrc = eraser;
                    } else {
                        pictureSrc = "";
                        circlesView = false;
                    }

                    return {
                        category: newDataValue[index]?.category, // Mantener la categoría del newData correspondiente
                        value: newDataValue[index]?.value, // Mantener el valor del newData correspondiente
                        pictureSettings: {
                            src: pictureSrc
                        }
                    };
                })
                    .filter(item => item.value > 0);




                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panY: false,
                        panX: false,
                        paddingLeft: 0,
                        paddingRight: 30,
                        wheelX: "none",
                        wheelY: "none",
                    })
                );

                const yRenderer = am5xy.AxisRendererY.new(root, {
                    minorGridEnabled: true
                });
                yRenderer.grid.template.set("visible", false);


                if (maximizes == true)
                    yRenderer.labels.template.set("forceHidden", false);  // Ocultar etiquetas del eje Y
                else
                    yRenderer.labels.template.set("forceHidden", true);  // Ocultar etiquetas del eje Y



                const yAxis = newChart.yAxes.push(
                    am5xy.CategoryAxis.new(root, {
                        categoryField: "category",
                        renderer: yRenderer,
                        paddingRight: 40
                    })
                );

                const xRenderer = am5xy.AxisRendererX.new(root, {
                    minGridDistance: 80,
                    minorGridEnabled: true
                });

                const xAxis = newChart.xAxes.push(
                    am5xy.ValueAxis.new(root, {
                        min: 0,
                        renderer: xRenderer
                    })
                );

                const series = newChart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: "Value",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: "value",
                        categoryYField: "category",
                        sequencedInterpolation: true,
                        calculateAggregates: true,
                        maskBullets: false,
                        tooltip: am5.Tooltip.new(root, {
                            dy: -30,
                            pointerOrientation: "vertical",
                            labelText: "{category} : {valueX}"
                        })


                    })
                );

                series.columns.template.adapters.add("fill", function (fill, target) {
                    return newChart.get("colors").getIndex(series.columns.indexOf(target));
                });
                series.columns.template.adapters.add("stroke", function (stroke, target) {
                    return newChart.get("colors").getIndex(series.columns.indexOf(target));
                });

                series.columns.template.setAll({
                    strokeOpacity: 0,
                    cornerRadiusBR: 10,
                    cornerRadiusTR: 10,
                    cornerRadiusBL: 10,
                    cornerRadiusTL: 10,
                    maxHeight: 50,
                    fillOpacity: 0.8
                });

                var currentlyHovered;

                series.columns.template.events.on("pointerover", function (e) {
                    handleHover(e.target.dataItem);
                });

                series.columns.template.events.on("pointerout", function (e) {
                    handleOut();
                });



                function handleHover(dataItem) {
                    if (dataItem && currentlyHovered != dataItem) {
                        handleOut();
                        currentlyHovered = dataItem;
                        var bullet = dataItem.bullets[0];
                        bullet.animate({
                            key: "locationX",
                            to: 1,
                            duration: 600,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                }

                function handleOut() {
                    if (currentlyHovered) {
                        var bullet = currentlyHovered.bullets[0];
                        bullet.animate({
                            key: "locationX",
                            to: 0,
                            duration: 600,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                }

                var circleTemplate = am5.Template.new({});

                series.bullets.push(function (root, series, dataItem) {
                    var bulletContainer = am5.Container.new(root, {});
                    var circle = bulletContainer.children.push(
                        am5.Circle.new(
                            root,
                            {
                                radius: 34
                            },
                            circleTemplate
                        )
                    );

                    var maskCircle = bulletContainer.children.push(
                        am5.Circle.new(root, { radius: 30 })
                    );

                    // only containers can be masked, so we add image to another container
                    var imageContainer = bulletContainer.children.push(
                        am5.Container.new(root, {
                            mask: maskCircle
                        })
                    );


                    var image = imageContainer.children.push(
                        am5.Picture.new(root, {
                            templateField: "pictureSettings",
                            centerX: am5.p50,
                            centerY: am5.p50,
                            width: 60,
                            height: 60

                        })
                    );

                    return am5.Bullet.new(root, {
                        locationX: 0,
                        sprite: bulletContainer
                    });
                });

                // heatrule
                if (circlesView === true) {
                    series.set("heatRules", [
                        {
                            dataField: "valueX",
                            min: am5.color(0xe5dc36),
                            max: am5.color(0x5faa46),
                            target: series.columns.template,
                            key: "fill"
                        },
                        {
                            dataField: "valueX",
                            min: am5.color(0xe5dc36),
                            max: am5.color(0x5faa46),
                            target: circleTemplate,
                            key: "fill"
                        }
                    ]);
                }



                series.data.setAll(newData);
                yAxis.data.setAll(newData);

                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);
                cursor.lineY.set("visible", false);

                cursor.events.on("cursormoved", function () {
                    var dataItem = series.get("tooltip").dataItem;
                    if (dataItem) {
                        handleHover(dataItem)
                    }
                    else {
                        handleOut();
                    }
                })



                // var title = newChart.children.push(am5.Label.new(root, {
                //     text: "Most popular languages on StackOverflow",
                //     fontSize: 20,
                //     x: am5.percent(50),
                //     centerX: am5.percent(50)
                // }));

                // var exporting = am5plugins_exporting.Exporting.new(root, {
                //     menu: am5plugins_exporting.ExportingMenu.new(root, {
                //         align: "right",
                //         valign: "bottom"
                //     }),
                //     pdfOptions: {
                //         addURL: false
                //     }
                // });






                var title = newChart.children.push(am5.Label.new(root, {
                    text: graphicTitle,
                    fontSize: 20,
                    x: am5.percent(50),
                    y: am5.percent(0),
                    centerX: am5.percent(50),
                    visible: false // Ocultar el título inicialmente
                }));
                
                // Configuración de exportación
                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    }),
                    pdfOptions: {
                        addURL: false
                    }
                });
                
                // Evento para mostrar el título solo durante la exportación a PDF
                exporting.events.on("exportstarted", function(event) {
                    if (event.format === "pdf") {
                        title.set("visible", true); // Mostrar el título solo al exportar a PDF
                    }
                });
                
                exporting.events.on("exportfinished", function(event) {
                    title.set("visible", false); // Ocultar el título nuevamente después de exportar
                });

                series.appear();
                newChart.appear(1000, 100);


                if (chart) {
                    chart.dispose();
                }
                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== 'undefined') {
            generateChart();
        }

        return () => {


            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });



            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default BarChart;
