import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const LineChartWhitOutScales = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`lineCWOS-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title


  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {
        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

        const root = am5.Root.new(chartDivId.current);
        // root.setThemes([am5themes_Animated.new(root)]);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panY: false,
            layout: root.verticalLayout,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0
          })
        );

        var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {
          behavior: "zoomX"
        }));
        cursor.lineY.set("visible", false);


        var date = new Date();
        date.setHours(0, 0, 0, 0);


        const newData = chartData.data.map((item) => ({
          data: item.data[0].map((value, index) => {
            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
            if (startDate == 0 && endDate == 0) {
              return {
                value: value,
                date: date
              };
            } else {
              if (date >= startDate && date <= endDatePlusOneDay) {
                return {
                  value: value,
                  date: date
                };
              } else {

                return null; // o puedes usar return undefined;
              }
            }
          })

            .filter(data => data !== null) // Filtrar elementos que no pasaron la condición
        }));
        newData.sort((a, b) => b.value - a.value);


        const yAxis = newChart.yAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        }));

        const xAxis = newChart.xAxes.push(am5xy.DateAxis.new(root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: "day",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 200,
            minorLabelsEnabled: true
          }),
          tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.set("minorDateFormats", {
          day: "dd",
          month: "MM"
        });


        xAxis.data.setAll(newData.map(item => item.data));

        const series = newChart.series.push(am5xy.LineSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}"
          })
        }));


        series.bullets.push(function () {
          var bulletCircle = am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill")
          });
          return am5.Bullet.new(root, {
            sprite: bulletCircle
          })
        })

        newChart.set("scrollbarX", am5.Scrollbar.new(root, {
          orientation: "horizontal"
        }));

        series.data.setAll(
          newData.flatMap(item => {
            const groupedData = item.data.reduce((acc, curr) => {
              if (!acc[curr.date] || curr.value > acc[curr.date].value) {
                acc[curr.date] = curr;
              }
              return acc;
            }, {});

            return Object.values(groupedData);
          })
        );

        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });




        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(10),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });






        series.appear(1000);
        newChart.appear(1000, 100);


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });

      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default LineChartWhitOutScales;