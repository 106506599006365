import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "../../colors"; // Importar los colores definidos




export const InnovaDistributionCDMX = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`innovaDistibutionStateTypeCDMX-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate



    let catalogo = "";


    if (chartData.show_graphic_graphic) {
        // let newCatalogo = "";
        let newCatalogo = "donde_sucedieron_los_hechos";

        // const tipo = chartData.show_graphic_graphic;

        // switch (tipo) {
        //     case '46':
        //         newCatalogo = "estado_innovasport";
        //         break;
        //     case '24':
        //         newCatalogo = "estado_invictus";
        //         break;
        //     case '25':
        //         newCatalogo = "estado_overtime";
        //         break;
        //     case '26':
        //         newCatalogo = "estado_culto";
        //         break;
        //     case '27':
        //         newCatalogo = "ameshop_estado";
        //         break;
        //     default:
        //         newCatalogo = catalogo;
        // }

        if (newCatalogo && newCatalogo !== catalogo) {
            catalogo = 'C::OWN::' + newCatalogo;
        }
    }



    const stateReportMap = {};

    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    chartData.data[0].data[2].forEach((subArray) => {
        let estadoCDMX = "unknown";
        let estado = "unknown";
        let tipoDenuncia = "unknown";

        subArray.forEach((item) => {

            if (item.key === catalogo) {
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::').pop();
                estadoCDMX = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = capitalizeWords(estado);
                estadoCDMX = estadoCDMX.split(" ")[1];


            }
            if (item.key === 'C::OWN::tipo_de_reporte') {
                tipoDenuncia = item.value.replace(/type::/g, '');
                tipoDenuncia = capitalizeWords(tipoDenuncia);  // Capitaliza el tipo de denuncia
            }

        });

        // console.log("ESTADO CDMX", estado);


        // Filtrar solo para CDMX
        if (estadoCDMX === "CDMX" || estadoCDMX === "Ciudad De Mexico") {
            if (estado.split(" ")[0] === "INNO") {
                if (!stateReportMap[estado]) {
                    stateReportMap[estado] = {};
                }

                if (!stateReportMap[estado][tipoDenuncia]) {
                    stateReportMap[estado][tipoDenuncia] = 0;
                }
                stateReportMap[estado][tipoDenuncia]++;
            }
        }
    });

    // console.log("Mapa de estados y cantidades totales:", stateReportMap);



    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);


                var zoomableContainer = root.container.children.push(
                    am5.ZoomableContainer.new(root, {
                        width: am5.p100,
                        height: am5.p100,
                        wheelable: true,
                        pinchZoom: true
                    })
                );

                newChart = root.container.children.push(
                    am5percent.PieChart.new(root, {
                        layout: root.verticalLayout,
                    })
                );

                var series = zoomableContainer.contents.children.push(
                    am5hierarchy.Treemap.new(root, {
                        maskContent: false,
                        sort: "descending",
                        singleBranchOnly: false,
                        downDepth: 2,
                        upDepth: 0,
                        initialDepth: 2,
                        valueField: "value",
                        categoryField: "name",
                        childDataField: "children",
                        nodePaddingOuter: 0,
                        nodePaddingInner: 0
                    })
                );

                series.labels.template.setAll({
                    fill: am5.color(0x000000),
                    fontSize: 12,
                    textAlign: "center",
                    minWidth: am5.percent(100),
                    oversizedBehavior: "wrap",
                    fontWeight: "bold"
                });

                series.nodes.template.events.on("click", function (ev) {
                    var depth = ev.target.dataItem.get("depth");
                    if (depth == 2) {
                        series.selectDataItem(ev.target.dataItem.get("parent"));
                    }
                })

                series.labels.template.set("minScale", 0);
                series.get("colors").set("step", 1);


                let data = stateReportMap;

                function processData(data) {
                    var treeData = [];
                    var smallBrands = { name: "Other", children: [] };

                    for (const state in data) {
                        var brandData = { name: state, children: [] };
                        var brandTotal = 0;

                        for (const denuncia in data[state]) {
                            brandTotal += data[state][denuncia];
                        }

                        for (const denuncia in data[state]) {
                            let tipoLimpio = denuncia.replace(/type::/g, "").replace(/::/g, "").replace(/_/g, " ");
                            brandData.children.push({
                                name: `${state} / \n ${tipoLimpio}`,
                                value: data[state][denuncia],
                                fill: am5.color(0x677935),
                            });
                        }

                        if (brandTotal >= 1) {
                            treeData.push(brandData);
                        } else {
                            smallBrands.children.push(brandData);
                        }
                    }

                    if (smallBrands.children.length > 0) {
                        treeData.push(smallBrands);
                    }

                    return [{
                        name: "",
                        children: treeData
                    }];
                }


                series.data.setAll(processData(data));
                series.set("selectedDataItem", series.dataItems[0]);


                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });

                newChart.legend = am5.Legend.new(root, {});

                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        generateChart();

        return () => {
            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });
            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default InnovaDistributionCDMX;