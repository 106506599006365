import _ from "lodash";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { getInvestigation, patchInvestigation } from "../../../../../apis";
import { MCButton } from "../../../../MainComponents/Button/Button";
import { MCInput } from "../../../../MainComponents/Input/Input";
import { MCSelect } from "../../../../MainComponents/Select/Select";
import { useTranslation } from "react-i18next";
import { OutlineData, OutlineHierarchy } from "../../../../Icon";
import { parseDateToDDMMYYYY2 } from "../../../../../helpers/dateTransform";
import { useSnackbar } from "notistack";

export const AddInvolved = ({
  investigationAlias,
  formValues,
  toggleModal,
  fromForm = false,
  selectedInvolved,
  investigationList,
  setRerenderAll
}) => {
  const [t] = useTranslation("investigation");
  const { enqueueSnackbar } = useSnackbar();

  const involvedTypeOpts = [
    {
      value: "accomplice",
      label: t("involved.accomplice"),
    },
    {
      value: "offender",
      label: t("involved.offender"),
    },
    {
      value: "victim",
      label: t("involved.victim"),
    },
    {
      value: "witness",
      label: t("involved.witness"),
    },
  ];

  const [involvedType, setInvolvedType] = useState(involvedTypeOpts[0]);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [additional, setAdditional] = useState("");
  const [relationship, setRelationship] = useState("");
  const [confirm, setConfirm] = useState(true);

  const handleSubmit = async (evt) => {
    let newForm;
    evt.preventDefault();
    if (formValues === null) {
      newForm = {
        accomplice: [],
        offender: [],
        victim: [],
        witness: [],
      };
    } else {
      newForm = _.cloneDeep(formValues);
    }
    if (
      involvedType.value === "accomplice" ||
      involvedType.value === "offender" ||
      involvedType.value === "victim" ||
      involvedType.value === "witness"
    ) {
      if (
        involvedType.value in newForm &&
        Array.isArray(newForm[involvedType.value])
      ) {
        newForm[involvedType.value].push({
          rol: involvedType.value,
          kind: involvedType.value,
          relationship,
          name,
          details,
          additional,
          confirm,
        });
      } else {
        newForm[involvedType.value] = [
          {
            rol: involvedType.value,
            kind: involvedType.value,
            relationship,
            name,
            details,
            additional,
            confirm,
          },
        ];
      }
    }
    const objToSave = {
      folder: {
        involved: newForm,
      },
    };
    try {
      const resp = await patchInvestigation(investigationAlias, objToSave).then(
        () => setRerenderAll()
      );
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  //* Para crear un involucrado desde la parte de Informacion en el reporte ////

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);

  useEffect(() => {
    if (selectedInvolved) {
      setInvolvedType(involvedTypeOpts.find(item=>selectedInvolved.type == item.value));
      setName(selectedInvolved.name);
      setRelationship(selectedInvolved.occupation);
    }
  }, [selectedInvolved]);

  const returnInvestigationOptionString = (alias, advance, created_at) => {
    if (advance) {
      return `(${alias.substring(0,5)}) -${parseDateToDDMMYYYY2(created_at)} -${t("investigation.advanced")}`;
    } else {
      return `(${alias.substring(0,5)}) -${parseDateToDDMMYYYY2(created_at)} -${t("investigation.basic")}`;
    }
  };

  const handleSubmitFromForm = async () => {
    if (selectedInvestigation === null  || !selectedInvestigation?.alias) {
      enqueueSnackbar("Selecciona una investigación", { variant: "warning" }); 
      return;
    };
    try {
      const investigation = await getInvestigation(selectedInvestigation.alias);
      let involveds = investigation.response.involved;
      if (involveds === null) {
        involveds = {
          accomplice: [],
          offender: [],
          victim: [],
          witness: [],
        };
      }
      if (
        involvedType.value === "accomplice" ||
        involvedType.value === "offender" ||
        involvedType.value === "victim" ||
        involvedType.value === "witness"
      ) {
        const itExist = involveds[involvedType.value].find(item=>item.name == name && item.relationship == relationship && item.kind == involvedType.value);
        if (itExist) {
          enqueueSnackbar("El involucrado ya existe en esta investigación", { variant: "warning" }); 
          return;
        }
        if (
          involvedType.value in involveds &&
          Array.isArray(involveds[involvedType.value])
        ) {
          involveds[involvedType.value].push({
            rol: involvedType.value,
            kind: involvedType.value,
            relationship,
            name,
            details,
            additional,
            confirm,
          });
        } else {
          involveds[involvedType.value] = [
            {
              rol: involvedType.value,
              kind: involvedType.value,
              relationship,
              name,
              details,
              additional,
              confirm,
            },
          ];
        }
      }
      const objToSave = {
        folder: {
          involved: involveds,
        },
      };
      const resp = await patchInvestigation(selectedInvestigation.alias, objToSave);
      setRerenderAll();
      toggleModal();
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" }); 
    }
  };


  return (
    <div className="edit-datos-page">
      <Row>
        <h5>{t("involved.people_involved_investigation")}</h5>
      </Row>
      <Row>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("involved.type_involved")}:</small>
          <MCSelect
            options={involvedTypeOpts}
            value={involvedType}
            onChange={setInvolvedType}
            isDisabled={fromForm}
          />
        </Col>

        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("involved.name")}:</small>
          <MCInput
            type="text"
            placeholder={t("involved.enter_name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={fromForm}
          />
        </Col>

        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("involved.position")}:</small>
          <MCInput
            type="text"
            placeholder={t("involved.enter_position")}
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
            disabled={fromForm}
          />
        </Col>

        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("involved.area")}:</small>
          <MCInput
            type="text"
            placeholder={t("involved.enter_area")}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Col>
        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">
            {t("involved.employee_number")}:
          </small>
          <MCInput
            type="text"
            placeholder={t("involved.enter_employee_number")}
            value={additional}
            onChange={(e) => setAdditional(e.target.value)}
          />
        </Col>

        <Col md={6} lg={4} className="input-container">
          <small className="my-2 d-block">{t("involved.confirmed")}</small>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={confirm}
              checked={confirm}
              onChange={(e) => setConfirm(e.target.checked)}
              id="present-resource-checkbox"
            />
            <label
              className="form-check-label"
              htmlFor="present-resource-checkbox"
            >
              {t("involved.confirmed")}
            </label>
          </div>
        </Col>
        {
          fromForm &&
          <Col md={6} lg={4} className="input-container">
            <small className="my-2 d-block">
              Investigacion:
            </small>
            <MCSelect
              options={investigationList}
              value={selectedInvestigation}
              onChange={setSelectedInvestigation}
              menuWidth={250}
              formatOptionLabel={({ alias, advance, created_at }) =>
                advance ? (
                  <small className="selectInvestigation__option">
                    <OutlineData className="--advance" />{" "}
                    {returnInvestigationOptionString(alias, advance, created_at)}
                  </small>
                ) : (
                  <small className="selectInvestigation__option">
                    <OutlineHierarchy className="--basic" />{" "}
                    {returnInvestigationOptionString(alias, advance, created_at)}
                  </small>
                )
              }
            />
          </Col>
        }
      </Row>

      <Row>
        <div className="mt-4 d-flex justify-content-end">
          <MCButton
            label={t("involved.cancel")}
            variant="primary"
            outline
            onClick={() => toggleModal("just-close")}
            className="me-2"
          />
          {
            fromForm 
            ? <MCButton
                label={t("involved.save")}
                variant="primary"
                outline
                onClick={handleSubmitFromForm}
                />
            : <MCButton
                label={t("involved.save")}
                variant="primary"
                outline
                onClick={handleSubmit}
              />
          }
        </div>
      </Row>
    </div>
  );
};
