import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { EGButton } from "../../MainComponents/Button/EGButton/EGButton";
import {
    getGraphicsDashboard,
    utilitiesDashboard,
    modifyGraficsDefault,
    getDefaultGraphics,
    addGraficsDefaultDashboard
} from "../../../apis/apiDashboard";
import { GraphicComplements } from "./../Graphic/complements/GraphicComplements";
import "./styles.scss";


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button } from '@mui/material';
import { catalogByPart } from "../../../apis";
import { MCInput } from "../../MainComponents/Input/Input";
import { MCButton } from "../../MainComponents/Button/Button";
import _ from "lodash";
import { MCLabel } from "../../MainComponents/Label/Label";
import { MCSelect } from "../../MainComponents/Select/Select";
import Modal from 'react-bootstrap/Modal';
import BarChart from "./../Graphic/charts/BarChart/BarChart";
import { PieChart } from "./../Graphic/charts/PieChart/PieChart";
import { DonutChart } from "./../Graphic/charts/PieChart/DonutChart";
import { DoubleChart } from "./../Graphic/charts/PieChart/DoubleChart";
import { LineChart } from "./../Graphic/charts/LineChart/historicLineChart";
import { LineChartSteps } from "./../Graphic/charts/LineChart/LineChartSteps";
import { LineChartWhitOutScales } from "./../Graphic/charts/LineChart/LineChartWithoutScale";
import { MonthsReportsHistoric } from "./../Graphic/charts/LineChart/MonthsReportsHistoric";
import { LineDoubleOpenClose } from "./../Graphic/charts/SpecialChart/LineDoubleOpenClose";
import { DispersionChart } from "./../Graphic/charts/SpecialChart/GraficaDispersion";
import { BarChartHistoric } from "./../Graphic/charts/LineChart/BarChartHistoric";
import { RadarChart } from "./../Graphic/charts/RadarChart/RadarChart";
import { WorldBubbleChart } from "./../Graphic/charts/GeoChart/WorldBubbleChart";
import { GeoXTypeChart } from "./../Graphic/charts/SpecialChart/GeoXTypeChart";
import { MexicoChart } from "./../Graphic/charts/GeoChart/MexicoGeoChart";
import { GeneroChart } from "./../Graphic/charts/SpecialChart/GeneroChart";
import { BarrasEstadoStack } from "./../Graphic/charts/SpecialChart/BarrasEstadoStackChart";
import { DistibutionStateSeverity } from "./../Graphic/charts/SpecialChart/DistributionStateSeverity";
import { DistibutionStateType } from "./../Graphic/charts/SpecialChart/DistributionStateType";
import ParetoChart from "./../Graphic/charts/BarChart/ParetoChart";
import BarChartHorizontal from "./../Graphic/charts/BarChart/BarChartHorizontal";
import { LineOpenCloseChart } from "./../Graphic/charts/SpecialChart/LineOpenCloseChart";



import { LoadingGraphic } from "../../Loading/Graphic/Graphic";
import { useTranslation } from "react-i18next";


import { enqueueSnackbar } from "notistack";


export const Default = ({ graphicsData, stateLoading = null }) => {
    
    const [t] = useTranslation("dashboard");
    const [t2] = useTranslation("components");

    const { gTheme } = useSelector((state) => state.theme);
    const theme = gTheme === "dark" ? "dark" : "light";
    const [graphics, setGraphics] = useState([]);
    const [replaceData, setReplaceData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [elementVisibility, setElementVisibility] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selects, setSelects] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Para desactivar el boton de agregar filtro
    const [filterSelected, setFilterSelected] = useState(null);
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedFilterType, setSelectedFilterType] = useState(null);
    const [stateData, setStateData] = useState(null); // Aqui es donde se almacena las utilidades del dashboard
    const [filterTitle, setFilterTitle] = useState("");
    const [catalogue, setCatalogue] = useState("");
    const [is_own, setIsOwn] = useState(false);
    const [selectedFilterValue, setSelectedFilterValue] = useState(null);
    const limiteGrafica = 99;
    const [isEditing, setIsEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(graphics[0]?.title || "");
    const [showModal, setShowModal] = useState(false);
    const [modalIndex, setModalIndex] = useState(null);
    const [newFilters, setNewFilters] = useState(null);
    const [optionsChild, setOptionsChild] = useState();
    const [firstFilterSelected, setFirstFilterSelected] = useState(null)
    const [select_father, setSelect_father] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filterType, setFilterType] = useState([
        { label: t2("ModalGraph.filter_by_status"), value: 1, selected: false },
        { label: t2("ModalGraph.filter_by_field_form"), value: 2, selected: false },
        { label: t2("ModalGraph.filter_by_indicator"), value: 3, selected: false },
        { label: t2("ModalGraph.filter_by_entryway"), value: 4, selected: false },
    ]);
    const lang = localStorage.getItem("lang");    




    useEffect(() => {
  
        utilitiesDashboard().then((response) => {
            setStateData(response);

            setIsLoading(false);
        });
   

    }, []);





    const getOptionsFromUtilities = (path) => {


        const options = getElementFromUtilities(path).childs.map((item) => {
            if (item.path !== 'filter_by_entryway#field#catalogue:::C::EG::RD-102106') {
                return {
                    label: item.locales[lang] ? item.locales[lang] : item.label,
                    value: item.path,
                    key: path,
                    selected: false,
                };
            }
            return null;
        }).filter(option => option !== null);
        return options;
    };

    const getElementFromUtilities = (path) => {
        return stateData["utilities"].filter((item) => item.path === path).pop();
    };





    const changeFilterType = (e) => {

        setSelects([]);
        setSelect_father(null);
        setIsButtonDisabled(true);

        var filterTypeTmp = _.cloneDeep(filterType);
        setFilterType(
            filterTypeTmp.map((item) => {
                if (item.value === e.value) item.selected = true;
                else item.selected = false;
                return item;
            })
        );

        setFilterSelected(null);

        if (e.value === 1)
            setFilterOptions(getOptionsFromUtilities("filter_by_status"));


        if (e.value === 2)
            setFilterOptions(getOptionsFromUtilities("filter_fields_report"));

        if (e.value === 3)
            setFilterOptions(getOptionsFromUtilities("filter_by_indicator"));
        if (e.value === 4)
            setFilterOptions(getOptionsFromUtilities("filter_by_entryway"));

        setSelectedFilterType(e.value);
    };


    const changeFilterSelected = (e) => {
        resetCatalogueChilds(e);
        setFirstFilterSelected(e);

        var filterOptionsTmp = _.cloneDeep(filterOptions);
        setFilterOptions(
            filterOptionsTmp.map((item) => {
                if (item.value === e.value) item.selected = true;
                else item.selected = false;
                return item;
            })
        );

        setFilterSelected(e);

        if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) {
            if (filterTitle !== "") setIsButtonDisabled(false);
        }
    };





    const handleNestedSelect = async (select_father) => {
        setSelect_father(select_father);

        setSelectedFilterValue(select_father);

        const origin = select_father.path;
        const itemIndex = origin.split(".").pop();
        const selectedPathArray = origin.split(".");
        var newElements = null;

        selectedPathArray.pop();
        selectedPathArray.pop();

        var selectedPath = "";

        for (let i = 0; i < selectedPathArray.length; i++) {
            if (i === 0) selectedPath = selectedPathArray[i];
            else selectedPath += `.${selectedPathArray[i]}`;
        }

        const newSelects = removeNestedSelects(_.cloneDeep(selects), selectedPath);

        if (select_father.continue !== "") {
            await catalogByPart({
                catalogue: catalogue,
                is_own: is_own,
                path: select_father.value,
            }).then((response) => {
                newElements = response.data;
            });
        }

        _.set(newSelects, `${origin}.selected`, true);

        if (newElements !== null) {

            _.set(newSelects, `${selectedPath}.selected`, itemIndex);
            _.set(newSelects, `${origin}.childs`, [
                createOptionsSelects(newElements, origin),
            ]);
        }

        setSelects(newSelects);
        if (filterTitle !== "") setIsButtonDisabled(false);
    };









    const resetCatalogueChilds = (data) => {
        setCatalogue("");
        setIsOwn(false);
        setSelectedFilterValue(null);
        if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) return;

        const originCatalogue = data.value.split(":::").pop().split("::");
        const catalogueLocal = originCatalogue.pop();
        const isOwnLocal = originCatalogue.pop() === "OWN" ? true : false;
        const pathLocal = "/";
        setCatalogue(catalogueLocal);
        setIsOwn(isOwnLocal);

        catalogByPart({
            catalogue: catalogueLocal,
            is_own: isOwnLocal,
            path: pathLocal,
        }).then((response) => {
            setSelects([createOptionsSelects(response.data)]);
        });
    };





    const createOptionsSelects = (data, origin = null, selected = null) => {
        return {
            selected: selected,
            options: data.map((item, idx) => {
                return {
                    label: item.label,
                    value: item.path_locales,
                    continue: item.path,
                    path: origin
                        ? `${origin}.childs.0.options.${idx}`
                        : `0.options.${idx}`,
                    childs: [],
                    selected: false,
                };
            }),
        };
    };








    const SelectChild = ({ options, selected = null, ...rest }) => {
        setOptionsChild(options)
        return (
            <>
                <MCSelect
                    className="m-1"
                    options={options}
                    {...rest}
                    value={options.find((item) => item.selected === true)}
                />
                {selected !== null && (
                    <SelectChild
                        options={options[selected].childs[0].options}
                        selected={options[selected].childs[0].selected}
                        {...rest}
                    />
                )}
            </>
        );
    };


    const removeNestedSelects = (data, path) => {
        const selectsTmp = _.get(data, `${path}.options`);
        _.set(
            data,
            `${path}.options`,
            selectsTmp.map((opt) => {
                opt.childs = [];
                opt.selected = false;
                return opt;
            })
        );
        _.set(data, `${path}.selected`, null);
        return data;
    };






    const handleFilterTitle = (e) => {
        setFilterTitle(e.target.value);
        if (e.target.value === "") setIsButtonDisabled(true);
        else {
            if ((selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && filterSelected !== null)
                setIsButtonDisabled(false);
            else if (
                selectedFilterType === 2 &&
                filterSelected !== null &&
                selectedFilterValue !== null
            )
                setIsButtonDisabled(false);
        }
    };









    const recursiveReturnFilters = (childs, lastOptions) => {
        if (Array.isArray(childs) && childs.length > 0) {
            const options = childs[0].options || [];
            const idxOpt = options.findIndex((opt) => opt.selected === true);
            if (idxOpt >= 0) {
                return recursiveReturnFilters(options[idxOpt].childs, options);
            } else {
                return options;
            }
        } else {
            return lastOptions;
        }
    };






    useEffect(() => {
        if (!replaceData) {            
            setGraphics(Object.values(graphicsData));
            setReplaceData(true);
        }
    }, []);





    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);

    };



    const saveChanges = async () => {

        setIsModalVisible(!isModalVisible);
        const response = await modifyGraficsDefault({
            filter_params: graphics[0].filter_params,
            title: graphics[0].title,
            path: graphics[0].path,
            show_graphic_graphic: { show_graphic_graphic: graphics[0].show_graphic_graphic },
        });

        const newDefaultGraphics = await getDefaultGraphics();

        const pathToChange = graphics[0].path;


        if (response.status === 404) {
            enqueueSnackbar(response.data, {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          }
          if (response.status === 200) {
            // enqueueSnackbar(t("Graphics.obtained_data"), {
            enqueueSnackbar(t("Cambios Guardados"), {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
      

          }


       
        
        
        // const matchingGraphic = Object.values(newDefaultGraphics.graphics).find(
        //     (graphic) => graphic.path === pathToChange
        // );

        if (graphics) {
            setGraphics( [graphics[0]])
        } else {
            console.error(`No se encontró un elemento con el path ${pathToChange}`);
        }

    };


    const addToDashboard = async () => {
        try {
            let pathToSend = graphics[0].path; // Obtener el path
            const response = await addGraficsDefaultDashboard({ [pathToSend]: graphics[0] }); // Asegurarse de esperar la Promise
    
    
            // Manejo de errores y éxito
            if (response.status === 404) {
                enqueueSnackbar(response.data, {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            } else if (response.status === 200) {
                enqueueSnackbar(t("Gráfico agregado al dashboard"), {
                    variant: "success",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            }

            window.location.reload();
        } catch (error) {
            // Manejo de errores de red u otros problemas
            console.error("Error al agregar gráfico al dashboard:", error);
            enqueueSnackbar("Hubo un problema al agregar el gráfico", {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
        }
    };











    const openModal = (index) => {

        setModalIndex(index);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };


    const changeSpecificFilter = () => {
        setNewFilters((prevFilters) => {

            filterSelected.value = filterSelected.value.split("#").pop(); 
            if (select_father != null) {


                select_father.value = `${filterSelected.value}?${select_father.value}`;
            }




            const updatedFilters = {
                ...prevFilters,
                [modalIndex]: { filterSelected, filterTitle, select_father }
            };

            const updatedGraphics = [...graphics];


            if (updatedGraphics[0]?.filter_params) {

                const newFunction = select_father ? select_father.value : filterSelected.value; 

                updatedGraphics[0].filter_params = updatedGraphics[0].filter_params.map((filter, index) => {
                    if (index === modalIndex) {
                        return {
                            ...filter,
                            function: newFunction, 
                            label: filterTitle    
                        };
                    }
                    return filter;
                });
            }


            setShowModal(false);
            return updatedFilters;
        });

    };






    const handleEditClick = () => {
        setIsEditing(true); 
    };

    const handleAcceptClick = () => {
        const updatedGraphics = [...graphics];
        updatedGraphics[0].title = newTitle;
        setGraphics(updatedGraphics);
        setIsEditing(false);
    };



    const addNewFilter = (graphicIndex) => {
        setGraphics((prevGraphics) => {
            const newGraphics = [...prevGraphics];
            const newFilter = { label: "New Filter", function: "" }; 
            newGraphics[graphicIndex].filter_params.push(newFilter);
            return newGraphics;
        });
    };


    const removeFilter = (graphicIndex, filterIndex) => {
        setGraphics((prevGraphics) => {
            const newGraphics = [...prevGraphics];
            newGraphics[graphicIndex].filter_params.splice(filterIndex, 1); 
            return newGraphics;
        });
    };



    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Row>
                <Col md={12} sm={12} xs={12} xl={6}>
                    {graphics.length > 0 &&
                        graphics.map((graphicItem, index) => {
                            return (
                                <Row key={index}>
                                    <div className={`graphic-content ${theme}`} >
                                        <div
                                            className={`graphic-container ${isLoading && currentIndex === index ? "show-loading" : ""
                                                }`}
                                        >
                                            <div className={`graphic-header ${theme} `}>
                                                <Col className="subtitle" lg={8}>
                                                    {graphicItem.title}
                                                </Col>
                                            </div>
                                            <LoadingGraphic />
                                            <Row className="graphic-body">
                                                {elementVisibility[index] ? (
                                                    <div className="graphic-indicators">
                                                        <GraphicComplements chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                                    </div>
                                                ) : (
                                                    <Col lg={12} className="graphic-element">
                                                        {graphicItem.show_graphic === "bars" ? (
                                                            <>
                                                                {graphicItem.show_graphic_graphic == 3 ? (
                                                                    <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 4 ? (
                                                                    <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 5 ? (
                                                                    <ParetoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : (
                                                                    <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                )}
                                                            </>
                                                        ) : graphicItem.show_graphic === "circles" ? (
                                                            <>
                                                                {graphicItem.show_graphic_graphic == 1 ? (
                                                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 2 ? (
                                                                    <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 8 ? (
                                                                    <DoubleChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : (
                                                                    <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                )}
                                                            </>
                                                        ) : graphicItem.show_graphic === "historic" ? (
                                                            <>
                                                                {graphicItem.show_graphic_graphic == 6 ? (
                                                                    <LineChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 7 ? (
                                                                    <LineChartWhitOutScales chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 9 ? (
                                                                    <LineChartSteps chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 11 ? (
                                                                    <BarChartHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 23 ? (
                                                                    <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : (
                                                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                )}
                                                            </>
                                                        ) : graphicItem.show_graphic === "radar" ? (
                                                            <>
                                                                {graphicItem.show_graphic_graphic == 10 ? (
                                                                    <RadarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : (
                                                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                )}
                                                            </>
                                                        ) : graphicItem.show_graphic === "special" ? (
                                                            <>
                                                                {graphicItem.show_graphic_graphic == 14 ? (
                                                                    <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 15 ? (
                                                                    <LineOpenCloseChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 16 ? (
                                                                    <GeoXTypeChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 17 ? (
                                                                    <LineDoubleOpenClose chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 18 ? (
                                                                    <DispersionChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 19 ? (
                                                                    <BarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 20 ? (
                                                                    <GeneroChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 21 ? (
                                                                    <DistibutionStateSeverity chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : graphicItem.show_graphic_graphic == 22 ? (
                                                                    <DistibutionStateType chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                ) : (
                                                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                                                )}
                                                            </>
                                                        ) : graphicItem.show_graphic === "geographic" ? (
                                                            <>
                                                                {graphicItem.show_graphic_graphic == 12 ? (
                                                                    <WorldBubbleChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                                                ) : graphicItem.show_graphic_graphic == 13 ? (
                                                                    <MexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                                                ) : (
                                                                    <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <LineChartWhitOutScales chartData={graphicItem} />
                                                            </>
                                                        )}
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                </Row>
                            );
                        })
                    }
                </Col>




                <Col md={12} sm={12} xs={12} xl={6}>
                    {isModalVisible ? (
                        <div className="dyBackground research-contents" style={{ minHeight: '624px' }}>
                            <div className="dyTheme1 dyBorder1 rounded p-20" style={{ minHeight: '624px' }}>
                                <div className="pb-20 border-bottom">
                                    <div className="d-block d-md-flex align-items-center justify-content-between">
                                        <div>
                                            {/* <h5>CAMBIAR TITULOS</h5> */}
                                            <h5>{t("Graphics.modify")}</h5>
                                        </div>
                                        <div>
                                            <MCButton
                                                label={t("Graphics.save_changes")}
                                                variant="primary"
                                                outline
                                                className="me-1"
                                                onClick={() => saveChanges()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-20">
                                    <div>
                                        {!isEditing ? (
                                            <div className="d-flex align-items-center">
                                                <h5 className="me-2">{graphics[0]?.title}</h5>
                                                
                                                <EGButton
                                                    className="btn btn-sm btn-primary"
                                                    onClick={handleEditClick}
                                                    color="primary"
                                                    iconColor="white"
                                                    iconName="outline_edit"
                                                    >
                                                    
                                                </EGButton>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    value={newTitle}
                                                    onChange={(e) => setNewTitle(e.target.value)}
                                                    className="form-control me-2"
                                                    style={{ maxWidth: "300px" }}
                                                />
                                                <button
                                                    className="btn btn-sm btn-success"
                                                    onClick={handleAcceptClick}
                                                >
                                                    {t("Graphics.acept")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <h6 className="mt-20 mb-0">{t("Graphics.filters")} </h6>
                                    {graphics.length > 0 &&
                                        graphics.map((graphicItem, graphicIndex) => {
                                            return (
                                                <div key={graphicIndex}>
                                                    {graphicItem.filter_params.map((graphicLabel, index) => {
                                                        return (
                                                            <Row key={`${graphicIndex}-${index}`}>
                                                                <Col lg="4" md="12" className="mt-20 p-0">
                                                                    <small className="mb-2">{t("Graphics.filter")}  {index + 1}:</small>
                                                                    <h6>{graphicLabel.label}</h6>
                                                                </Col>
                                                                <Col lg="1" md="2" className="mt-20 p-0 buttons">
                                                                    {/* Botón de editar */}
                                                                    <EGButton
                                                                        iconName="outline_edit"
                                                                        iconSize={18}
                                                                        onClick={() => openModal(index)}
                                                                        color="primary"
                                                                        iconColor="white"
                                                                       
                                                                    />
                                             
                                                                    <EGButton
                                                                        iconName="outline_message_minus"
                                                                        iconSize={18}
                                                                        onClick={() => removeFilter(graphicIndex, index)}
                                                                        color="error"
                                                                        iconColor="white"         
                                                                        />
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}

                                                    {/* Botón para agregar un nuevo filtro */}
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <EGButton
                                                                iconName="add_circle"
                                                                iconSize={24}
                                                                onClick={() => addNewFilter(graphicIndex)}
                                                                color="primary"
                                                                iconColor="white"
                                                            >
                                                                {t("Graphics.add_new_filter")}
                                                            </EGButton>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        })
                                    }
                                    <Modal show={showModal} onHide={closeModal}>
                                        <Modal.Body>
                                            <>
                                                <div className="filter-modal">
                                                    <MCLabel text={`${t2("ModalGraph.data_series_name")}:`} />
                                                    <MCInput
                                                        type="text"
                                                        name="title"
                                                        className="m-1"
                                                        placeholder={t2("ModalGraph.enter_category_name")}
                                                        autoComplete="off"
                                                        value={filterTitle}
                                                        onChange={handleFilterTitle}
                                                    />
                                                </div>
                                                <div>
                                                    <MCLabel text={`${t2("ModalGraph.select_filter")}:`} />
                                                    <MCSelect
                                                        className="m-1"
                                                        options={filterType}
                                                        onChange={(e) => changeFilterType(e)}
                                                        value={filterType.find((item) => item.selected === true)}
                                                    />
                                                </div>
                                                {(selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && (
                                                    <div>
                                                        <MCLabel text={`${t2("ModalGraph.filter_by_status")}:`} />
                                                        <MCSelect
                                                            className="m-1"
                                                            options={filterOptions}
                                                            onChange={(e) => changeFilterSelected(e)}
                                                            value={filterOptions.find((item) => item.selected === true)}
                                                        />
                                                    </div>
                                                )}
                                                {selectedFilterType === 2 && (
                                                    <div>
                                                        <div className="d-flex justify-content-between">
                                                            <MCLabel text={`${t2("ModalGraph.filter_by_field_form")}:`} />
                                                        </div>
                                                        <MCSelect
                                                            className="m-1"
                                                            options={filterOptions}
                                                            onChange={(e) => changeFilterSelected(e)}
                                                            value={filterOptions.find((item) => item.selected === true)}
                                                        />
                                                    </div>
                                                )}

                                                {selects.length > 0 && <hr className="my-4" />}

                                                {selects.map((item) => {
                                                    return (
                                                        <SelectChild
                                                            options={item.options}
                                                            selected={item.selected}
                                                            onChange={(e) => handleNestedSelect(e)}
                                                        />
                                                    );
                                                })}

                                            </>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <MCButton variant="primary" onClick={() => changeSpecificFilter()} label="APLICAR NUEVO FILTRO">
                                       
                                            </MCButton>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="dyBackground research-contents" style={{ minHeight: '624px' }}>
                            <div className="dyTheme1 dyBorder1 rounded p-20" style={{ minHeight: '624px' }}>
                                <div className="pb-20 border-bottom" >
                                    <div className="d-block d-md-flex align-items-center justify-content-between" >
                                        <div>
                                            <h5>{graphics[0]?.title} </h5>

                                        </div>
                                        <div>
                                            <MCButton
                                                label={t("Graphics.change_filters_and_data")}
                                                variant="primary"
                                                outline
                                                className="me-1"
                                                onClick={() => toggleModal()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-20">
                                    <h6 className="mt-20 mb-0">{t("Graphics.filters")} </h6>
                                    {graphics.length > 0 &&
                                        graphics.map((graphicItem, graphicIndex) => {
                                            return (
                                                graphicItem.filter_params.map((graphicLabel, index) => {
                                                    return (
                                                        <Row key={`${graphicIndex}-${index}`}> {/* Clave única basada en índices combinados */}
                                                            <Col lg="4" md="12" className="mt-20 p-0">
                                                                <small className="mb-2">{t("Graphics.filter")} {index + 1}:</small>
                                                                <h6>{graphicLabel.label}</h6> {/* Usar el elemento actual de la iteración */}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            );
                                        })
                                    }
                                </div>
                                <div className="add-dashboard-default-graphics">
                                    
                                            <MCButton
                                                label={t("Graphics.cancel")}
                                                variant="danger"
                                                outline
                                                className="me-1"
                                                onClick={() => window.location.reload()}
                                            />
                                            <MCButton
                                                label={t("Graphics.addToDashboard")}
                                                variant="primary"
                                                outline
                                                className="me-1"
                                                onClick={() => addToDashboard()}
                                            />
                                        
                                        </div>
                            </div>
                        </div>
                    )
                    }
                </Col>
            </Row>
        </LocalizationProvider>
    );
};
