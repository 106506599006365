import { Col, Row } from "react-bootstrap";
// import News from "./News/News";
import Dropdown from "react-bootstrap/Dropdown";
import { utilitiesDashboard } from "../../../apis/apiDashboard";
import { getLastReport, getDefaultGraphics, addGraficsDefaultDashboard, createCopyOfDefault, getCatalogue, getCataloguedetail, createDashboard } from "../../../apis/apiDashboard";
import { useEffect, useState } from "react";
import { Loading } from "../../Loading/String/Loading";
import { Default } from "./Default";
import "./../Dashboard.scss";

import { MCTooltip } from "../../MainComponents/Tooltip/Tooltip";
import DropdownButton from "react-bootstrap/DropdownButton";


import { useTranslation } from "react-i18next";

import { LoadingGraphic } from "../../Loading/Graphic/Graphic";




import { GraphicComplements } from "./../Graphic/complements/GraphicComplements";
import BarChart from "./../Graphic/charts/BarChart/BarChart";
import { PieChart } from "./../Graphic/charts/PieChart/PieChart";
import { DonutChart } from "./../Graphic/charts/PieChart/DonutChart";
import { DoubleChart } from "./../Graphic/charts/PieChart/DoubleChart";
import { LineChart } from "./../Graphic/charts/LineChart/historicLineChart";
import { LineChartSteps } from "./../Graphic/charts/LineChart/LineChartSteps";
import { LineChartWhitOutScales } from "./../Graphic/charts/LineChart/LineChartWithoutScale";
import { MonthsReportsHistoric } from "./../Graphic/charts/LineChart/MonthsReportsHistoric";
import { LineDoubleOpenClose } from "./../Graphic/charts/SpecialChart/LineDoubleOpenClose";
import { DispersionChart } from "./../Graphic/charts/SpecialChart/GraficaDispersion";
import { BarChartHistoric } from "./../Graphic/charts/LineChart/BarChartHistoric";
import { RadarChart } from "./../Graphic/charts/RadarChart/RadarChart";
import { WorldBubbleChart } from "./../Graphic/charts/GeoChart/WorldBubbleChart";
import { GeoXTypeChart } from "./../Graphic/charts/SpecialChart/GeoXTypeChart";
import { MexicoChart } from "./../Graphic/charts/GeoChart/MexicoGeoChart";
import { GeneroChart } from "./../Graphic/charts/SpecialChart/GeneroChart";
import { BarrasEstadoStack } from "./../Graphic/charts/SpecialChart/BarrasEstadoStackChart";
import { DistibutionStateSeverity } from "./../Graphic/charts/SpecialChart/DistributionStateSeverity";
import { DistibutionStateType } from "./../Graphic/charts/SpecialChart/DistributionStateType";
import ParetoChart from "./../Graphic/charts/BarChart/ParetoChart";
import BarChartHorizontal from "./../Graphic/charts/BarChart/BarChartHorizontal";
import { LineOpenCloseChart } from "./../Graphic/charts/SpecialChart/LineOpenCloseChart";


import { InnovaDistribution } from "./../Graphic/charts/SpecialChart/InnovaCharts/innovaDistribution";
import { InnovaDistributionCDMX } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaDistributionCDMX";
import { InnovaDonutChart } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaDonutChart";
import { InnovaMexicoGeoChart } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaMexicoGeoChart";
import { InnovaZonaMexicoChart } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaZonaMexicoChart";
import { InnovaBarrasEstadoStack } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaBarrasEstadoStackChart";
import { InnovaMultiVerticalBars } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaMultiVerticalBars";
import { InnovaSpecificMultiVerticalBars } from "./../Graphic/charts/SpecialChart/InnovaCharts/InnovaSpecificMultiVerticalBars";


import { enqueueSnackbar } from "notistack";


import { dataList } from "./innovaTemplate"







export const GraphicsTemplates = () => {
  const lang = localStorage.getItem("lang");
  const tenant = localStorage.getItem("Tenant");
  const [t] = useTranslation("components");
  const [t2] = useTranslation("dashboard")
  const [isLoading, setIsLoading] = useState(true);
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [loadingGraphics, setLoadingGraphics] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [graphics, setGraphics] = useState({});
  const [lastReport, setLastReport] = useState({});
  const [stateData, setStateData] = useState(null); // Aqui es donde se almacena las utilidades del dashboard
  const [typeGrafic, setTypeGrafic] = useState(null); // Como se va a mostrar el grafico
  const [titleGrafic, setTitleGrafic] = useState(""); // Titulo del grafico
  const [typeDataGrafic, setTypeDataGrafic] = useState("get_data#type#count"); // Que tipo de datos se van a mostrar en el grafico
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Para desactivar el boton de agregar filtro
  const [typeOfBar, setTypeOfBar] = useState(null);
  const [graphicsDefault, setGraphicsDefault] = useState({});
  const [hideDefaultGraphics, setHideDefaultGraphics] = useState(false)



  const [typeGraficGrafic, setTypeGraficGrafic] = useState(null); // Como se va a mostrar el grafico
  const [typeOfCircleChart, setCircleTypeOfChart] = useState("");
  const [typeOfBarChart, setBarTypeOfChart] = useState("");
  const [typeOfHistoricChart, setHistoricTypeOfChart] = useState("");
  const [typeOfRadarChart, setRadarTypeOfChart] = useState("");
  const [typeOfGeographicChart, setGeographicTypeOfChart] = useState("");
  const [typeOfSpecialChart, setSpecialTypeOfChart] = useState("");
  const [selectGraphic, setSelectGraphic] = useState("false");





  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [elementVisibility, setElementVisibility] = useState([]);



  useEffect(() => {
    if (!modalOpen) {
      setIsLoading(true);
      getDataElement();
    }
  }, []);
  useEffect(() => {
    if (loadingIndicators) getDataElement();
  }, [loadingIndicators]);
  useEffect(() => {
    if (loadingGraphics) getDataElement();
  }, [loadingGraphics]);




  const [graphicsDefaultToShow, setGraphicsDefaultToShow] = useState({});
  const callAllGraphics = async () => {
    try {
      // Inicia la carga
      const response = await getDefaultGraphics();

      // Guarda las gráficas obtenidas
      setGraphicsDefaultToShow(Object.values(response.graphics));
      setGraphics(response.graphics)
    } catch (error) {
    } finally {
      // Finaliza la carga
      setLoadingGraphics(false);
    }
  };
  useEffect(() => {


    callAllGraphics();
  }, []);

  const [catalogoClaves, setCatalogoClaves] = useState();
  const [catalogoValores, setCatalogovalores] = useState(null);
  const [cread, setCreated] = useState(null);
  useEffect(() => {
    if (loadingGraphics) {
      // Si está cargando, no hacemos nada.

      return;
    }

    if (Object.keys(graphicsDefaultToShow).length === 0 && loadingGraphics) {
      // Si no hay gráficas, crea todas.
      //createAllGraphics(catalogoValores);
      //getDataElement()
      return;
    }

    const keys = Object.keys(graphicsDefaultToShow);

    const funciones = keys.flatMap(item =>
      graphicsDefaultToShow[item]?.filter_params?.map(func => func.function) || []
    );


    const hasEspecial = funciones.some(funcion => funcion.includes("especial"));

    if (hasEspecial && funciones.length != 0 && loadingGraphics == false) {
    } else {
      try {
        createAllGraphics(catalogoValores);
      } catch (error) {


      } finally {

      }

    }
  }, [graphicsDefaultToShow, loadingGraphics]);






  const CreateGraphicsInnova = async () => {

    // Limpiar el valor de tenant
    const cleanTenant = tenant.trim().replace(/['"]/g, '');
    console.log("Tenant limpio:", cleanTenant);
    
    
    
    if (cleanTenant === "odoo_innlinelineaeticamx") {
      for (const data of dataList) {
        try {
          const response = await createDashboard(data);
        } catch (error) {
          console.error("Error al crear el dashboard:", error);
        }
      }
    }
  };






  /**
     * Funciones para contruir las graficas por default a partir de los catalogos
     */
  const createAllGraphics = async () => {
    try {
      // Obtener todos los catálogos
      const catalogues = await fetchCatalogue();

      // Iterar sobre los catálogos y crear gráficas secuencialmente
      for (const catalogue of catalogues.data.catalogues) {
        // Obtener los valores del catálogo específico
        const catalogValues2 = await fetchCataloguedetail(catalogue.code);

        // Crear la gráfica con los valores obtenidos
        await CreateGraphicsDefaut(catalogValues2, catalogue.code, catalogue.label);
      }

      await CreateGraphicsInnova();



    } catch (error) {
      console.error("Error al crear las gráficas:", error);
    } finally {
      callAllGraphics();
    }
  };


  const CreateGraphicsDefaut = async (filtros, code, label) => {
    const entry = {
      "contar_WEBSITE": "Web",
      "contar_PHONE": "Phone",
      "contar_EMAIL": "Email",
      "contar_CHAT": "Chat",
      "contar_WHATSAPP": "Whatsapp",
      "contar_APP": "App",
      "contar_OUTDOOR": "Outhdoor",
      "contar_OTHER": "Other"
    }

    const lang = localStorage.getItem("lang");



    let params = null;
    if (code == 'RD-102109') {
      params = Object.entries(entry).map(([key, value]) => {
        return {
          label: value, // El valor del objeto
          function: "filter_by_entryway#type#" + key // La clave del objeto
        };
      });
    } else {
      params = filtros.data.data.map(item => {
        return {
          label: item.locales[lang] ? item.locales[lang] : item.label,
          function: "filter_by_indicator#type#especial&" + item.path_locales + '$' + code
        }
      })
    }




    let data = {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: label /*titleGrafic*/,
          filter_params: params /*stackFilters*/,
          show_graphic: "show_graphic#type#circles",
          show_graphic_graphic: 2,
          get_data: "get_data#type#count",
          // get_data: typeDataGrafic.value,
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    };

    const response = await createDashboard(data);
  }





















  const fetchCatalogue = async () => {
    try {
      const result = await getCatalogue(); // Esperar a que la promesa se resuelva
      //setCatalogoClaves(result); // Asignar las opciones de filtro
      const labels = result.data.catalogues.map(item => {
        return item.label
      })
      return result
    } catch (error) {
      console.error("Error fetching catalogue:", error);
    }
  }
  const fetchCataloguedetail = async (catalogo) => {
    const data = {
      "is_own": false,
      "catalogue": catalogo,
      "path": "/"
    }
    try {
      const result = await getCataloguedetail(data); // Esperar a que la promesa se resuelva
      //setCatalogovalores(result); // Asignar las opciones de filtro
      return result
    } catch (error) {
      console.error("Error fetching catalogue:", error);
    }
  }






  const getDataElement = async () => {
    const response = await getDefaultGraphics();
    const lastReport = await getLastReport();
    setGraphics(response?.graphics);
    setLastReport(lastReport);
    setIsLoading(false);
    setLoadingIndicators(false);
    //setLoadingGraphics(false);
  };

  const breadcrumbItems = [
    { label: t("dashboard.home"), route: "/" },
    { label: "Dashboard" },
  ];


  useEffect(() => {

    utilitiesDashboard().then((response) => {
      setStateData(response);
      setIsLoading(false);
    });

  }, []);



  const getOptionsFromElementUtilities = (path) => {


    const options = getElementFromUtilities(path).childs.map((item) => {
      if (item.path !== 'filter_by_entryway#field#catalogue:::C::EG::RD-102106') {
        return {
          label: item.locales[lang] ? item.locales[lang] : item.label,
          value: item.path,
          key: path,
          selected: false,
        };
      }
      return null;
    }).filter(option => option !== null);
    return options;
  };

  const getElementFromUtilities = (path) => {
    return stateData["utilities"].filter((item) => item.path === path).pop();
  };



  const handleTypeGrafic = (e) => {
    setTypeGrafic(e);
    if (
      titleGrafic !== "" &&
      typeDataGrafic !== null
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);

    setTypeOfBar(e.value);
  };


  function getOptionsCircleChart(type) {
    if (type === "get_data_circle") {
      return [
        { label: t("ModalGraph.pie_chart"), value: 1 },
        { label: t("ModalGraph.donut_chart"), value: 2 },
        { label: t("ModalGraph.double_chart"), value: 8 },
      ];
    } else if (type === "get_data_bar") {
      return [
        { label: t("ModalGraph.horizontal_chart"), value: 3 },
        { label: t("ModalGraph.vertical_chart"), value: 4 },
        { label: t("ModalGraph.pareto_chart"), value: 5 },
      ];
    } else if (type === "get_data_historic") {
      return [
        { label: t("ModalGraph.lines_chart_with_scale"), value: 6 },
        { label: t("ModalGraph.lines_chart_without_scale"), value: 7 },
        { label: t("ModalGraph.steps_chart"), value: 9 },
        { label: t("ModalGraph.bars_chart"), value: 11 },
        { label: "Distribución de las denuncias a traves de los meses", value: 23 }
      ];
    } else if (type === "get_data_radar") {
      return [
        { label: t("ModalGraph.radar_chart"), value: 10 },
      ];
    } else if (type === "get_data_geographic") {
      return [
        { label: t("ModalGraph.world_chart"), value: 12 },
        { label: t("ModalGraph.mexico_chart"), value: 13 },
      ];
    } else if (type === "get_data_special") {
      return [
        { label: t("ModalGraph.invalid_reports"), value: 14 },
        { label: t("ModalGraph.severe_complaints_by_state"), value: 16 },
        { label: t("ModalGraph.double_lines_open_closed"), value: 17 },
        // { label: t("ModalGraph.reports_by_severity"), value: 18 },
        // { label: t("ModalGraph.stacked_state_bars"), value: 19 },
        { label: t("ModalGraph.gender_chart"), value: 20 },
        // { label: t("ModalGraph.state_severity_distribution"), value: 21 },
        { label: t("ModalGraph.state_report_type_distribution"), value: 22 },
      ];
    }
  }




  const handleTypeDataGraficBar = (event) => {
    const selectedValue = event.value;
    setBarTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setBarTypeOfChart(event);
  };

  const handleTypeDataGraficHistoric = (event) => {
    const selectedValue = event.value;
    setHistoricTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setHistoricTypeOfChart(event);
  };
  const handleTypeDataGraficRadar = (event) => {
    const selectedValue = event.value;
    setRadarTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setRadarTypeOfChart(event);
  };
  const handleTypeDataGraficGeographic = (event) => {
    const selectedValue = event.value;
    setGeographicTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setGeographicTypeOfChart(event);
  };
  const handleTypeDataGraficSpecial = (event) => {
    const selectedValue = event.value;
    setSpecialTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setSpecialTypeOfChart(event);
  };

  const handleTypeDataGraficCircle = (event) => {
    const selectedValue = event.value;
    setCircleTypeOfChart(event);
    setTypeGraficGrafic(selectedValue);
    setCircleTypeOfChart(event);
  };










  const showGraphics = async (path) => {
    try {


      if (selectGraphic == true) {
        setSelectGraphic(false);
      }
      // Convertimos los valores de graphics en un array e iteramos
      for (const [index, g] of Object.entries(graphics)) {
        if (g.path === path) {
          setHideDefaultGraphics(true); // Cambiar el estado antes de cualquier operación asincrónica.

          // Dividimos el path y creamos una copia del gráfico
          const splitPath = g.path.split("::")[1];

          // Esperamos a que se cree la copia antes de continuar
          const copy = await createCopyOfDefault({ [splitPath]: g });

          // Actualizamos el estado de gráficos con el resultado de la copia
          setGraphicsDefault(copy);
          setSelectGraphic(true); // Cambiar el estado antes de cualquier operación asincrónica.

          // Puedes salir del bucle si solo necesitas procesar un elemento coincidente
          break;
        }
      }
    } catch (error) {
      console.error("Error en showGraphics:", error);
    }
  };







  const modifyGraphic = (path) => {
    showGraphics(path)
  }







  const addToDashboard = async (path) => {
    try {
      Object.values(graphics).forEach(async (g, index) => {
        // Comparar explícitamente los paths
        if (g.path === path) {
          const key = g.path.split("::")[1]; // Obtener la parte necesaria del path
          try {
            const response = await addGraficsDefaultDashboard({ [key]: g }); // Asegurarse de manejar la promesa correctamente


            if (response.status === 404) {
              enqueueSnackbar("Error: No se pudo agregar el gráfico", {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            } else if (response.status === 200) {
              enqueueSnackbar("Gráfico agregado al dashboard", {
                variant: "success",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            }
          } catch (error) {
            console.error(`Error al procesar gráfico en índice ${index}:`, error);
            enqueueSnackbar("Error interno al procesar la solicitud", {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          }
        }
      });
    } catch (error) {
      console.error("Error general en addToDashboard:", error);
      enqueueSnackbar("Hubo un problema al agregar gráficos al dashboard", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };



  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (

        <div id="dashboard" className={`dashboard-default`}  >

          <div className="p-1" >
            <Row>
              <Col lg="12">

                {/* <MCLabel style={{ marginLeft: "5px" }} text={`${t("ModalGraph.graph_type")}:`} />
                <MCSelect
                  className="m-1"
                  options={getOptionsFromElementUtilities("show_graphic")}
                  onChange={(e) => handleTypeGrafic(e)}
                  value={typeGrafic}
                />

                {typeOfBar == "show_graphic#type#circles" && (
                  <div >
                    <MCLabel text={`Circular`} style={{ marginLeft: "5px" }} />
                    <MCSelect
                      className="m-1"
                      options={getOptionsCircleChart("get_data_circle")}
                      onChange={(e) => handleTypeDataGraficCircle(e)}
                      value={typeOfCircleChart}
                    />
                  </div>
                )}
                {typeOfBar == "show_graphic#type#bars" && (
                  <div>
                    <MCLabel text={`Barrras`} style={{ marginLeft: "5px" }} />
                    <MCSelect
                      className="m-1"
                      options={getOptionsCircleChart("get_data_bar")}
                      onChange={(e) => handleTypeDataGraficBar(e)}
                      value={typeOfBarChart}
                    />
                  </div>
                )}

                {typeOfBar == "show_graphic#type#historic" && (
                  <div>
                    <MCLabel text={`Histórico`} style={{ marginLeft: "5px" }} />
                    <MCSelect
                      className="m-1"
                      options={getOptionsCircleChart("get_data_historic")}
                      onChange={(e) => handleTypeDataGraficHistoric(e)}
                      value={typeOfHistoricChart}
                    />
                  </div>
                )}
                {typeOfBar == "show_graphic#type#radar" && (
                  <div>
                    <MCLabel text={`Radar`} style={{ marginLeft: "5px" }} />
                    <MCSelect
                      className="m-1"
                      options={getOptionsCircleChart("get_data_radar")}
                      onChange={(e) => handleTypeDataGraficRadar(e)}
                      value={typeOfRadarChart}
                    />
                  </div>
                )}
                {typeOfBar == "show_graphic#type#geographic" && (
                  <div>
                    <MCLabel text={`Geográfica`} style={{ marginLeft: "5px" }} />
                    <MCSelect
                      className="m-1"
                      options={getOptionsCircleChart("get_data_geographic")}
                      onChange={(e) => handleTypeDataGraficGeographic(e)}
                      value={typeOfGeographicChart}
                    />
                  </div>
                )}
                {typeOfBar == "show_graphic#type#special" && (
                  <div>
                    <MCLabel text={`Especial`} style={{ marginLeft: "5px" }} />
                    <MCSelect
                      className="m-1"
                      options={getOptionsCircleChart("get_data_special")}
                      onChange={(e) => handleTypeDataGraficSpecial(e)}
                      value={typeOfSpecialChart}
                    />
                  </div>
                )}
                {modalOpen && (
                  <ModalGraph
                    show={modalOpen}
                    onHide={() => setModalOpen(false)}
                    stateLoadingIndicators={setLoadingIndicators}
                    stateLoadingGraphics={setLoadingGraphics}
                  />
                )}
                <div style={{ marginLeft: "5px", paddingTop: "5px", paddingBottom: "50px" }}>
                  <MCButton
                    label="Generar Gráfica Default"
                    variant="success"
                    onClick={() => showGraphics()}
                  >
                  </MCButton>
                </div> */}


                {hideDefaultGraphics === false && graphicsDefaultToShow ? (
                  <>

                    {graphicsDefaultToShow.length > 0 &&
                      graphicsDefaultToShow.map((graphicItem, index) => {

                        return (
                          <Row key={index}>
                            <div className={`graphic-content`} >

                              <div
                                className={`graphic-container ${isLoading && currentIndex === index ? "show-loading" : ""
                                  }`}
                              >
                                <div className={`graphic-header `}>
                                  <Col className="subtitle" lg={8}>
                                    {graphicItem.title}
                                  </Col>
                                  <Col sm={2} className={`option-menu `}>
                                    <div className={`graphic-options `}>
                                      <MCTooltip text={t2("Graphics.options")} position="bottom">
                                        <DropdownButton
                                          key="end"
                                          id="dropdown-button-drop-end"
                                          drop="end"
                                          title="ooo"
                                        >

                                          <Dropdown.Item
                                            eventKey="4"
                                            onClick={() => addToDashboard(graphicItem.path)}
                                          >
                                            {t2("Graphics.addToDashboard")}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="4"
                                            onClick={() => modifyGraphic(graphicItem.path)}
                                          >
                                            {t2("Graphics.modify")}
                                          </Dropdown.Item>

                                        </DropdownButton>
                                      </MCTooltip>
                                    </div>
                                  </Col>
                                </div>
                                <LoadingGraphic />
                                <Row className="graphic-body">

                                  <Col lg={12} className="graphic-element">
                                    {graphicItem.show_graphic === "bars" ? (
                                      <>
                                        {graphicItem.show_graphic_graphic == 3 ? (
                                          <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 4 ? (
                                          <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 5 ? (
                                          <ParetoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : (
                                          <BarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        )}
                                      </>
                                    ) : graphicItem.show_graphic === "circles" ? (
                                      <>
                                        {graphicItem.show_graphic_graphic == 1 ? (
                                          <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 2 ? (
                                          <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 8 ? (
                                          <DoubleChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : (
                                          <DonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        )}
                                      </>
                                    ) : graphicItem.show_graphic === "historic" ? (
                                      <>
                                        {graphicItem.show_graphic_graphic == 6 ? (
                                          <LineChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 7 ? (
                                          <LineChartWhitOutScales chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 9 ? (
                                          <LineChartSteps chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 11 ? (
                                          <BarChartHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 23 ? (
                                          <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : (
                                          <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        )}
                                      </>
                                    ) : graphicItem.show_graphic === "radar" ? (
                                      <>
                                        {graphicItem.show_graphic_graphic == 10 ? (
                                          <RadarChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : (
                                          <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        )}
                                      </>
                                    ) : graphicItem.show_graphic === "special" ? (
                                      <>
                                        {graphicItem.show_graphic_graphic == 14 ? (
                                          <BarChartHorizontal chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 15 ? (
                                          <LineOpenCloseChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 16 ? (
                                          <GeoXTypeChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 17 ? (
                                          <LineDoubleOpenClose chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 18 ? (
                                          <DispersionChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 19 ? (
                                          <BarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 20 ? (
                                          <GeneroChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 21 ? (
                                          <DistibutionStateSeverity chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 22 ? (
                                          <DistibutionStateType chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 23 ? (
                                          <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 24 ? (
                                          <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 25 ? (
                                          <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 26 ? (
                                          <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 27 ? (
                                          <InnovaDistribution chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 28 ? (
                                          <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 29 ? (
                                          <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 30 ? (
                                          <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 31 ? (
                                          <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 32 ? (
                                          <InnovaDonutChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 33 ? (
                                          <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 34 ? (
                                          <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 35 ? (
                                          <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 36 ? (
                                          <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 37 ? (
                                          <InnovaMexicoGeoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 38 ? (
                                          <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 39 ? (
                                          <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 40 ? (
                                          <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 41 ? (
                                          <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 42 ? (
                                          <InnovaBarrasEstadoStack chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 43 ? (
                                          <InnovaMultiVerticalBars chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 44 ? (
                                          <InnovaSpecificMultiVerticalBars chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 45 ? (
                                          <InnovaZonaMexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 46 ? (
                                          <InnovaDistributionCDMX chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : graphicItem.show_graphic_graphic == 99 ? (
                                          <MonthsReportsHistoric chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        ) : (
                                          <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} maximize={false} />
                                        )}
                                      </>
                                    ) : graphicItem.show_graphic === "geographic" ? (
                                      <>
                                        {graphicItem.show_graphic_graphic == 12 ? (
                                          <WorldBubbleChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                        ) : graphicItem.show_graphic_graphic == 13 ? (
                                          <MexicoChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                        ) : (
                                          <PieChart chartData={graphicItem} startDate={startDate} endDate={endDate} />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <LineChartWhitOutScales chartData={graphicItem} />
                                      </>
                                    )}
                                  </Col>

                                </Row>
                              </div>

                            </div>
                          </Row>
                        );
                      })
                    }

                  </>

                ) : (

                  <></>

                )}

                {selectGraphic == true ? (
                  <div className="graphics-content">
                    {loadingGraphics ? (
                      <Loading />
                    ) : (
                      <Default
                        graphicsData={graphicsDefault}
                        // graphicsData={graphics}
                        stateLoading={setLoadingGraphics}
                      />
                    )}
                  </div>


                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>

        </div>
      )}
    </>
  );
};
