import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  catalogByPart,
  getFormDetails,
  listCatalogues,
  ownCatalogues,
} from "../../../apis";
import { GeneralIconHeader } from "../../Title/GeneralHeader";
import { formStarter } from "../PreviewForm/formStarter";
import { BuildSteps } from "./BuildSteps";
import { BuildForm } from "./BuildForm";
import { ModalPreview } from "./";
import "./styles.scss";
import {
  INPUT_TYPE_CATALOGUE_RADIO,
  INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_SELECT,
  INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_CHECKBOX_CONDITIONAL,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_DESCRIPTION,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_FILE,
  INPUT_TYPE_INVOLVED,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_SUBJECT,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TEXTAREA,
  isEspecialCatalogue
} from "../consts";
import { useTranslation } from "react-i18next";
import { Breadcrum } from "../../MainComponents/Breadcrumb/Breadcrumb";


export const CreateForm = () => {
  const { gTheme } = useSelector((state) => state.theme);
  const [t] = useTranslation("forms");

  // If is create form or edit form
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [create, setCreate] = useState(true);
  const [formLoaded, setFormLoaded] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [tryToCreate, setTryToCreate] = useState(false);

  // for valid creation form
  const [validFormIdentifier, setValidFormIdentifier] = useState([]);
  const [validCreateSteps, setValidCreateSteps] = useState(null);
  const [validCreateInputs, setValidCreateInputs] = useState(null);
  const [rerender, setRerender] = useState(false);

  // for form states
  const [formIdentifier, setFormIdentifier] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [activeInput, setActiveInput] = useState(0);

  const [steps, setSteps] = useState(null);
  const [schemaState, setSchemaState] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [constraints, setConstraints] = useState(null);

  const [egCataloguesState, setEgCataloguesState] = useState([]);
  const [ownCataloguesState, setOwnCataloguesState] = useState([]);

  useEffect(() => {
    if (!params.id) {
      setCreate(true);
      formStarter(null, "new").then(
        ({
          initFormIdentifier,
          initSteps,
          initSchemaState,
          initFormData,
          initIsValid,
          initConstraints,
          initValidCreateSteps,
          initValidCreateInputs,
        }) => {
          setFormIdentifier(initFormIdentifier);
          setSteps(initSteps);
          setSchemaState(initSchemaState);
          setFormData(initFormData);
          setIsValid(initIsValid);
          setConstraints(initConstraints);
          setValidCreateSteps(initValidCreateSteps);
          setValidCreateInputs(initValidCreateInputs);
          setIsLoading(false);
        }
      );
    } else {
      setCreate(false);
      getFormDetails(params.id).then((form) => {
        setFormLoaded(form);
      });
    }
  }, []);

  useEffect(() => {
    Promise.all([listCatalogues(), ownCatalogues()])
      .then(([eg, own]) => {
        setEgCataloguesState(eg.catalogues);
        setOwnCataloguesState(own.catalogues);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (!create && formLoaded) {
      formStarter(formLoaded, "normal")
      .then(({
        initFormIdentifier,
        initSteps,
        initSchemaState,
        initFormData,
        initIsValid,
        initConstraints,
        initValidCreateSteps,
        initValidCreateInputs,
      }) => {
        setFormIdentifier(initFormIdentifier);
        setSteps(initSteps);
        setSchemaState(initSchemaState);
        setFormData(initFormData);
        setIsValid(initIsValid);
        setConstraints(initConstraints);
        setValidCreateSteps(initValidCreateSteps);
        setValidCreateInputs(initValidCreateInputs);
        setIsLoading(false);
      })
      .catch(console.log);
    }
  }, [formLoaded]);

  useEffect(() => {
    setRerender(true);
  }, [activeStep]);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  const sanitizeString = (string) => {
    let outputString = string.replace(/\s+/g, "_");
    outputString = outputString.replace(/[^a-zA-Z_]/g, "");
    outputString = outputString.toLowerCase();
    return outputString;
  };

  const checkNoRepeatStep = (name, repNum = "_") => {
    const check = steps.find((stp) => name === stp.name);
    if (check) {
      return checkNoRepeatStep(`${name}${repNum}`);
    }
    return name;
  };

  const hdlFrmNme = (name) => {
    let errors = [];
    const sanitizedName = sanitizeString(name);
    if (sanitizedName.trim() === "") {
      errors.push(t("createForm.field_no_empty"));
    }
    setFormIdentifier(sanitizedName);
    setValidFormIdentifier(errors);
  };

  const hdlAddStep = () => {
    const newKey = checkNoRepeatStep("step_title");
    setSteps([
      ...steps,
      {
        name: newKey,
        title: t("createForm.step_title"),
        description: t("createForm.step.description"),
      },
    ]);
    setValidCreateSteps([...validCreateSteps, []]);
    setValidCreateInputs([...validCreateInputs, [[]]]);
    setSchemaState([
      ...schemaState,
      [
        {
          key: t("createForm.input_enter_label"),
          type: "string",
          label: t("createForm.label_name"),
          placeholder: t("createForm.enter_label_name"),
          required: false,
          sensitive: false,
          grid: 4,
          break: false,
        },
      ],
    ]);
    setFormData([...formData, [{ input_label: "" }]]);
    setIsValid([...isValid, []]);
    setActiveStep(steps.length);
  };

  const hdlDelStep = (index) => {
    if (steps.length === 1) {
      alert(t("createForm.form_must_least_one_step"));
      return;
    }

    const newSteps = _.cloneDeep(steps);
    const newValidCreateSteps = _.cloneDeep(validCreateSteps);
    const newSchema = _.cloneDeep(schemaState);
    const newFormData = _.cloneDeep(formData);
    const newIsValid = _.cloneDeep(isValid);

    newSteps.splice(index, 1);
    newValidCreateSteps.splice(index, 1);
    newSchema.splice(index, 1);
    newFormData.splice(index, 1);
    newIsValid.splice(index, 1);

    if (index > 0 && index === activeStep) {
      hdlActStp((ant) => ant - 1);
    } else if (activeStep === steps.length - 1) {
      hdlActStp((ant) => ant - 1);
    }
    // setActiveStep(0)
    setSteps(newSteps);
    setValidCreateSteps(newValidCreateSteps);
    setSchemaState(newSchema);
    setFormData(newFormData);
    setIsValid(newIsValid);
  };

  const hdlMveStp = (direction, stepIndex) => {
    let newSteps = _.cloneDeep(steps);
    let newValidCreateSteps = _.cloneDeep(validCreateSteps);
    let newValidCreateInputs = _.cloneDeep(validCreateInputs);
    let newSchemaState = _.cloneDeep(schemaState);
    let newFormData = _.cloneDeep(formData);
    let newIsValid = _.cloneDeep(isValid);
    if (direction === "up" && stepIndex === 0) return;
    if (direction === "down" && stepIndex === newSteps.length - 1) return;
    if (direction === "up") {
      hdlActStp(stepIndex - 1);
    } else if (direction === "down") {
      hdlActStp(stepIndex + 1);
    }
    const newStepsOrder = newSteps.map((step, idx) => {
      if (direction === "up") {
        if (idx === stepIndex) return newSteps[stepIndex - 1];
        if (idx === stepIndex - 1) return newSteps[stepIndex];
        return step;
      }
      if (direction === "down") {
        if (idx === stepIndex + 1) return newSteps[stepIndex];
        if (idx === stepIndex) return newSteps[stepIndex + 1];
        return step;
      }
      return step;
    });
    const newValidCreateStepsOrder = newValidCreateSteps.map((data, idx) => {
      if (direction === "up") {
        if (idx === stepIndex) return newValidCreateSteps[stepIndex - 1];
        if (idx === stepIndex - 1) return newValidCreateSteps[stepIndex];
        return data;
      }
      if (direction === "down") {
        if (idx === stepIndex + 1) return newValidCreateSteps[stepIndex];
        if (idx === stepIndex) return newValidCreateSteps[stepIndex + 1];
        return data;
      }
      return data;
    });
    const newValidCreateInputsOrder = newValidCreateInputs.map((data, idx) => {
      if (direction === "up") {
        if (idx === stepIndex) return newValidCreateInputs[stepIndex - 1];
        if (idx === stepIndex - 1) return newValidCreateInputs[stepIndex];
        return data;
      }
      if (direction === "down") {
        if (idx === stepIndex + 1) return newValidCreateInputs[stepIndex];
        if (idx === stepIndex) return newValidCreateInputs[stepIndex + 1];
        return data;
      }
      return data;
    });
    const newSchemaStateOrder = newSchemaState.map((schema, idx) => {
      if (direction === "up") {
        if (idx === stepIndex) return newSchemaState[stepIndex - 1];
        if (idx === stepIndex - 1) return newSchemaState[stepIndex];
        return schema;
      }
      if (direction === "down") {
        if (idx === stepIndex + 1) return newSchemaState[stepIndex];
        if (idx === stepIndex) return newSchemaState[stepIndex + 1];
        return schema;
      }
      return schema;
    });
    const newFormDataOrder = newFormData.map((data, idx) => {
      if (direction === "up") {
        if (idx === stepIndex) return newFormData[stepIndex - 1];
        if (idx === stepIndex - 1) return newFormData[stepIndex];
        return data;
      }
      if (direction === "down") {
        if (idx === stepIndex + 1) return newFormData[stepIndex];
        if (idx === stepIndex) return newFormData[stepIndex + 1];
        return data;
      }
      return data;
    });
    const newIsValidOrder = newIsValid.map((data, idx) => {
      if (direction === "up") {
        if (idx === stepIndex) return newIsValid[stepIndex - 1];
        if (idx === stepIndex - 1) return newIsValid[stepIndex];
        return data;
      }
      if (direction === "down") {
        if (idx === stepIndex + 1) return newIsValid[stepIndex];
        if (idx === stepIndex) return newIsValid[stepIndex + 1];
        return data;
      }
      return data;
    });
    newSteps = newStepsOrder;
    newValidCreateSteps = newValidCreateStepsOrder;
    newValidCreateInputs = newValidCreateInputsOrder;
    newSchemaState = newSchemaStateOrder;
    newFormData = newFormDataOrder;
    newIsValid = newIsValidOrder;
    setSteps(newSteps);
    setValidCreateSteps(newValidCreateSteps);
    setValidCreateInputs(newValidCreateInputs);
    setSchemaState(newSchemaState);
    setFormData(newFormData);
    setIsValid(newIsValid);
  };

  const hdlActStp = (step) => {
    setActiveStep(step);
    setActiveInput(0);
  };

  const hdlStpNme = (name, index) => {
    const newSteps = _.cloneDeep(steps);
    const sanitizedName = sanitizeString(name);
    const newKey = checkNoRepeatStep(sanitizedName);
    _.set(newSteps, `${index}.title`, name);
    _.set(newSteps, `${index}.name`, newKey);
    setSteps(newSteps);
  };

  const hdlAddInp = ({
    isNewConditional = null,
    value = null,
    inputIndex,
    entirePathSchema,
    entirePathData,
    nestLvl,
    setActiveInput,
    setRerender,
  }) => {
    // let newValidCreateInputs = _.cloneDeep(validCreateInputs);

    let newSchemaState = _.cloneDeep(schemaState);
    let newArraySchema = _.get(schemaState, `${entirePathSchema}`);

    let newFormData = _.cloneDeep(formData);
    let newArrayData = _.get(formData, `${entirePathData}`);

    let newIsValid = _.cloneDeep(isValid);
    let newArrayValid = _.get(isValid, `${entirePathData}`);

    let keyName = t("createForm.input.key");

    let inputSchema = {
      key: keyName,
      type: "string",
      label: t("createForm.label_name"),
      placeholder: t("createForm.enter_label_name"),
      required: false,
      sensitive: false,
      grid: 4,
      break: false,
    };

    let inputSchemaConditional = {
      caseOf: value,
      nestChildren: [inputSchema],
    };

    if (isNewConditional) {
      if (value === null || value === undefined) return;

      newArraySchema.push(inputSchemaConditional);
      _.set(newSchemaState, `${entirePathSchema}`, newArraySchema);
      setSchemaState(newSchemaState);

      newArrayData.push({ [keyName]: "" });
      _.set(newFormData, `${entirePathData}`, newArrayData);
      setFormData(newFormData);

      newArrayValid.push({ [keyName]: [] });
      _.set(newIsValid, `${entirePathData}`, newArrayValid);
      setIsValid(newIsValid);
    } else {
      newArraySchema.push(inputSchema);
      _.set(newSchemaState, `${entirePathSchema}`, newArraySchema);
      setSchemaState(newSchemaState);

      newArrayData.push({ [keyName]: "" });
      _.set(newFormData, `${entirePathData}`, newArrayData);
      setFormData(newFormData);

      newArrayValid.push({ [keyName]: [] });
      _.set(newIsValid, `${entirePathData}`, newArrayValid);
      setIsValid(newIsValid);
    }

    if (nestLvl === 0) {
      hdlActInp(inputIndex);
    } else {
      setActiveInput(inputIndex);
      setRerender(true);
    }
  };

  const hdlDelInp = ({
    inputIndex,
    entirePathSchema,
    entirePathData,
    nestLvl,
    setActiveInput,
    setRerender,
  }) => {
    let parentSchemaPath = entirePathSchema.split(".");
    parentSchemaPath.pop();
    parentSchemaPath = parentSchemaPath.join(".");

    let parentDataPath = entirePathData.split(".");
    parentDataPath.pop();
    parentDataPath = parentDataPath.join(".");

    let newSchema = _.cloneDeep(schemaState);
    let newArraySchema = _.get(newSchema, `${parentSchemaPath}`);
    newArraySchema.splice(inputIndex, 1);
    _.set(newSchema, parentSchemaPath, newArraySchema);

    let newFormData = _.cloneDeep(formData);
    let newArrayData = _.get(newFormData, `${parentDataPath}`);
    newArrayData.splice(inputIndex, 1);
    _.set(newFormData, parentDataPath, newArrayData);

    let newIsValid = _.cloneDeep(isValid);
    let newArrayValid = _.get(newIsValid, `${parentDataPath}`);
    newArrayValid.splice(inputIndex, 1);
    _.set(newIsValid, parentDataPath, newArrayValid);

    if (nestLvl === 0 && newArraySchema.length === 0) {
      alert(t("createForm.step_must_least_one_field"));
    } else {
      if (nestLvl === 0 && inputIndex > 0) {
        hdlActInp(inputIndex - 1);
      } else if (nestLvl !== 0 && inputIndex > 0) {
        setActiveInput(inputIndex - 1);
      }
      setSchemaState(newSchema);
      setFormData(newFormData);
      setIsValid(newIsValid);
      setRerender(true);
    }
  };

  const hdlMveInp = ({
    direction,
    inputIndex,
    entirePathSchema,
    entirePathData,
    nestLvl,
    setActiveInput,
    setRerender,
  }) => {
    // const newValidCreateInputs = _.cloneDeep(validCreateInputs);

    let parentSchemaPath = entirePathSchema.split(".");
    parentSchemaPath.pop();
    parentSchemaPath = parentSchemaPath.join(".");

    let parentDataPath = entirePathData.split(".");
    parentDataPath.pop();
    parentDataPath = parentDataPath.join(".");

    let newSchema = _.cloneDeep(schemaState);
    let newArraySchema = _.get(schemaState, `${parentSchemaPath}`);

    let newFormData = _.cloneDeep(formData);
    let newArrayData = _.get(formData, `${parentDataPath}`);

    let newIsValid = _.cloneDeep(isValid);
    let newArrayValid = _.get(isValid, `${parentDataPath}`);

    if (direction === "up" && inputIndex === 0) return;
    if (direction === "down" && inputIndex === newArraySchema.length - 1)
      return;
    if (nestLvl === 0) {
      if (direction === "up") {
        hdlActInp(inputIndex - 1);
      } else {
        hdlActInp(inputIndex + 1);
      }
    } else {
      if (direction === "up") {
        setActiveInput(inputIndex - 1);
      } else {
        setActiveInput(inputIndex + 1);
      }
    }

    // const newValidCreateInputsOrder = newValidCreateInputs[activeStep].map((input, idx) => {
    //   if (direction==="up") {
    //     if (idx===inputIndex) return newValidCreateInputs[activeStep][inputIndex-1];
    //     if (idx===inputIndex-1) return newValidCreateInputs[activeStep][inputIndex];
    //     return input;
    //   }
    //   if (direction==="down") {
    //     if (idx===inputIndex+1) return newValidCreateInputs[activeStep][inputIndex];
    //     if (idx===inputIndex) return newValidCreateInputs[activeStep][inputIndex+1];
    //     return input;
    //   }
    // });

    const newSchemaOrder = newArraySchema.map((input, idx) => {
      if (direction === "up") {
        if (idx === inputIndex) return newArraySchema[inputIndex - 1];
        if (idx === inputIndex - 1) return newArraySchema[inputIndex];
        return input;
      }
      if (direction === "down") {
        if (idx === inputIndex + 1) return newArraySchema[inputIndex];
        if (idx === inputIndex) return newArraySchema[inputIndex + 1];
        return input;
      }
    });
    const newFormDataOrder = newArrayData.map((input, idx) => {
      if (direction === "up") {
        if (idx === inputIndex) return newArrayData[inputIndex - 1];
        if (idx === inputIndex - 1) return newArrayData[inputIndex];
        return input;
      }
      if (direction === "down") {
        if (idx === inputIndex + 1) return newArrayData[inputIndex];
        if (idx === inputIndex) return newArrayData[inputIndex + 1];
        return input;
      }
    });
    const newIsValidOrder = newArrayValid.map((input, idx) => {
      if (direction === "up") {
        if (idx === inputIndex) return newArrayValid[inputIndex - 1];
        if (idx === inputIndex - 1) return newArrayValid[inputIndex];
        return input;
      }
      if (direction === "down") {
        if (idx === inputIndex + 1) return newArrayValid[inputIndex];
        if (idx === inputIndex) return newArrayValid[inputIndex + 1];
        return input;
      }
    });

    // _.set(newValidCreateInputs, `${activeStep}`, newValidCreateInputsOrder);
    _.set(newSchema, parentSchemaPath, newSchemaOrder);
    _.set(newFormData, parentDataPath, newFormDataOrder);
    _.set(newIsValid, parentDataPath, newIsValidOrder);

    // setValidCreateInputs(newValidCreateInputs);
    setSchemaState(newSchema);
    setFormData(newFormData);
    setIsValid(newIsValid);
    setRerender(true);
  };

  const hdlActInp = (inputIndex) => {
    setActiveInput(inputIndex);
  };

  const hdlChange = async ({
    pathSchema,
    pathData,
    value,
    toChange,
    selectedCatalogue,
    selectedCatalogue2,
    key,
    label,
    placeholder,
    type,
    required,
    sensitive,
    defaultAnswer,
    grid,
    catalogueInfo

  }) => {
    const newSchema = _.cloneDeep(schemaState);
    const newFormData = _.cloneDeep(formData);
    const newIsValid = _.cloneDeep(isValid);
    if (toChange === "label") {
      const lableSanitized = sanitizeString(value);
      _.set(newSchema, `${pathSchema}.label`, value);
      if (
        !(
          type.includes("catalog") ||
          type === "subject" ||
          type === "description" ||
          type === "email" ||
          type === "involucrados" 
        )
      ) {
        _.set(newSchema, `${pathSchema}.key`, lableSanitized);
        const lastData = _.get(newFormData, `${pathData}.${key}`);
        _.unset(newFormData, `${pathData}.${key}`);
        _.set(newFormData, `${pathData}.${lableSanitized}`, lastData);
      }
    } else if (toChange === "placeholder") {
      _.set(newSchema, `${pathSchema}.placeholder`, value);
    } else if (toChange === "required") {
      _.set(newSchema, `${pathSchema}.required`, value);
    } else if (toChange === "sensitive") {
      if (type === "email") return;
      _.set(newSchema, `${pathSchema}.sensitive`, value);
    } else if (toChange === "break") {
      _.set(newSchema, `${pathSchema}.break`, value);
    } else if (toChange ==="defaultAnswer") {
      _.set(newSchema, `${pathSchema}.defaultAnswer`, value);
    } else if (toChange === "hidden") {
      _.set(newSchema, `${pathSchema}.hidden`, value);
      if (!isEspecialCatalogue() && value) {
        _.set(newSchema, `${pathSchema}.required`, false);
        _.set(newSchema, `${pathSchema}.break`, false);
      }
    } else if (toChange === "role") {
      _.set(newSchema, `${pathSchema}.role`, value);
    } else if (toChange === "grid") {
      _.set(newSchema, `${pathSchema}.grid`, value);
    } else if (toChange === "type") {
      let newInput;
      let newKeyName = sanitizeString(label) || "input_label";
      if (value === INPUT_TYPE_TEXT) {
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_TEXT,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: "", sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_NUMBER) {
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_NUMBER,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: 0, sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_TEXTAREA) {
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_TEXTAREA,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: "", sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_CHECKBOX) {
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_CHECKBOX,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          defaultAnswer: false,
          grid: grid,
          break: false,
          hidden: false,
          role: null
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: false, sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_CHECKBOX_CONDITIONAL) {
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_CHECKBOX_CONDITIONAL,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          conditionals: [],
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [newKeyName]: false,
          sensitive,
          conditionals: [],
        });
        _.set(newIsValid, `${pathData}`, {
          [newKeyName]: [],
          conditionals: [],
        });
      }
      if (value === INPUT_TYPE_FILE) {
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_FILE,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: 12,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: null, sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_DATE) {
        const today = new Date();
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_DATE,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: today, sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_DATE_RANGE) {
        let today = new Date().toJSON();
        newInput = {
          key: newKeyName,
          type: INPUT_TYPE_DATE_RANGE,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [newKeyName]: `${today}__${today}`,
          sensitive,
        });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_SUBJECT) {
        newInput = {
          key: "subject",
          type: INPUT_TYPE_SUBJECT,
          label: label || t("createForm.subject"),
          placeholder: placeholder,
          required: true,
          sensitive: true,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { subject: "", sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_DESCRIPTION) {
        newInput = {
          key: "description",
          type: INPUT_TYPE_DESCRIPTION,
          label: label || t("createForm.description"),
          placeholder: placeholder,
          required: true,
          sensitive: true,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { description: "", sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_EMAIL) {
        newInput = {
          key: "email",
          type: INPUT_TYPE_EMAIL,
          label: label || "Email",
          placeholder: placeholder,
          required: required,
          sensitive: true,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { description: "", sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_INVOLVED) {
        newInput = {
          key: "involucrados",
          type: INPUT_TYPE_INVOLVED,
          label: label || t("createForm.input_label"),
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: 12,
          break: false,
          hidden: false,
          role: null,
        };
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, { [newKeyName]: [], sensitive });
        _.set(newIsValid, `${pathData}`, { [newKeyName]: [] });
      }
      if (value === INPUT_TYPE_CATALOGUE_SELECT) {
        newInput = {
          key: `C::${
            selectedCatalogue === "OWN" ? "OWN" : "EG"
          }::${selectedCatalogue2}`,
          type: INPUT_TYPE_CATALOGUE_SELECT,
          label: label,
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          scope: "/",
          selected: "",
          ...catalogueInfo
        };
        const isOwn = selectedCatalogue === "OWN" ? true : false;
        const resp = await catalogByPart({
          is_own: isOwn,
          catalogue: selectedCatalogue2,
          path: "/",
        });
        newInput.children = resp.data;
        newInput.isOwn = isOwn;
        newInput.catalogue = selectedCatalogue2;
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [selectedCatalogue2]: "",
          catalogue: selectedCatalogue2,
          isOwn,
          sensitive,
        });
        _.set(newIsValid, `${pathData}`, { [selectedCatalogue2]: [] });
      }
      if (value === INPUT_TYPE_CATALOGUE_RADIO) {
        newInput = {
          key: `C::${
            selectedCatalogue === "OWN" ? "OWN" : "EG"
          }::${selectedCatalogue2}`,
          type: INPUT_TYPE_CATALOGUE_RADIO,
          label: label,
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          scope: "/",
          selected: "",
          ...catalogueInfo
        };
        const isOwn = selectedCatalogue === "OWN" ? true : false;
        const resp = await catalogByPart({
          is_own: isOwn,
          catalogue: selectedCatalogue2,
          path: "/",
        });
        newInput.children = resp.data;
        newInput.isOwn = isOwn;
        newInput.catalogue = selectedCatalogue2;
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [selectedCatalogue2]: "",
          catalogue: selectedCatalogue2,
          isOwn,
          sensitive,
        });
        _.set(newIsValid, `${pathData}`, { [selectedCatalogue2]: [] });
      }
      if (value === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION) {
        newInput = {
          key: `C::${
            selectedCatalogue === "OWN" ? "OWN" : "EG"
          }::${selectedCatalogue2}`,
          type: INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION,
          label: label,
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          scope: "/",
          selected: "",
          ...catalogueInfo
        };
        const isOwn = selectedCatalogue === "OWN" ? true : false;
        const resp = await catalogByPart({
          is_own: isOwn,
          catalogue: selectedCatalogue2,
          path: "/",
        });
        newInput.children = resp.data;
        newInput.isOwn = isOwn;
        newInput.catalogue = selectedCatalogue2;
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [selectedCatalogue2]: "",
          catalogue: selectedCatalogue2,
          isOwn,
          sensitive,
        });
        _.set(newIsValid, `${pathData}`, { [selectedCatalogue2]: [] });
      }
      if (value === INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL) {
        newInput = {
          key: `C::${
            selectedCatalogue === "OWN" ? "OWN" : "EG"
          }::${selectedCatalogue2}`,
          type: INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
          label: label,
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          scope: "/",
          selected: "",
          conditionals: [],
          ...catalogueInfo
        };
        const isOwn = selectedCatalogue === "OWN" ? true : false;
        const resp = await catalogByPart(
          { is_own: isOwn, catalogue: selectedCatalogue2, path: "/" },
          "aqui"
        );
        newInput.children = resp.data;
        newInput.isOwn = isOwn;
        newInput.catalogue = selectedCatalogue2;
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [selectedCatalogue2]: "",
          catalogue: selectedCatalogue2,
          isOwn,
          sensitive,
          conditionals: [],
        });
        _.set(newIsValid, `${pathData}`, {
          [selectedCatalogue2]: [],
          conditionals: [],
        });
      }
      if (value === INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL) {
        newInput = {
          key: `C::${
            selectedCatalogue === "OWN" ? "OWN" : "EG"
          }::${selectedCatalogue2}`,
          type: INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
          label: label,
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          scope: "/",
          selected: "",
          conditionals: [],
          ...catalogueInfo
        };
        const isOwn = selectedCatalogue === "OWN" ? true : false;
        const resp = await catalogByPart({
          is_own: isOwn,
          catalogue: selectedCatalogue2,
          path: "/",
        });
        newInput.children = resp.data;
        newInput.isOwn = isOwn;
        newInput.catalogue = selectedCatalogue2;
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [selectedCatalogue2]: "",
          catalogue: selectedCatalogue2,
          isOwn,
          sensitive,
          conditionals: [],
        });
        _.set(newIsValid, `${pathData}`, {
          [selectedCatalogue2]: [],
          conditionals: [],
        });
      }
      if (value === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL) {
        newInput = {
          key: `C::${
            selectedCatalogue === "OWN" ? "OWN" : "EG"
          }::${selectedCatalogue2}`,
          type: INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
          label: label,
          placeholder: placeholder,
          required: required,
          sensitive: sensitive,
          grid: grid,
          break: false,
          hidden: false,
          role: null,
          scope: "/",
          selected: "",
          conditionals: [],
          ...catalogueInfo
        };
        const isOwn = selectedCatalogue === "OWN" ? true : false;
        const resp = await catalogByPart({
          is_own: isOwn,
          catalogue: selectedCatalogue2,
          path: "/",
        });
        newInput.children = resp.data;
        newInput.isOwn = isOwn;
        newInput.catalogue = selectedCatalogue2;
        _.set(newSchema, `${pathSchema}`, newInput);
        _.set(newFormData, `${pathData}`, {
          [selectedCatalogue2]: "",
          catalogue: selectedCatalogue2,
          isOwn,
          sensitive,
          conditionals: [],
        });
        _.set(newIsValid, `${pathData}`, {
          [selectedCatalogue2]: [],
          conditionals: [],
        });
      }
    }
    setSchemaState(newSchema);
    setFormData(newFormData);
    setIsValid(newIsValid);
  };

  const createForm = [
    { label: t("createForm.home"), route: "/" },
    { label: t("createForm.settings"), route: "/settings" },
    { label: t("createForm.create_form") },
  ];

  const editform = [
    { label: t("createForm.home"), route: "/" },
    { label: t("createForm.settings"), route: "/settings" },
    { label: t("createForm.forms_list"), route: "/formslist" },
    { label: t("createForm.edit_form") },
  ];

  return (
    <div
      className={`create-form page ${gTheme !== "light" ? "dark-mode" : ""}`}
    >
      <div className="mb-4">
        <GeneralIconHeader
          icon={`${create ? "outline_note_2" : "outline_task_square"}`}
          title={`${
            create ? t("createForm.create_form") : t("createForm.edit_form")
          }`}
          text={
            create
              ? t("createForm.this_section_can_create_new_form")
              : t("createForm.this_section_can_edit_form")
          }
        />
        <Breadcrum items={create ? createForm : editform} />
      </div>
      <ModalPreview
        gTheme={gTheme}
        formIdentifier={formIdentifier}
        steps={steps}
        schemaState={schemaState}
        setSchemaState={setSchemaState}
        formData={formData}
        setFormData={setFormData}
        isValid={isValid}
        setIsValid={setIsValid}
        constraints={constraints}
        setConstraints={setConstraints}
        isLoading={isLoading}
        show={showPreview}
        onHide={() => setShowPreview(false)}
      />

      {!isLoading && (
        <div className="create-form-main">
          <BuildSteps
            steps={steps}
            activeStep={activeStep}
            hdlActStp={hdlActStp}
            hdlStpNme={hdlStpNme}
            hdlAddStep={hdlAddStep}
            hdlDelStep={hdlDelStep}
            hdlMveStp={hdlMveStp}
            validCreateSteps={validCreateSteps}
            setValidCreateSteps={setValidCreateSteps}
            tryToCreate={tryToCreate}
            gTheme={gTheme}
          />

          {!rerender && (
            <BuildForm
              setShowPreview={setShowPreview}
              activeStep={activeStep}
              activeInput={activeInput}
              steps={steps}
              schemaState={schemaState}
              setSchemaState={setSchemaState}
              formData={formData}
              setFormData={setFormData}
              isValid={isValid}
              setIsValid={setIsValid}
              constraints={constraints}
              setConstraints={setConstraints}
              hdlChange={hdlChange}
              hdlActInp={hdlActInp}
              hdlAddInp={hdlAddInp}
              hdlDelInp={hdlDelInp}
              hdlMveInp={hdlMveInp}
              formIdentifier={formIdentifier}
              validFormIdentifier={validFormIdentifier}
              hdlFrmNme={hdlFrmNme}
              validCreateSteps={validCreateSteps}
              validCreateInputs={validCreateInputs}
              setValidCreateInputs={setValidCreateInputs}
              create={create}
              tryToCreate={tryToCreate}
              setTryToCreate={setTryToCreate}
              gTheme={gTheme}
              egCataloguesState={egCataloguesState}
              ownCataloguesState={ownCataloguesState}
            />
          )}
        </div>
      )}

      
    </div>
  );
};
