import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { listCatalogues, ownCatalogues } from "../../../apis";
import { Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MCButton } from "../../MainComponents/Button/Button";
import { MCSelect } from "../../MainComponents/Select/Select";
import {
  INPUT_TYPE_CATALOGUE_RADIO,
  INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_SELECT,
  INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
} from "../consts";
import { useTranslation } from "react-i18next";

export const ModalSelectCatalogue = ({
  show,
  onHide,
  hdlChange,
  entirePathSchema,
  entirePathData,
  nestLvl,
  IconOption,
  IconSingleValue,
}) => {
  const [t] = useTranslation("forms");

  const optionsSelectCatalogue = [
    {
      value: "EG",
      icon: "imagotipo_ethics",
      label: t("modalSelectCatalog.eg_catalog"),
    },
    {
      value: "OWN",
      icon: "outline_task_square",
      label: t("modalSelectCatalog.my_catalogs"),
    },
  ];

  const optionsTypeCatalogue = [
    {
      value: INPUT_TYPE_CATALOGUE_SELECT,
      icon: "arrow_square_down",
      label: t("modalSelectCatalog.dropdown"),
    },
    {
      value: INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
      icon: "arrow_square_down",
      label: t("modalSelectCatalog.dropdown_conditional"),
    },
    {
      value: INPUT_TYPE_CATALOGUE_RADIO,
      icon: "record_circle",
      label: t("modalSelectCatalog.multiple_option"),
    },
    {
      value: INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION,
      icon: "record_circle",
      label: t("modalSelectCatalog.multiple_option_description"),
    },
    {
      value: INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
      icon: "record_circle",
      label: t("modalSelectCatalog.multiple_option_conditionals"),
    },
    {
      value: INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
      icon: "record_circle",
      label: t("modalSelectCatalog.multiple_option_description_conditional"),
    },
  ];

  const filterOptionsSelectType = (nestLvl = 0) => {
    const omitOnNest = [
      INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
      INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
      INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
    ];
    if (nestLvl === 0) {
      return optionsTypeCatalogue;
    } else {
      return optionsTypeCatalogue.filter(
        (item) => !omitOnNest.includes(item.value)
      );
    }
  };

  const [asRadios, setAsRadios] = useState(INPUT_TYPE_CATALOGUE_SELECT);
  const [selectedCatalogue, setSelectedCatalogue] = useState("EG");
  const [selectedCatalogue2, setSelectedCatalogue2] = useState("");
  const [selectedCatalogue2Title, setSelectedCatalogue2Title] = useState("");
  const navigate = useNavigate();
  const { gTheme } = useSelector((state) => state.theme);

  const [egCataloguesState, setEgCataloguesState] = useState([]);
  const [ownCataloguesState, setOwnCataloguesState] = useState([]);

  useEffect(() => {
    Promise.all([listCatalogues(), ownCatalogues()])
      .then(([eg, own]) => {
        setEgCataloguesState(eg.catalogues);
        setOwnCataloguesState(own.catalogues);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    setSelectedCatalogue2("");
  }, [selectedCatalogue]);

  useEffect(() => {
    if (egCataloguesState && ownCataloguesState && selectedCatalogue2) {
      const catalogInfo = (
        selectedCatalogue === "EG" ? egCataloguesState : ownCataloguesState
      ).find(
        (item) =>
          selectedCatalogue2 === item.code || selectedCatalogue2 === item.alias
      );
      setSelectedCatalogue2Title(catalogInfo.label);
    }
  }, [selectedCatalogue2]);

  const handleChange = () => {
    let catalogueInfo = null;
    if (selectedCatalogue === "EG") {
      catalogueInfo = egCataloguesState.find(item=>item.code===selectedCatalogue2);
    } else if (selectedCatalogue === "OWN") {
      catalogueInfo = ownCataloguesState.find(item=>item.alias===selectedCatalogue2);
    }
    hdlChange({
      pathSchema: entirePathSchema,
      pathData: entirePathData,
      selectedCatalogue,
      selectedCatalogue2,
      toChange: "type",
      value: asRadios,
      label: selectedCatalogue2Title,
      required: false,
      sensitive: false,
      placeholder: t("modalSelectCatalog.support_text"),
      grid: 12,
      ...(asRadios.includes("conditional") && { conditionals: [] }),
      catalogueInfo: {
        ...(Array.isArray(catalogueInfo.hiddenLvls) && { hiddenLvls: catalogueInfo.hiddenLvls })
      }
    });
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      className={`modal-select-type ${gTheme !== "light" ? "dark-mode" : ""}`}
      centered
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      />
      <div className="mt-3">
        <h5>{t("modalSelectCatalog.select_form_catalog")}</h5>
        <label>{t("modalSelectCatalog.witch_catalog_linked")}</label>
        <div className="modal-type-header d-flex justify-content-between mb-3">
          <div style={{ minWidth: 280, maxWidth: 280 }}>
            <MCSelect
              className="w-100 mt-2"
              options={optionsSelectCatalogue}
              value={optionsSelectCatalogue.find(
                (item) => item.value === selectedCatalogue
              )}
              onChange={(e) => setSelectedCatalogue(e.value)}
              components={{ Option: IconOption, SingleValue: IconSingleValue }}
            />
          </div>
          <MCButton
            label={t("modalSelectCatalog.create_catalog")}
            variant="primary"
            onClick={() => navigate("/catalogues/new")}
          />
        </div>
        {selectedCatalogue &&
          Array.isArray(egCataloguesState) &&
          Array.isArray(ownCataloguesState) && (
            <Table responsive className="custom-table">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("modalSelectCatalog.catalog")}</th>
                  {/* <th>{t("createForm.modals.selectCatalogue.cols.levels")}</th>
                <th>{t("createForm.modals.selectCatalogue.cols.creation")}</th> */}
                  <th>{t("modalSelectCatalog.action")}</th>
                  <th>{t("modalSelectCatalog.preview")}</th>
                </tr>
              </thead>
              <tbody>
                {(selectedCatalogue === "EG"
                  ? egCataloguesState
                  : ownCataloguesState
                ).map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        className="form-check-input"
                        style={{ marginLeft: 1, marginTop: 6 }}
                        type="checkbox"
                        aria-label="..."
                        onChange={() =>
                          setSelectedCatalogue2(item.code || item.alias)
                        }
                        checked={
                          selectedCatalogue2 === item.code ||
                          selectedCatalogue2 === item.alias
                        }
                        id={item.code || item.alias}
                      />
                    </td>
                    <td>
                      <div>
                        <label htmlFor={item.code || item.alias}>
                          {item.label}
                        </label>
                      </div>
                    </td>
                    <td>
                      <MCButton
                        label={t("modalSelectCatalog.edit")}
                        variant="primary"
                        outline
                      />
                    </td>
                    <td>
                      <MCButton
                        label={t("modalSelectCatalog.view")}
                        variant="primary"
                        outline
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        <label>{t("modalSelectCatalog.how_field_create")}</label>
        <div className="d-flex" style={{ minWidth: 380, maxWidth: 380 }}>
          <MCSelect
            className="w-100 mt-2"
            options={filterOptionsSelectType(nestLvl)}
            components={{ Option: IconOption, SingleValue: IconSingleValue }}
            onChange={(e) => setAsRadios(e.value)}
            value={filterOptionsSelectType().find(
              (item) => item.value === asRadios
            )}
          />
        </div>
        <hr />
        <div className="d-flex justify-content-end mt-3">
          <MCButton
            label={t("modalSelectCatalog.cancel")}
            className="me-2"
            variant="primary"
            outline
            onClick={onHide}
          />
          {selectedCatalogue2 && (
            <MCButton
              label={t("modalSelectCatalog.create_field")}
              variant="primary"
              onClick={handleChange}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
