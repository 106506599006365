// Lista de conjuntos de datos
export const dataList = [
  
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Análisis Denuncia por Estado Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 23,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Tipo de Infractor",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 28,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Denuncias por Estado Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 33,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Gravedad por Estado Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 38,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Tipo de Denuncia General Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 43,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Tipo de Denuncia Especifica Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 44,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Denuncias por Zona Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 45,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    {
      graphics: [],
      indicators: [],
      default_graphics: [
        {
          title: "Análisis Denuncia por Tienda CDMX Grupo Innovasport",
          filter_params: [{
            function: "filter_by_status#type#contar_TODOS",
            label: "Todos los reportes"
          }],
          show_graphic: "show_graphic#type#special",
          show_graphic_graphic: 46,
          get_data: "get_data#type#count",
          indicators: [],
          metadata: {},
          is_active: true,
          addTwoColumns: true
        }
      ],
    },
    // Agrega más conjuntos de datos según sea necesario
  ];


