import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const LineOpenCloseChart = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate

    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {
                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

                const root = am5.Root.new(chartDivId.current);
                // root.setThemes([am5themes_Animated.new(root)]);
                root.setThemes([MyTheme.new(root)]);

                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panX: true,
                        panY: true,
                        layout: root.verticalLayout,
                        wheelX: "panX",
                        wheelY: "zoomX",
                        paddingLeft: 0
                    })
                );

                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "none"
                }));
                cursor.lineY.set("visible", false);

                var date = new Date();
                date.setHours(0, 0, 0, 0);

                function createData() {

                    const newData = chartData.data.map((item) => {
                        let previousValue = 0; // Valor inicial para comparación
                        const upColor = root.interfaceColors.get("positive");
                        const downColor = root.interfaceColors.get("negative");

                        // Objetos para contar reportes abiertos y cerrados por fecha
                        const openedReportsCount = {};
                        const closedReportsCount = {};

                        

                        // Llenar openedReportsCount con los reportes abiertos y closedReportsCount con los reportes cerrados
                        item.data[3].forEach((status, index) => {
                            const creationDate = new Date(item.data[4][index]).toDateString();
                    
                            if (status.current == "closed") {
                                closedReportsCount[creationDate] = (closedReportsCount[creationDate] || 0) + 1;
                            } else {
                                openedReportsCount[creationDate] = (openedReportsCount[creationDate] || 0) + 1;
                            }
                        });


                        // Valor acumulado que se llevará al siguiente día
                        let accumulatedValue = 0;
                        const processedDates = new Set();

                        return {


                            data: item.data[0].map((_, index) => {
                                const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                                const dateString = new Date(date).toDateString();
                        
                                // Verificar si la fecha actual ya ha sido procesada
                                if (processedDates.has(dateString)) {
                                    // Si la fecha ya ha sido procesada, simplemente retornar null
                                    return null;
                                }
                                processedDates.add(dateString); // Agregar la fecha actual al conjunto de fechas procesadas
                        
                                let color;
                        
                                // Comparar la fecha con la anterior para determinar el color
                                if (date >= previousValue) {
                                    color = upColor;
                                    previousValue = date;
                                } else {
                                    color = downColor;
                                    previousValue = date;
                                }
                        
                                // Contar reportes abiertos y cerrados en la fecha actual
                                const openCount = openedReportsCount[dateString] || 0;
                                const closeCount = closedReportsCount[dateString] || 0;
                        
                                // Calcular el valor ajustado
                                const adjustedValue = accumulatedValue + openCount - closeCount;
                                accumulatedValue = adjustedValue; // Actualizar el valor acumulado
                        
                        
                                // Verificar si la fecha está dentro del rango especificado
                                if (startDate === 0 && endDate === 0) {
                                    return {
                                        value: adjustedValue,
                                        date: date,
                                        color: color, // Añadido el color al objeto de datos
                                        strokeSettings: { stroke: color }
                                    };
                                } else {
                                    if (date >= startDate && date <= endDatePlusOneDay) {
                                        return {
                                            value: adjustedValue,
                                            date: date,
                                            color: color, // Añadido el color al objeto de datos
                                            strokeSettings: { stroke: color }
                                        };
                                    } else {
                                        return null; // Excluir el registro si la fecha está fuera del rango
                                    }
                                }
                            })
                                .filter(data => data !== null) // Filtrar elementos que no pasaron la condición
                        };
                    });
                    return newData;


                }

                var xAxis = newChart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: { timeUnit: "day", count: 1 },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minorGridEnabled: true,
                            minGridDistance: 70
                        }),
                        tooltip: am5.Tooltip.new(root, {})
                    })
                );

                var yAxis = newChart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {})
                    }));

                var series = newChart.series.push(am5xy.LineSeries.new(root, {
                    name: "Series",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "date",
                    // stroke: (dataItem) => dataItem.dataContext.color 

                }));
                series.strokes.template.set("templateField", "strokeSettings");

                var tooltip = series.set("tooltip", am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                }));

                tooltip.on("pointTo", function () {
                    var background = tooltip.get("background");
                    background.set("fill", background.get("fill"));
                });

                // tooltip bacground takes color from data item
                tooltip.get("background").adapters.add("fill", function (fill) {
                    if (tooltip.dataItem) {
                        return tooltip.dataItem.dataContext.color;
                    }
                    return fill;
                });
                var scrollbar = newChart.set(
                    "scrollbarX",
                    am5xy.XYChartScrollbar.new(root, {
                        orientation: "horizontal",
                        height: 60
                    })
                );

                var sbDateAxis = scrollbar.chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: {
                            timeUnit: "day",
                            count: 1
                        },
                        renderer: am5xy.AxisRendererX.new(root, {})
                    })
                );

                var sbValueAxis = scrollbar.chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );

                var sbSeries = scrollbar.chart.series.push(
                    am5xy.LineSeries.new(root, {
                        valueYField: "value",
                        valueXField: "date",
                        xAxis: sbDateAxis,
                        yAxis: sbValueAxis
                    })
                );



                var newData = createData();
                series.data.setAll(
                    newData.flatMap(item => {
                        const groupedData = item.data.reduce((acc, curr) => {
                            if (!acc[curr.date] || curr.value > acc[curr.date].value) {
                                acc[curr.date] = curr;
                            }
                            return acc;
                        }, {});

                        return Object.values(groupedData);
                    })
                );
                sbSeries.data.setAll(
                    newData.flatMap(item => {
                        const groupedData = item.data.reduce((acc, curr) => {
                            if (!acc[curr.date] || curr.value > acc[curr.date].value) {
                                acc[curr.date] = curr;
                            }
                            return acc;
                        }, {});

                        return Object.values(groupedData);
                    })
                );


                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });

                series.appear(1000);
                newChart.appear(1000, 100);


                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== 'undefined') {
            generateChart();
        }

        return () => {

            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });

            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default LineOpenCloseChart;