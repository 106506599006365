import { login, loginMFA } from "../../apis";
import { getSession, getUsername } from "../../handlers/getSession";
import { getTenant } from "../../handlers/getTenant";
import { enqueueSnackbar } from "notistack";
import {
  setChecking,
  setErrorMessage,
  setLoggedIn,
  setLoggedOut,
  setTenant,
  setUserInfo,
} from "./authSlice";
import statuscode_en from "../../translations/EN/statuscode.json";
import statuscode_es from "../../translations/ES/statuscode.json";
import { getUserList } from "../../apis/apiUsers";

//-----Send data to 'Login'(Cognito)-----//
const lang = localStorage.getItem("lang") || "es";
const language = lang === "es" ? statuscode_es : statuscode_en;

const showErrorMessage = (error) => {
  enqueueSnackbar(error.message || error, { variant: "error" });
};

const showOTPErrorMessage = (error) => {
  enqueueSnackbar(error, { variant: "error" });
};

export const startGettingUserInfo = () => async(dispatch, getState) => {
  let currentUser = null;
  const { email = null } = getSession();
  if (!email) {
    dispatch(setUserInfo(currentUser));
  } else {
    try {
      const { users = [] } = await getUserList();
      currentUser = users.find(user=>user.email === email) ?? null;
      dispatch(setUserInfo(currentUser));
    } catch (error) {
      console.log(error);
    }
  }
}

export const startSettingDomain = (tenant) => (dispatch, getState) => {
  localStorage.setItem("Tenant", JSON.stringify(tenant));
  dispatch(setTenant(tenant));
}

export const startFinalLogin = (email, res, mfa = null, multiTenant = false) => async(dispatch, getState) => {
  dispatch(setErrorMessage(false));
  const { domain_list } = res.data;
  const { username } = res.data;
  const { AccessToken, IdToken, RefreshToken } = res.data.data;
  const tenant = domain_list[0];
  localStorage.setItem("LoginTime", new Date().getTime().toString());
  localStorage.setItem("Username", username);
  localStorage.setItem("Session",JSON.stringify({ AccessToken, IdToken, RefreshToken, email, ...( mfa && { mfa }) }));
  if (!multiTenant) {
    localStorage.setItem("Tenant", JSON.stringify(tenant));
  }
  dispatch(
    setLoggedIn({
      AccessToken,
      IdToken,
      RefreshToken,
      email,
      username,
      ...( !multiTenant ? { tenant } : { tenant: null }),
      ...( mfa && { mfa })
    })
  );
}

export const startLoggedIn = (form, setMfa, setSectionShowed, setDomainList, setSelectedDomain) => async (dispatch, getState) => {
  const email = form.email;
  const password = form.password;
  // dispatch(setChecking());
  dispatch(setErrorMessage(false));
  await login(email, password)
  .then((res) => {  // Login Failed
    const is_mfa_enabled = res.data.data["ChallengeName"]

    if( is_mfa_enabled == "SOFTWARE_TOKEN_MFA") {
      setMfa(res.data.data); // Aquí actualizas el estado de mfa
      setSectionShowed("mfa");
    } else {
      const { domain_list } = res.data;
      if (Array.isArray(domain_list) && domain_list.length > 1) {  // Tiene varios dominios
        setSectionShowed("domain");
        setDomainList(domain_list);
        setSelectedDomain(domain_list[0]);
        dispatch(startFinalLogin(email, res, null, true));
      } else {  // Solo tiene un dominio y se selecciona automaticamente
        dispatch(setErrorMessage(false));
        dispatch(startFinalLogin(email, res))
      }
    }
  })
  
  // Login Failed
  .catch((err) => {
    console.log(err);
    dispatch(setErrorMessage(true));
    dispatch(setLoggedOut());
    setTimeout(() => {
      dispatch(setErrorMessage(false));
    }, 10);
  });
};

export const startLoggedInWithMFA = (mfa, code, email, setSectionShowed, setDomainList, setSelectedDomain) => async (dispatch, getState) => {

  const otp_code = code;
  const uuid = mfa["ChallengeParameters"]["USER_ID_FOR_SRP"];
  const session = mfa["Session"]

  // dispatch(setChecking());
  dispatch(setErrorMessage(false));
  await loginMFA( uuid, otp_code, session, email )
  // Login Success
  .then((res) => {
    const { domain_list } = res.data;
    if (Array.isArray(domain_list) && domain_list.length > 1) {  // Tiene varios dominios
      setSectionShowed("domain");
      setDomainList(domain_list);
      setSelectedDomain(domain_list[0]);
      dispatch(startFinalLogin(email, res, mfa, true));
    } else {  // Solo tiene un dominio y se selecciona automaticamente
      dispatch(setErrorMessage(false));
      dispatch(startFinalLogin(email, res, mfa));
    }
  })
  .catch((err) => {  // Login Failed
    dispatch(showOTPErrorMessage("Error"));
    dispatch(setLoggedOut());
    setTimeout(() => {
      dispatch(setErrorMessage(false));
    }, 10);
  });
};

export const startLoggedOut = () => async (dispatch, getState) => {
  try {
    dispatch(setChecking());
    // await logout();
    localStorage.removeItem("LoginTime");
    localStorage.removeItem("Username");
    localStorage.removeItem("Tenant");
    localStorage.removeItem("Session");
    dispatch(setLoggedOut());
  } catch (e) {
    showErrorMessage(`${language.failed_logout}`);
  }
};

export const startLoggedInWithTokenLS = () => async (dispatch, getState) => {
  try {
    const session = getSession();
    const tenant = getTenant();
    const username = getUsername();
    const logintime = localStorage.getItem("LoginTime");
    if (!session || !tenant) {
      dispatch(setLoggedOut());
      return;
    }
    if (!logintime) {
      localStorage.setItem("LoginTime", new Date().getTime().toString());
    }
    dispatch(
      setLoggedIn({
        AccessToken: session.AccessToken,
        IdToken: session.IdToken,
        RefreshToken: session.RefreshToken,
        email: session.email,
        tenant,
        username,
        mfa: session.mfa
      })
    );
  } catch (e) {
    showErrorMessage(`${language.failed_fetch_session_data_storage}`);
  }
};
