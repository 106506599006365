import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos




export const RadarChart = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`vertical-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title

  
  
  useEffect(() => {
    let newChart = null;
    
    const generateChart = async () => {
      
      try {
        
        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);
        const root = am5.Root.new(chartDivId.current);

        // root.setThemes([am5themes_Animated.new(root)]);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX"
          })
        );



        const newData = chartData.data
    .map((item) => ({
        category: item.label,
        value: item.data[0].filter((value, index) => {
            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
            if (startDate === 0 && endDate === 0) {
                return true;
            } else {
                return date >= startDate && date <= endDatePlusOneDay;
            }
        }).length,
    }))
    .filter(item => item.value > 0);

        newData.sort((a, b) => b.value - a.value);


        const yAxis = newChart.yAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {})
        }));

        var xRenderer = am5radar.AxisRendererCircular.new(root, {});
        xRenderer.labels.template.setAll({
          radius: 10
        });

        var xAxis = newChart.xAxes.push(am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "category",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        }));
        

        xAxis.data.setAll(newData);
        

        const series = newChart.series.push(am5radar.RadarColumnSeries.new(root, {
          name: "Series",
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "category",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}"
          })
        }));

        series.columns.template.setAll({
          tooltipText: "{name}: {valueY}"
        });


        newChart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));
        newChart.set("scrollbarY", am5.Scrollbar.new(root, { orientation: "vertical" }));

        series.data.setAll(newData);

        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });



        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(5),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });






        series.appear(1000);
        newChart.appear(1000, 100);


        setChart(newChart);


      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function(root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });

      if (chart) {
        chart.dispose();
      }

    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};
export default RadarChart;