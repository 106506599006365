import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
const showWarningMessage = (msg) => {
  enqueueSnackbar(`${msg}`, {
    variant: "warning",
    preventDuplicate: true,
  });
};

// Create Dashboard
export const createDashboard = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/create/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_creating_graphic} (${error.response.status})`
    );
    console.error(error);
  }
};

// Utilities Dashboard
export const utilitiesDashboard = async () => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(`${tenant}/api/dashboard/utilities/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${error} (${error.response.status})`);
  }
};

// Graphics Dashboard
export const getGraphicsDashboard = async () => {
  const session = getSession();
  const tenant = getTenant();

  
  try {
    const resp = await apiCall.get(`${tenant}/api/dashboard/detail/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error);
  }
};

// Delete Dashboard using path
/**
 * data = {
 *  path: "path"
 * }
 */
export const deleteElement = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.delete(`${tenant}/api/dashboard/delete/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
      data: data,
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_delete_element} (${error.response.status})`
    );
    console.error(error);
  }
};

// Get Data By Path
export const getDataGraphicByPath = async (data) => {
  
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/element/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return {
      status: resp.status,
      data: resp.data.data,
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.statusText,
    };
  }
};

// Get last Report from API
export const getLastReport = async () => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(`${tenant}/api/report/list/?limit=5`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error);
  }
};









export const getDefaultGraphics = async () => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.get(`${tenant}/api/dashboard/add/default-graphics/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showWarningMessage(
      `${language.error_retrieving_data} (${error.response.status})`
    );
    console.error(error);
  }
};











// Modify the Dashboard default
export const modifyGraficsDefault = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();

  
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/modify/defaut/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_creating_graphic} (${error.response.status})`
    );
    console.error(error);
  }
};



// Modify the Dashboard default
export const addGraficsDefaultDashboard = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();  

  
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/add/defaut/dashboard/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_creating_graphic} (${error.response.status})`
    );
    console.error(error);
  }
};




// Modify the Dashboard default
export const createCopyOfDefault = async (data = {}) => {
  const session = getSession();
  const tenant = getTenant();  

  
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/create/copy/default/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_creating_graphic} (${error.response.status})`
    );
    console.error(error);
  }
};

// Get catalogue general
export const getCatalogue = async () => {
  const session = getSession();
  const tenant = getTenant();
  const data={
    "is_own": false,
    "catalogue": "",
    "path": "/"
    }
  try {
    const resp = await apiCall.get(`${tenant}/api/list/catalogues/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return {
      status: resp.status,
      data: resp.data,
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.statusText,
    };
  }
};

// Get catalogo a detalle
export const getCataloguedetail = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  
  try {
    const resp = await apiCall.post(`${tenant}/api/detail/catalogue/`, data, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return {
      status: resp.status,
      data: resp.data,
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.statusText,
    };
  }
};

export const oneColumnChange = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/change/column/`, data,{
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return {
      status: resp.status,
      data: resp.data.data,
    };  } catch (error) {
    showErrorMessage(
      `${language.error_delete_element} (${error.response.status})`
    );
    console.error(error);
  }
};


export const modifyOrder = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  try {
    const resp = await apiCall.post(`${tenant}/api/dashboard/modify-order/`, data,{
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(
      `${language.error_delete_element} (${error.response.status})`
    );
    console.error(error);
  }
};