import { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { MCLabel } from "../MainComponents/Label/Label";
import { MCCheckbox, MCInput } from "../MainComponents/Input/Input";
import { MCSelect } from "../MainComponents/Select/Select";
import { MCButton } from "../MainComponents/Button/Button";
import { catalogByPart} from "../../apis";

export const NewFilterComponentStacked = ({
    label,
    selectedIndexStacked,
    stackFilters,
    getOptionsFromUtilities,
    handleShowNewFilter,
    handleNewFilter,
  }) => {


    const SelectChild = ({ options, selected = null, ...rest }) => {
        return (
          <>
            <MCSelect
              className="m-1"
              options={options}
              {...rest}
              value={options.find((item) => item.selected === true)}
            />
            {selected !== null && (
              <SelectChild
                options={options[selected].childs[0].options}
                selected={options[selected].childs[0].selected}
                {...rest}
              />
            )}
          </>
        );
      };



    const [t] = useTranslation("components");
    // Para el titulo del filtro
    const [filterTitle, setFilterTitle] = useState("");
    // Los tipos de filtros que se pueden usar
    const [filterType, setFilterType] = useState([
      { label: t("ModalGraph.filter_by_status"), value: 1, selected: false },
      { label: t("ModalGraph.filter_by_field_form"), value: 2, selected: false },
      { label: t("ModalGraph.filter_by_indicator"), value: 3, selected: false },
      { label: t("ModalGraph.filter_by_entryway"), value: 4, selected: false },
    ]);
    // State para el typo filtro seleccionado
    const [selectedFilterType, setSelectedFilterType] = useState(null);
    // State para las opciones de los filtros
    const [filterOptions, setFilterOptions] = useState([]);
    // State para el filtro seleccionado
    const [filterSelected, setFilterSelected] = useState(null);
  
    // State para los catalogos origen
    const [catalogue, setCatalogue] = useState("");
    const [is_own, setIsOwn] = useState(false);
  
    // State para los catalogos anidados
    const [selects, setSelects] = useState([]);
  
    // State para el valor del filtro aniadido seleccionado
    const [selectedFilterValue, setSelectedFilterValue] = useState(null);
  
    // State para activar o desactivar el boton de agregar filtro
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
    // State para agregar un filtro para cada hijo en la ultima seleccion
    const [addEveryChild, setAddEveryChild] = useState(false);
  
    useEffect(() => {
      // Asignar opciones de filtro
      setFilterOptions([]);
    }, []);
  
    /**
     * Funcion que permite guardar el titulo
     * del filtro
     */
    const handleFilterTitle = (e) => {
      setFilterTitle(e.target.value);
      if (e.target.value === "") setIsButtonDisabled(true);
      else {
        if ((selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && filterSelected !== null)
          setIsButtonDisabled(false);
        else if (
          selectedFilterType === 2 &&
          filterSelected !== null &&
          selectedFilterValue !== null
        )
          setIsButtonDisabled(false);
      }
    };
  
    /**
     * Funcion que permite mostar el filtro
     * seleccionado
     */
    const changeFilterType = (e) => {
      // Asignar un valor vacio a los selects
      setSelects([]);
      // Desactivar el boton de agregar filtro
      setIsButtonDisabled(true);
  
      var filterTypeTmp = _.cloneDeep(filterType);
      setFilterType(
        filterTypeTmp.map((item) => {
          if (item.value === e.value) item.selected = true;
          else item.selected = false;
          return item;
        })
      );
  
      // Asignar un valor null al filtro seleccionado
      setFilterSelected(null);
  
  
      // Asignar opciones de filtro
      if (e.value === 1)
        setFilterOptions(getOptionsFromUtilities("filter_by_status"));
  
  
      if (e.value === 2)
        setFilterOptions(getOptionsFromUtilities("filter_fields_report"));
  
      if (e.value === 3)
        setFilterOptions(getOptionsFromUtilities("filter_by_indicator"));
      if (e.value === 4)
        setFilterOptions(getOptionsFromUtilities("filter_by_entryway"));
  
  
      // Asignar filtro seleccionado al estado
      setSelectedFilterType(e.value);
    };
  
  
    /**
     * Funcion que permite cambiar el filtro seleccionado
     */
    const changeFilterSelected = (e) => {
      resetCatalogueChilds(e);
  
      var filterOptionsTmp = _.cloneDeep(filterOptions);
      setFilterOptions(
        filterOptionsTmp.map((item) => {
          if (item.value === e.value) item.selected = true;
          else item.selected = false;
          return item;
        })
      );
  
      // Asignar filtro seleccionado al estado
      setFilterSelected(e.value);
      // Aqui es donde hay que ver que tipo de filtro es para
      // activar o mantener desactivado el boton de agregar filtro
      if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) {
        if (filterTitle !== "") setIsButtonDisabled(false);
      }
    };
  
    /**
     * Funcion que permite agregar un nuevo
     * select al filtro
     */
    const handleNestedSelect = async (select_father) => {
      // Valor del filtro seleccionado
      setSelectedFilterValue(select_father.value);
  
      const origin = select_father.path;
      const itemIndex = origin.split(".").pop();
      const selectedPathArray = origin.split(".");
      var newElements = null;
  
      selectedPathArray.pop();
      selectedPathArray.pop();
  
      var selectedPath = "";
  
      for (let i = 0; i < selectedPathArray.length; i++) {
        if (i === 0) selectedPath = selectedPathArray[i];
        else selectedPath += `.${selectedPathArray[i]}`;
      }
  
      const newSelects = removeNestedSelects(_.cloneDeep(selects), selectedPath);
  
      if (select_father.continue !== "") {
        await catalogByPart({
          catalogue: catalogue,
          is_own: is_own,
          path: select_father.value,
        }).then((response) => {
          newElements = response.data;
        });
      }
  
      _.set(newSelects, `${origin}.selected`, true);
  
      if (newElements !== null) {
        _.set(newSelects, `${selectedPath}.selected`, itemIndex);
        _.set(newSelects, `${origin}.childs`, [
          createOptionsSelects(newElements, origin),
        ]);
      }
  
      setSelects(newSelects);
      // Aqui es donde se debe activar el boton de agregar filtro
      if (filterTitle !== "") setIsButtonDisabled(false);
    };
  
    /**
     * Funcion que permite remover los selects
     * anidados y regresar el valor de selected
     * a false de cada uno de los elementos
     */
    const removeNestedSelects = (data, path) => {
      const selectsTmp = _.get(data, `${path}.options`);
  
      _.set(
        data,
        `${path}.options`,
        selectsTmp.map((opt) => {
          opt.childs = [];
          opt.selected = false;
          return opt;
        })
      );
  
      _.set(data, `${path}.selected`, null);
  
      return data;
    };
  
    /**
     * Funcion que permite resetear los datos del
     * catalogo seleccionado de la lista de filtros
     * campos del reporte
     */
    const resetCatalogueChilds = (data) => {
      setCatalogue("");
      setIsOwn(false);
      setSelectedFilterValue(null);
      if (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) return;
  
      const originCatalogue = data.value.split(":::").pop().split("::");
      const catalogueLocal = originCatalogue.pop();
      const isOwnLocal = originCatalogue.pop() === "OWN" ? true : false;
      const pathLocal = "/";
      setCatalogue(catalogueLocal);
      setIsOwn(isOwnLocal);
  
      catalogByPart({
        catalogue: catalogueLocal,
        is_own: isOwnLocal,
        path: pathLocal,
      }).then((response) => {
        setSelects([createOptionsSelects(response.data)]);
      });
    };
  
    /**
     * Funcion que permite crear las opciones
     * de los selects anidados
     */
    const createOptionsSelects = (data, origin = null, selected = null) => {
      return {
        selected: selected,
        options: data.map((item, idx) => {
          return {
            label: item.label,
            value: item.path_locales,
            continue: item.path,
            path: origin
              ? `${origin}.childs.0.options.${idx}`
              : `0.options.${idx}`,
            childs: [],
            selected: false,
          };
        }),
      };
    };
  
    /**
     * Funcion que recolecta los datos del filtro
     * y los agrega a la lista de filtros
     */
    const handleAddFilter = () => {
      
      handleNewFilter({
        label: filterTitle,
        function:
          (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4)
            ? filterSelected
            : `${filterSelected}?${selectedFilterValue}`,
        selectedIndexStacked: selectedIndexStacked,
      });
      // Aqui es donde se regresa a la pantanlla del
      // cuerpo del filtro
      handleShowNewFilter(false);
    };
    /**
     * Funcion para buscar en el schema el ultimo
     * scope de hijos que hay en las selecciones
     */
    const recursiveReturnFilters = (childs, lastOptions) => {
      if (Array.isArray(childs) && childs.length > 0) {
        const options = childs[0].options || [];
        const idxOpt = options.findIndex((opt) => opt.selected === true);
        if (idxOpt >= 0) {
          return recursiveReturnFilters(options[idxOpt].childs, options);
        } else {
          return options;
        }
      } else {
        return lastOptions;
      }
    };
    /**
     * Funcion para agregar un filtro
     * por cada hijo que halla en el primer scope
     */
    const handleAddFilterForEveryChild = () => {
      const currentFilters = recursiveReturnFilters(selects);
      let allFilters;
      if (addEveryChild && Array.isArray(currentFilters)) {
        allFilters = currentFilters.map((item) => {
          return {
            // label: `${filterTitle} - ${item.label}`,
            label: `${item.label}`,
            function:
              (selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4)
                ? filterSelected
                : `${filterSelected}?${item.value}`,
          };
        });
      }
      if (allFilters) {
        handleNewFilter(allFilters, true);
        handleShowNewFilter(false);
      }
    };
    /**
     * Retorna un valor para habilitar o inhabilitar el
     * boton para agregar un filtro por cada hijo
     */
    const isButtonAddFilterForEveryChildDisabled = () => {
      if (filterTitle.trim() === "") {
        return true;
      }
      if (filterSelected === null) {
        return true;
      }
      if (!Array.isArray(selects[0]?.options)) {
        return true;
      }
      return false;
    };
  
    return (
      <>
        <div className="filter-modal">
          {/* <MCLabel text={`${t("ModalGraph.data_series_name")}:`} /> */}
          <MCLabel text={label} />
          <MCInput
            type="text"
            name="title"
            className="m-1"
            // placeholder={t("ModalGraph.enter_category_name")}
            placeholder={"Ingresa el nuevo filtro para agrupar"}
            autoComplete="off"
            value={filterTitle}
            onChange={handleFilterTitle}
          />
        </div>
  
        <div>
          <MCLabel text={`${t("ModalGraph.select_filter")}:`} />
          <MCSelect
            className="m-1"
            options={filterType}
            onChange={(e) => changeFilterType(e)}
            value={filterType.find((item) => item.selected === true)}
          />
        </div>
  
        {(selectedFilterType === 1 || selectedFilterType === 3 || selectedFilterType === 4) && (
          <div>
            <MCLabel text={`${t("ModalGraph.filter_by_status")}:`} />
            <MCSelect
              className="m-1"
              options={filterOptions}
              onChange={(e) => changeFilterSelected(e)}
              value={filterOptions.find((item) => item.selected === true)}
            />
          </div>
        )}
  
        {selectedFilterType === 2 && (
          <div>
            <div className="d-flex justify-content-between">
              <MCLabel text={`${t("ModalGraph.filter_by_field_form")}:`} />
            </div>
            <MCSelect
              className="m-1"
              options={filterOptions}
              onChange={(e) => changeFilterSelected(e)}
              value={filterOptions.find((item) => item.selected === true)}
            />
          </div>
        )}
  
        {selects.length > 0 && <hr className="my-4" />}
  
        {selects.map((item) => {
          return (
            <SelectChild
              options={item.options}
              selected={item.selected}
              onChange={(e) => handleNestedSelect(e)}
            />
          );
        })}
        <br />
        <div className="add-indicator-stacked">
          {!addEveryChild ? (
            <>
              <MCButton
                id="cancel"
                label={t("ModalGraph.cancel")}
                variant="danger"
                outline
                onClick={() => handleShowNewFilter(false)} // Corrección aquí
                />
            <MCButton
              label={t("ModalGraph.create")}
              // label={"GUARDADITO"}
              variant={isButtonDisabled ? "secondary" : "success"}
              onClick={handleAddFilter}
              disabled={isButtonDisabled}
              />
              </>
          ) : (
            <MCButton
              label={t("ModalGraph.add")}
              variant={
                isButtonAddFilterForEveryChildDisabled() ? "secondary" : "success"
              }
              onClick={handleAddFilterForEveryChild}
              disabled={isButtonAddFilterForEveryChildDisabled()}
            />
          )}
        </div>
      </>
    );
  };