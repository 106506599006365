import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme, barrasestadostackchart } from "./../../colors";


export const InnovaBarrasEstadoStack = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`BarrasEstadoStack-${Math.random().toString(36).substr(2, 9)}`);

    const diaInicio = startDate
    const diaFin = endDate


    let catalogo = "";


    if (chartData.show_graphic_graphic) {
        // let newCatalogo = "";
        let newCatalogo = "donde_sucedieron_los_hechos";
        const tipo = chartData.show_graphic_graphic;

        // switch (tipo) {
        //     case '38':
        //         newCatalogo = "estado_innovasport";
        //         break;
        //     case '34':
        //         newCatalogo = "estado_invictus";
        //         break;
        //     case '35':
        //         newCatalogo = "estado_overtime";
        //         break;
        //     case '36':
        //         newCatalogo = "estado_culto";
        //         break;
        //     case '37':
        //         newCatalogo = "ameshop_estado";
        //         break;
        //     default:
        //         newCatalogo = catalogo;
        // }

        if (newCatalogo && newCatalogo !== catalogo) {
            catalogo = 'C::OWN::' + newCatalogo;
        }
    }

    const stateReportMap = {};

    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    chartData.data[0].data[2].forEach((subArray) => {
        let estado = "unknown";
        let pathValue = ""; 
        // console.log("CHARTDATA", chartData.data[0].data);
        
        const pathIndex = chartData.data[0].data[5].findIndex((item, idx) => idx === chartData.data[0].data[2].indexOf(subArray));
        if (pathIndex !== -1) {
            pathValue = chartData.data[0].data[5][pathIndex].path || ""; 
        }

        subArray.forEach((item) => {
            if (item.key === catalogo) {
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = capitalizeWords(estado);
            }
        });
        // console.log("ESTADITO", estado);
        
        if (estado !== "unknown" && estado.split(" ")[0] === "INNO") {
            if (estado.split(" ")[1] ==="Dentro Del Lugar De Trabajo"){
                estado = estado
            }else{
                estado = estado.split(" ")[1];
            }
            const key = `${estado}|${pathValue}`; 

            if (!stateReportMap[key]) {
                stateReportMap[key] = 0; 
            }
            stateReportMap[key]++; 
        }
    });

    const result = Object.entries(stateReportMap).map(([key, count]) => {
        const [estado, path] = key.split('|');
        return {
            estado: estado,
            path: path,
            count: count
        };
    });

    // console.log("RESULT:", result);
    

    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([barrasestadostackchart.new(root)]);


                const newDataValue = Object.entries(stateReportMap).map(([key, count]) => {
                    const [estado, path] = key.split('|');
                
                    return {
                        category: estado, // Usar el estado como categoría
                        value: count, // Usar el conteo como valors
                        severity: [{ path }] // Colocar el path como parte de severity
                    };
                });
                
                
                
                const listita = newDataValue.filter(element => element.value !== 0);
                
                let listitaCompleta = [];

                listita.forEach(element => {
                    let countLow = 0;
                    let countMedium = 0;
                    let countHigh = 0;
                
                    element.severity.forEach(subelement => {
                        switch (subelement.path) {
                            case "type::low":
                                countLow += element.value;
                                break;
                            case "type::medium":
                                countMedium += element.value;
                                break;
                            case "type::hight":
                                countHigh += element.value;
                                break;
                            default:
                                break;
                        }
                    });
                
                    if (countLow === 0) countLow = undefined;
                    if (countMedium === 0) countMedium = undefined;
                    if (countHigh === 0) countHigh = undefined;
                
                    if (countLow !== undefined || countMedium !== undefined || countHigh !== undefined) {
                        listitaCompleta.push({
                            state: element.category,
                            low: countLow,
                            medium: countMedium,
                            high: countHigh,
                        });
                    }
                });
                
                
                const combinedResults = {};
                
                listitaCompleta.forEach(item => {
                    const { state, low, medium, high } = item;
                
                    if (!combinedResults[state]) {
                        combinedResults[state] = {
                            state,
                            low: low || 0,
                            medium: medium || 0,
                            high: high || 0,
                        };
                    } else {
                        if (low) combinedResults[state].low += low;
                        if (medium) combinedResults[state].medium += medium;
                        if (high) combinedResults[state].high += high;
                    }
                });
                
                const finalResults = Object.values(combinedResults);
                
                listitaCompleta = finalResults
                
                function sumaDenuncias(item) {
                    return (item.low || 0) + (item.medium || 0) + (item.high || 0);
                }

                listitaCompleta.sort((a, b) => sumaDenuncias(a) - sumaDenuncias(b));

                
                
                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "panY",
                        wheelY: "zoomY",
                        paddingLeft: 0,
                        layout: root.verticalLayout
                    })
                );

                newChart.set("scrollbarY", am5.Scrollbar.new(root, {
                    orientation: "vertical"
                }));


                var yRenderer = am5xy.AxisRendererY.new(root, {});
                var yAxis = newChart.yAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "state",
                    renderer: yRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                }));

                yRenderer.grid.template.setAll({
                    location: 1
                })

                yAxis.data.setAll(listitaCompleta);

                var xAxis = newChart.xAxes.push(am5xy.ValueAxis.new(root, {
                    min: 0,
                    maxPrecision: 0,
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 40,
                        strokeOpacity: 0.1
                    })
                }));

                var legend = newChart.children.push(am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                }));

                function makeSeries(name, fieldName) {
                    var series = newChart.series.push(am5xy.ColumnSeries.new(root, {
                        name: name,
                        stacked: true,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        baseAxis: yAxis,
                        valueXField: fieldName,
                        categoryYField: "state"
                    }));

                    series.columns.template.setAll({
                        tooltipText: "{name}: {valueX}",
                        tooltipY: am5.percent(90)
                    });


                    series.columns.template.setAll({
                        strokeOpacity: 0,
                        cornerRadiusBR: 10,
                        cornerRadiusTR: 10,
                        cornerRadiusBL: 10,
                        cornerRadiusTL: 10,
                        maxHeight: 50,
                        fillOpacity: 0.8
                    });

                    series.data.setAll(listitaCompleta);


                    series.appear();

                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Label.new(root, {
                                text: "{valueX}",
                                fill: root.interfaceColors.get("alternativeText"),
                                centerY: am5.p50,
                                centerX: am5.p50,
                                populateText: true,
                                
                            })
                        });
                    });

                    legend.data.push(series);
                }





                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);
                cursor.lineY.set("visible", false);



                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });

                // series.appear();

                makeSeries("Baja", "low");
                makeSeries("Media", "medium");
                makeSeries("Alta", "high");


                newChart.appear(1000, 100);



                if (chart) {
                    chart.dispose();
                }
                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== 'undefined') {
            generateChart();
        }

        return () => {


            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });



            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default InnovaBarrasEstadoStack;
