import { enqueueSnackbar } from "notistack";
import { apiCall } from ".";
import { getSession } from "../handlers/getSession";
import { getTenant } from "../handlers/getTenant";
import statuscode_es from "../translations/ES/statuscode.json";
import statuscode_en from "../translations/EN/statuscode.json";

const lang = localStorage.getItem("lang");
const language = lang === "es" ? statuscode_es : statuscode_en;

//function to handle errors and display them to the user
const showErrorMessage = (error) => {
  enqueueSnackbar(`${error}`, {
    variant: "error",
    preventDuplicate: true,
    autoHideDuration: 10000,
  });
};
//  UTILITIES CLIENT
export const getUtilitiesConfig = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return {};
  try {
    const resp = await apiCall.get(`${tenant}/api/client/config/utilities/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_utilities}`);
    console.error(error);
  }
};

// GENERAL //
export const getGeneralDetails = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/client/config/general/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

// PUT INFO GENERAL
export const updateDetails = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/client/config/general/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp;
  } catch (error) {
    showErrorMessage(`${language.error_updating} (${error.response.status})`);
    console.error(error);
  }
};

// PEOPLE //
export const getPeopleDetails = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(`${tenant}/api/config/people/details/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

//CREATE CLIENT
const emailRegex =
  /^[\w\d!#\$%&'\*\+\-\/=\?\^\{\|\}~\.\-_]{1,64}?@[a-zA-Z\d!#\$%&'\*\+\-\/=\?\^\{\|\}~-]+\.?[^\.]*$/;

const isValidEmail = (email) => {
  return emailRegex.test(email);
};

const usernameRegex = /^[a-zA-Z0-9._]{3,64}$/;

const isValidUsername = (username) => {
  return usernameRegex.test(username);
};

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

const isValidPassword = (password) => {
  return passwordRegex.test(password);
};

const phoneRegex = /^\d{10,15}$/;

const isValidPhoneNumber = (phone_number) => {
  return phoneRegex.test(phone_number);
};

export const createClient = async (email, username, password, phone_number) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    if (!isValidEmail(email)) {
      showErrorMessage(`${language.please_enter_valid_email}`);
    }
    if (!isValidUsername(username)) {
      showErrorMessage(`${language.enter_valid_username}`);
    }
    if (!isValidPassword(password)) {
      showErrorMessage(`${language.segure_account_strong_password}`);
    }
    if (!isValidPhoneNumber(phone_number)) {
      showErrorMessage(`${language.enter_valid_phone_number}`);
    }
    const resp = await apiCall.post(`${tenant}/api/create`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_creating_new_client}`);
    console.error(error);
  }
};

//  CONFIG CLIENT
export const configClient = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return {};
  try {
    const resp = await apiCall.get(`${tenant}/api/client/config/utilities/`, {
      headers: {
        IdToken: session.IdToken,
        Authorization: `Bearer ${session.AccessToken}`,
      },
    });
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
  }
};

// LIST BUSINESS "LIST" GET
export const getListBusiness = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/client/config/list/business-unit/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_business_units_list}`);
    console.error(error);
  }
};

// CREATE INDUSTRY "CREATE" POST
export const createBusinessUnit = async (data) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/client/config/create/business-unit/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_creating_new_business_unit}`);
    console.error(error);
  }
};

// LIST BUSINNES "UTILITIES" GET
export const utilitiesList = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/client/config/utilities/business-unit/`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_data}`);
    console.error(error);
  }
};

// LIST BUSINESS "DETAIL" POST
export const detailBusinessUnit = async (alias) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.post(
      `${tenant}/api/client/config/detail/business-unit/`,
      { alias },
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_obtaining_business_unit_details}`);
    console.error(error);
  }
};

// UPDATED BUSINESS "UPDATED" PUT 0
export const updatedBusinessUnit = async (data = { alias: "" }) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/client/config/update/business-unit/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    enqueueSnackbar(`${language.error_updating_business_unit}`);
    console.error(error?.request.response);
  }
};

// UPPDATED BUSINESS "UPDATED STATUS " PUT 1
export const updatedStatus = async (
  data = {
    alias: "",
    is_active: "",
  }
) => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.put(
      `${tenant}/api/client/config/update/business-unit/status/`,
      data,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_updating_business_unit}`);
    console.error(error);
  }
};






























// LIST BUSINESS "LIST" GET
export const getListBusinessPublic = async () => {
  const session = getSession();
  const tenant = getTenant();
  if (!session) return;
  try {
    const resp = await apiCall.get(
      `${tenant}/api/client/config/list/business-unit/public`,
      {
        headers: {
          IdToken: session.IdToken,
          Authorization: `Bearer ${session.AccessToken}`,
        },
      }
    );
    return resp.data;
  } catch (error) {
    showErrorMessage(`${language.error_retrieving_business_units_list}`);
    console.error(error);
  }
};