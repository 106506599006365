import { useTranslation } from "react-i18next";
import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb"
import { ProgressBar } from "react-bootstrap";

export const ReportHeader = ({ title, subtitle, icon, reportDetail }) => {

  const [t] = useTranslation("report");
  const [t2] = useTranslation("information");

  const breadcrumbItems = [
    { label: t("ReportViewer.home"), route: "/" },
    { label: t("ReportViewer.all_reports"), route: "/reports/all" },
    { label: `${title || t("ReportViewer.no_folio")}` },
  ];

  const now_open = reportDetail?.open_days
    ? (reportDetail?.open_days * 100) / reportDetail?.expired_report
    : 0;



  const calculateCloseDuration = () => {
    if (reportDetail?.created_at && reportDetail?.status?.assigned) {
      const createdDate = new Date(reportDetail.created_at);
      const closedDate = new Date(reportDetail.status.assigned);
      const durationInDays = Math.floor((closedDate - createdDate) / (1000 * 60 * 60 * 24));
      return durationInDays;
    }
    return 0;
  };

  const now_close = calculateCloseDuration()
    ? (calculateCloseDuration() * 100) / reportDetail?.expired_report
    : 0;

  return (

    <div className="general-header dyTheme1 dyBorder1 rounded" id="header-report">
      <div className="d-flex align-items-center justify-content-between row">

        <div className="my-2 col-xl-6 report-info">


          <span className="title-icon">
            <svg width="48" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" float="left">
              <g fillRule="evenodd">
                <path d="M8 22.75c-3.65 0-5.75-2.1-5.75-5.75V7c0-3.65 2.1-5.75 5.75-5.75h8c3.65 0 5.75 2.1 5.75 5.75v10c0 3.65-2.1 5.75-5.75 5.75H8zM3.75 7v10c0 2.86 1.39 4.25 4.25 4.25h8c2.86 0 4.25-1.39 4.25-4.25V7c0-2.86-1.39-4.25-4.25-4.25H8C5.14 2.75 3.75 4.14 3.75 7z">
                </path>
                <path d="M16.5 9.25c-1.52 0-2.75-1.23-2.75-2.75v-2c0-.41.34-.75.75-.75s.75.34.75.75v2c0 .69.56 1.25 1.25 1.25h2c.41 0 .75.34.75.75s-.34.75-.75.75h-2zM8 13.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75H8zM8 17.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75H8z"
                  fillRule="nonzero">
                </path>
              </g>
            </svg>
          </span>

          <div>
            <h3 className="mb-0 general-header-title">{reportDetail?.folio || t("ReportViewer.no_folio")}</h3> {/* Poner asunto del reporte */}
            <h4 className="my-1">{reportDetail?.classification?.label || t("ReportViewer.no_classification")}</h4> {/* Poner categoría del reporte */}
          </div>
        </div>

        <div className="my-2 col-xl-6">
          <div className="text-lg-right">
            {/* Progreso  */}
            <div className="d-sm-flex justify-content-xl-end">
              <div className="text-left">

                {reportDetail?.status?.current !== "closed" ? (
                  <>
                    <small className="mb-1">
                      {reportDetail?.open_days} {t("ReportViewer.open_days")} &gt; {reportDetail?.expired_report}
                    </small>
                    <div>
                      <ProgressBar
                        animated
                        variant={
                          reportDetail?.open_days >= reportDetail?.expired_report
                            ? "danger"
                            : "info"
                        }
                        now={now_open}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <small className="mb-1">
                      {t("ReportViewer.close_since")} {calculateCloseDuration()} {t("ReportViewer.days")}
                    </small>
                    <div>
                      <ProgressBar
                        animated
                        variant={
                          calculateCloseDuration() >= reportDetail?.expired_report
                            ? "danger"
                            : "info"
                        }
                        now={now_close}
                      />
                    </div>
                  </>
                )}


              </div>
            </div>

            {/* Ruta */}
            <Breadcrum items={breadcrumbItems} />

          </div>
        </div>
      </div>
    </div>
  )
}
