import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login, loginAD } from "../../apis"; // Asegúrate de que esta importación es correcta
import { startFinalLogin } from '../../store/auth/authThunks';
import { useDispatch } from 'react-redux';

const AuthCallback = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        console.log("Auth callback");
        console.log(code);

        if (!code) {
          throw new Error('No se recibió código de autorización');
        } else {

          const result = await loginAD(code);

          if (result && result.data) {
            console.log("Valid auth");
            console.log(result.data);

            dispatch(startFinalLogin("not-given", result));
            navigate('/');

            console.log("Auth callback end");

            // Redirigir al usuario a la página principal o dashboard
            // window.location.href = '/dashboard';
          } else {
            console.log("Error ? ")
            // Si no hay datos, considerarlo como un error
            throw new Error('No se recibieron datos de autenticación');
          }

        }
      } catch (error) {
        console.error("Error durante la autenticación:", error);
        // Redirigir al usuario a la página de login en caso de cualquier error
        navigate('/');
      }
    };

    handleCallback();
  }, [location, navigate]);

  return <div>working...</div>;
};

export default AuthCallback;