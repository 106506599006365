import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map.js";
// import * as am5map from "@amcharts/amcharts5/map.js";

import map from './worldLow';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos




export const WorldBubbleChart = ({ chartData, startDate, endDate }) => {
  
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`worldbubblechart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title


  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {



        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5map.MapChart.new(root, {
            panX: "rotateX",
            panY: "rotateY",
            projection: am5map.geoMercator()
          })
        );

        var backgroundSeries = newChart.series.push(am5map.MapPolygonSeries.new(root, {}));
          backgroundSeries.mapPolygons.template.setAll({
          fill: root.interfaceColors.get("alternativeBackground"),
          fillOpacity: 0,
          strokeOpacity: 0
        });

        backgroundSeries.data.push({
          geometry: am5map.getGeoRectangle(90, 180, -90, -180)
        });

        var polygonSeries = newChart.series.push(
          am5map.MapPolygonSeries.new(root, {
            geoJSON: map
          })
        );

        


        polygonSeries.mapPolygons.template.setAll({
          fill: root.interfaceColors.get("alternativeBackground"),
          fillOpacity: 0.15,
          strokeWidth: 0.5,
          stroke: root.interfaceColors.get("background")
        });

        var circleTemplate = am5.Template.new({
          tooltipText: "{name}: {value}"
        });

        var bubbleSeries = newChart.series.push(
          am5map.MapPointSeries.new(root, {
            calculateAggregates: true,
            valueField: "value",
            polygonIdField: "id"
          })
        );

        bubbleSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 10,
              templateField: "circleTemplate"
            }, circleTemplate)
          });
        });

        bubbleSeries.set("heatRules", [{
          target: circleTemplate,
          min: 3,
          max: 30,
          key: "radius",
          dataField: "value"
        }]);

        var colors = am5.ColorSet.new(root, {});



        if (chartData.data[0].data.length == 2){
          bubbleSeries.data.setAll(chartData.data.map((item) => ({
            category: item.label,
            value: item.data[0].filter((value, index) => {
              const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
              if (startDate === 0 && endDate === 0) {
                return true;
              } else {
                return date >= startDate && date <= endDatePlusOneDay;
              }
            }).length,
          }))
        );
      }else{
        
          var pruebaDatos = chartData.data.map((item) => {
            if (item.data[2] != undefined){
            const filteredData = item.data[2].filter(obj => obj.catalogue === 'RD-102107100');
            var valueCountry = filteredData.length > 0 ? filteredData[0].value.split('::').pop() : null;
            switch (valueCountry) {
              case "MEX":
                valueCountry = "MX"
                break;
              case "BRA":
                valueCountry = "BR"
                break;
              case "ARG":
                valueCountry = "AR"
                break;
              case "BLZ":
                valueCountry = "BZ"
                break;
              case "BOL":
                valueCountry = "BO"
                break;
              case "USA":
                valueCountry = "US"
                break;
              case "SGP":
                valueCountry = "SG"
                break;
              case "PRY":
                valueCountry = "PY"
                break;
              case "CHL":
                valueCountry = "CL"
                break;
              case "COL":
                valueCountry = "CO"
                break;
              case "CRI":
                valueCountry = "CR"
                break;
              case "ECU":
                valueCountry = "EC"
                break;
              case "ESP":
                valueCountry = "ES"
                break;
              case "GUA":
                valueCountry = "GT"
                break;
              case "NLD":
                valueCountry = "NL"
                break;
              case "PAN":
                valueCountry = "PA"
                break;
              case "PER":
                valueCountry = "PE"
                break;
              case "DOM":
                valueCountry = "DO"
                break;
              case "SLV":
                valueCountry = "SV"
                break;
              case "GBR":
                valueCountry = "GB"
                break;
              case "URU":
                valueCountry = "UY"
                break;
              case "VEN":
                valueCountry = "VE"
                break;
              case "CAN":
                valueCountry = "CA"
                break;
              case "HND":
                valueCountry = "HN"
                break;
              case "NIC":
                valueCountry = "NI"
                break;
              case "JAM":
                valueCountry = "JM"
                break;
              case "PHL":
                valueCountry = "PH"
                break;
              default:
                break;
            }

  
          
            return {
              category: item.label,
              value: item.data[0].filter((value, index) => {
                const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                if (startDate === 0 && endDate === 0) {
                  return true;
                } else {
                  return date >= startDate && date <= endDatePlusOneDay;
                }
              }).length,
              id: valueCountry,
           
              circleTemplate: { fill: colors.getIndex(4) },
              name: item.label,
            };
            }
          }).filter(item => item !== undefined); // Filtrar elementos undefined

          bubbleSeries.data.setAll(pruebaDatos);


        }


        

        var cont = newChart.children.push(am5.Container.new(root, {
          layout: root.horizontalLayout,
          x: 20,
          y: 40
        }));

        cont.children.push(am5.Label.new(root, {
          centerY: am5.p50,
          text: "Map"
        }));
        
        var switchButton = cont.children.push(
          am5.Button.new(root, {
            themeTags: ["switch"],
            centerY: am5.p50,
            icon: am5.Circle.new(root, {
              themeTags: ["icon"]
            })
          })
        );
        
        switchButton.on("active", function () {
          if (!switchButton.get("active")) {
            newChart.set("projection", am5map.geoMercator());
            backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
          } else {
            newChart.set("projection", am5map.geoOrthographic());
            backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
          }
        });
        
        cont.children.push(
          am5.Label.new(root, {
            centerY: am5.p50,
            text: "Globe"
          })
        );


        // var exporting = am5plugins_exporting.Exporting.new(root, {
        //   menu: am5plugins_exporting.ExportingMenu.new(root, {
        //     align: "right",
        //     valign: "bottom"
        //   })
        // });

        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });



        // Add legend
        newChart.legend = am5.Legend.new(root, {});

        newChart.appear(1000, 100);

        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };


    generateChart();




    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });


      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default WorldBubbleChart;