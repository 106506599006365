import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Col, Modal, Row } from "react-bootstrap";
import { parseDateToLgComun3 } from "../../../../helpers/dateTransform";
import {
  updateStatusCloseDetails,
  updateStatusReport,
  updateStatusCheckboxes,
} from "../../../../apis";
import { Resolutions } from "../Resolutions/Resolutions";
import { Conclusions } from "../Conclusions/Conclusion";
import { useTranslation } from "react-i18next";
import { ToDoCare } from "./ToDoCare";
import { ChangeReportStatus } from "./ChangeReportStatus";
import { MCButton } from "../../../MainComponents";
import { EthicsModal } from "../../../Modal/EthicsModal";
import { WarningIcon } from "../../../Icon";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Loading } from "../../../Loading/String/Loading";
import "./Care.scss";

const getStatus = (status, translates) => {
  if (status === "new") return translates["new"];
  if (status === "finish") return translates["finish"];
  if (status === "pending") return translates["pending"];
  if (status === "closed") return translates["closed"];
  if (status === "process") return translates["process"];
  if (status === "draft") return translates["draft"];
};

export const Care = ({ reportDetail, setRerenderAll, setActiveTab, changeStatus }) => {

  const { userInfo } = useSelector(state => state.auth);
  const statuses = userInfo?.policy?.deny?.status ?? [];

  const navigate = useNavigate();

  /**
   * Esta parte es para mostrar y ocutar comentarios
   */
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll((show) => !show);
  };
  const filteredHistory = [];

  if (reportDetail?.status?.current === changeStatus) {
    // Si el comentario actual no está vacío, añadirlo al resultado filtrado
    const currentComment =
      reportDetail?.status?.comment && reportDetail?.status?.comment.trim()
        ? `${reportDetail?.status?.comment.trim()}`
        : "";

    if (currentComment) {
      filteredHistory?.push({
        status: changeStatus,
        comment: currentComment,
        assigned: reportDetail?.status?.assigned,
        sub_status: reportDetail?.status?.sub_status || [],
      });
    }
  }

  // Filtrar el historial
  //filteredHistory?.push(...reportDetail?.status?.history?.filter(history => history?.status === changeStatus));
  filteredHistory?.push(
    ...(reportDetail?.status?.history?.filter(
      (history) => history?.status === changeStatus
    ) ?? [])
  );

  const itemsToShow = showAll ? filteredHistory : filteredHistory?.slice(0, 2);
  const firstItem = itemsToShow[0];
  let subStatusReporte;
  let subStatusReporteElse;

  // Verificar que reportDetail esté definido y tenga la estructura esperada
  if (reportDetail?.status?.sub_status?.[0]?.length != undefined) {
    // Construir la clave dinámica
    const dynamicKey = "sub_status" + reportDetail?.status?.current + "_1";
    // Verificar que la clave dinámica exista en el objeto

    if (
      reportDetail?.status?.sub_status?.[0]?.[0]?.[
      reportDetail?.status?.current
      ] != undefined
    ) {
      subStatusReporte =
        reportDetail?.status?.sub_status?.[0]?.[0]?.[
        reportDetail?.status?.current
        ] ?? false;
    } else {
      subStatusReporte = reportDetail?.status?.sub_status?.[0]?.[dynamicKey]
        ? reportDetail?.status?.sub_status[0][dynamicKey]
        : reportDetail?.status?.sub_status[0];
    }
  } else {
    subStatusReporteElse = reportDetail?.status?.sub_status;
  }

  // Inicializa los checkboxes con los nombres de las keys del objeto subStatusReporte
  useEffect(() => {
    if (
      !subStatusReporte ||
      subStatusReporte?.[0]?.[0]?.length == undefined ||
      reportDetail?.status?.current == "draft" ||
      reportDetail?.status?.sub_status?.[0]?.[0].length == undefined
    ) {
      setSelectAll(true);
    }
    let initialCheckboxes = [];
    if (subStatusReporte) {

      initialCheckboxes = Object.keys(subStatusReporte).map((key) => {
        // Crear el nuevo objeto con las propiedades que necesitas
        // return {
        //   label: subStatusReporte[key][claves[0]],
        //   checked: subStatusReporte[key][claves[1]],
        //   updated_at: subStatusReporte[key][claves[2]],
        // };
        return {
          label: subStatusReporte[key].key,
          checked: subStatusReporte[key].checkbox,
          updated_at: subStatusReporte[key].updated_at,
        };
      });
    } else if (subStatusReporteElse) {
      for (let index = 0; index < subStatusReporteElse.length; index++) {
        initialCheckboxes.push({
          label: subStatusReporteElse[index]["label"],
          checked: subStatusReporteElse[index]["checked"],
          updated_at: subStatusReporteElse[index]["updated_at"],
        });
      }
    }
    if (initialCheckboxes.length > 0) {
      if (initialCheckboxes[0]["label"] == undefined) initialCheckboxes.pop();
    }
    setCheckboxes(initialCheckboxes);
    if (initialCheckboxes.length > 0) {
      const allSelected =
        initialCheckboxes.length > 0 &&
        initialCheckboxes.every((checkbox) => checkbox.checked);
      setSelectAll(allSelected);
    }
  }, [subStatusReporte]);

  const handleCheckboxChange = (index) => {
    const newCheckboxes = checkboxes.map((checkbox, i) => {
      if (i === index) {
        return {
          ...checkbox,
          checked: !checkbox.checked,
          updated_at: new Date(), // Actualizar updated_at a la fecha y hora actual
        };
      }
      return checkbox;
    });
    setCheckboxes(newCheckboxes);
  };

  function combineContiguousStatus(status) {
    const history = status.history || [];
    const currentStatus = {
      status: status.current,
      comment:
        status.comment && status.comment.trim() ? status.comment.trim() : "",
      assigned: status.assigned,
      sub_status: status.sub_status || [],
    };

    if (!Array.isArray(history) || history?.length === 0) {
      return currentStatus.comment || currentStatus.sub_status.length > 0
        ? [currentStatus]
        : [];
    }

    const combinedHistory = [];

    // Combinar el historial
    for (let i = 0; i < history?.length; i++) {
      const current = history[i];
      const lastCombined = combinedHistory[combinedHistory?.length - 1];

      if (lastCombined && lastCombined.status === current.status) {
        if (current.comment && current.comment.trim()) {
          lastCombined.comment += lastCombined.comment
            ? `, ${current.comment.trim()}`
            : current.comment.trim();
        }
        lastCombined.sub_status = [
          ...lastCombined.sub_status,
          ...current.sub_status,
        ];
        lastCombined.assigned = current.assigned;
      } else {
        combinedHistory?.push({ ...current });
      }
    }

    // Agregar el estado actual al historial combinado
    const lastCombined = combinedHistory[combinedHistory?.length - 1];
    if (lastCombined && lastCombined.status === currentStatus.status) {
      if (currentStatus.comment) {
        lastCombined.comment += lastCombined.comment
          ? `, ${currentStatus.comment}`
          : currentStatus.comment;
      }
      lastCombined.sub_status = [
        ...lastCombined.sub_status,
        ...currentStatus.sub_status,
      ];
      lastCombined.assigned = currentStatus.assigned;
    } else {
      combinedHistory?.push(currentStatus);
    }

    return combinedHistory;
  }

  const [t] = useTranslation("report");
  const [id, setId] = useState(reportDetail?.id);
  const [status, setStatus] = useState(null);
  const [commentStatus, setCommentStatus] = useState([]);
  const [messageStatus, setMessageStatus] = useState("");
  const [isFinished, setIsFinished] = useState(true);
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showDefaultHistory, setShowDefaultHistory] = useState(2);
  const [history, setHistory] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { gTheme } = useSelector((state) => state.theme);
  const [prevStatus, setPrevStatus] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const catStatus = ["new", "pending", "process", "finish", "closed"];

  useEffect(() => {
    //Sets the history in chronological order
    setHistory(reportDetail?.status?.history?.reverse());
    changeStatus = reportDetail?.status?.current;
    setPrevStatus(
      catStatus[
      catStatus.findIndex(
        (status) => status == reportDetail?.status?.current
      ) - 1
      ]
    );
  }, [reportDetail]);

  useEffect(() => {
    if (reportDetail?.status) {
      // Combinar estados contiguos y luego invertir para orden cronológico
      const reducedHistory = combineContiguousStatus(reportDetail?.status);
      setHistory(reducedHistory);
    }

    // Actualizar el estado previo basado en el status actual
    const currentStatus = reportDetail?.status?.current;
    const currentStatusIndex = catStatus.findIndex(
      (status) => status === currentStatus
    );

    if (currentStatusIndex > 0) {
      setPrevStatus(catStatus[currentStatusIndex - 1]);
    } else {
      setPrevStatus(null);
    }
  }, [reportDetail]);

  // View less/more history elements
  const toggleShowHistory = () => {
    setShowHistory(!showHistory);
  };
  useEffect(() => { }, [changeStatus]);
  // Set it if close/open modal
  const handleModal = () => setShow((show) => !show);
  // Content of the modal for reopening the closed report
  const EditReportState = () => {
    return (
      <>
        <Modal.Header
          closeButton
          className={`${gTheme !== "light" ? "btn-close-white" : ""}`}
        >
          <h4>{t("Care.edit_report_state")}</h4>
        </Modal.Header>
        <Modal.Body>
          <small className="px-3 mb-3 care__modal-text">
            {t("Care.modify_data_may_have_important_consequiences")}
          </small>
          <div className="bg-finish rounded care__modal-warning mx-2">
            <WarningIcon className="mx-2" width={50} height={50} />
            <small className="text-center px-1" style={{ fontSize: "1rem" }}>
              {t("Care.clicking_deletes_saved_data_closure_report")}
            </small>
            <WarningIcon className="mx-2" width={50} height={50} />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 care__modal-footer">
          <MCButton
            onClick={resetStatusDetails}
            variant="danger"
            label={t("Care.accept")}
            outline={isLoading}
            disabled={isLoading}
          />
          <MCButton
            label={t("Care.cancel")}
            variant="secondary"
            onClick={handleModal}
          />
        </Modal.Footer>
      </>
    );
  };
  // Sets the report status change from the ChangeReportStatus component
  const handleStatusChange = (status) => {
    setStatus(status);
  };
  // Update status report
  const handleUpdateStatus = async () => {
    setIsLoading(true);
    // if( commentStatus.length!=0 || reportDetail?.status?.current=='draft'){
    // if( commentStatus.length!=0 || reportDetail?.status?.current=='draft'){
    for (let index = 0; index < checkboxes.length; index++) {
      if (checkboxes[index].checked === false) {
        return;
      }
    }

    try {
      await updateStatusReport(
        id,
        !status ? reportDetail?.status?.current : status,
        commentStatus?.length != 0 ? commentStatus : '',
        messageStatus,
        checkboxes,
      );
      // const isDenied = statuses.includes("process") && status === "process";
      const isDenied = statuses.includes(status);
      if (isDenied) {
        enqueueSnackbar(`Reporte no encontrado`, {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 5000,
        });
        navigate("/reports/all");
      } else {
        setRerenderAll();
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const status_locales = {
    new: t("status.new"),
    process: t("status.process"),
    finish: t("status.finish"),
    pending: t("status.pending"),
    closed: t("status.closed"),
    draft: t("status.draft"),
  };
  // Calculation of history items displayed on screen
  const historyLength = history?.length - showDefaultHistory;

  let viewMore;
  if (historyLength === 1) {
    viewMore = `${t("Care.view")} 1 ${t("Care.more_change")}`;
  } else {
    viewMore = `${t("Care.view_others")} ${historyLength} ${t(
      "Care.more_changes"
    )}`;
  }
  // Deletion of saved data documenting the report closure to repen report
  const resetStatusDetails = async (e) => {
    e.preventDefault();
    enqueueSnackbar(`${t("Care.updating_report")}...`, { variant: "info" });
    setIsLoading(true);
    const details = {
      cause: {},
      close_date: "",
      closed: "",
      followup: false,
      observations: "",
      another_cause: "",
    };

    try {
      const resp = await updateStatusCloseDetails(id, details).then(() => {
        closeSnackbar();
        setIsLoading(false);
        enqueueSnackbar(`${t("Conclusion.sending_successful")}`, {
          variant: "success",
        });
        setIsFinished((isFinished) => !isFinished);
        handleModal();
      });
    } catch (error) { }
  };
  // Default open accordeon
  let statusCurrent;
  if (reportDetail?.status?.current === "finish") {
    statusCurrent = 1;
  } else {
    statusCurrent = 0;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <EthicsModal
        show={show}
        handleModal={handleModal}
        modalContent={<EditReportState />}
        size="lg"
      />

      <div className="mt-20 p-4 dyTheme1 dyBorder1 rounded care">
        <Col className={`care__header dyBorder1 rounded p-2 ${changeStatus} `}>
          <Row className="care__title">
            <h3 className="mb-0 py-1">
              {changeStatus
                ? t(
                  "status." +
                  (reportDetail?.status?.details == undefined &&
                    reportDetail?.status?.current == "closed" &&
                    changeStatus == "closed"
                    ? "closing_process"
                    : changeStatus)
                )
                : t("status." + "closing_process")}
            </h3>
            {changeStatus == reportDetail?.status?.current ? (
              <p style={{ whiteSpace: "pre-wrap" }} className="mt-2 px-4">
                {reportDetail?.status?.comment}
              </p>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <p className="m-auto text-center">
              {t("Care.updated_at")}:{" "}
              {parseDateToLgComun3(
                reportDetail?.status?.assigned,
                t("Care.locale"),
                -1
              ) || t("Care.no_updated")}
            </p>

            {reportDetail?.status?.current === "closed" && (
              <MCButton
                className="w-50 mx-auto my-1"
                // Reabrir el reporte una vez cerrado
                label={
                  isFinished ? t("Care.edit_report_state") : t("Care.cancel")
                }
                variant={isFinished ? "danger" : "secondary"}
                onClick={isFinished ? handleModal : () => setIsFinished(true)}
                outline
              />
            )}
          </Row>
        </Col>

        <Row className="mt-20">
          <Col lg="8" className="my-3">
            {itemsToShow
              .slice(1)
              .filter((history) => history.comment != "")
              .map((history, index) => (
                <div style={{ marginBottom: "10px" }}>
                  <div
                    key={index}
                    className={`care__header dyBorder1 rounded p-2 m-1 ${changeStatus}`}
                    style={{ opacity: "50%" }}
                  >
                    <p style={{ whiteSpace: "pre-wrap" }} className="mt-2 px-4">
                      {history?.comment}
                    </p>
                    <p className="m-auto text-center">
                      {t("Care.updated_at")}:{" "}
                      {parseDateToLgComun3(
                        history?.assigned,
                        t("Care.locale"),
                        -1
                      ) || t("Care.no_updated")}
                    </p>
                  </div>
                </div>
              ))}

            {/* Botón Mostrar más o Mostrar menos */}

            {filteredHistory?.length - 2 > 0 && (
              <div onClick={toggleShowAll} className="--showHistory">
                <span className="--showHistory__line"></span>
                <span className="--showHistory__lineText">
                  {showAll
                    ? t("Care.view_less")
                    : `${t("Care.view")} ${filteredHistory?.length - 2} ${t(
                      "Care.more_notifications"
                    )}`}
                </span>
                <span className="--showHistory__line"></span>
              </div>
            )}
            {/* itemsToShow
              viewMore = `${t("Care.view_others")} ${historyLength} ${t( */}

            {reportDetail?.status?.current !== "closed" || !isFinished ? (
              <>
                {/* Activities List */}

                {changeStatus == reportDetail?.status?.current ? (
                  <>
                    <Accordion defaultActiveKey={statusCurrent}>
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                          <h5 className="m-0 p-3">
                            {t("Care.change_report_status")}
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          {reportDetail?.status?.current == changeStatus ? (
                            <ToDoCare
                              checkboxes={checkboxes}
                              setCheckboxes={setCheckboxes}
                              selectAll={selectAll}
                              setSelectAll={setSelectAll}
                              handleCheckboxChange={handleCheckboxChange}
                              status={reportDetail?.status?.current}
                            />
                          ) : (
                            <></>
                          )}
                          {/* Change report status */}
                          <ChangeReportStatus
                            status={status}
                            handleStatusChange={handleStatusChange}
                            getStatus={getStatus}
                            reportDetail={reportDetail}
                            status_locales={status_locales}
                            commentStatus={commentStatus}
                            setCommentStatus={setCommentStatus}
                            messageStatus={messageStatus}
                            setMessageStatus={setMessageStatus}
                            handleUpdateStatus={handleUpdateStatus}
                            selectAll={selectAll}
                            setSelectAll={setSelectAll}
                            actualStatus={reportDetail?.status?.current}
                            checkboxes={checkboxes}
                            changeStatus={changeStatus}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* Details report status "Finish" */}
                      {reportDetail?.status?.current === "finish" &&
                        changeStatus === "finish" && (
                          <Accordion.Item eventKey={1}>
                            <Accordion.Header>
                              <h5 className="m-0 p-3">
                                {t("Care.report_resolution_details")}
                              </h5>
                            </Accordion.Header>
                            <Accordion.Body className="border-0">
                              <Resolutions
                                resolution_in={reportDetail?.resolution}
                                setRerenderAll={setRerenderAll}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                    </Accordion>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {changeStatus === "finish" ? (
                  // Contenido que se renderiza si changeStatus es "finish"
                  <>
                    <Accordion defaultActiveKey={statusCurrent}>
                      {/* Details report status "Finish" */}
                      <Accordion.Item eventKey={1}>
                        <Accordion.Header>
                          <h5 className="m-0 p-3">
                            {t("Care.report_resolution_details")}
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body className="border-0">
                          <Resolutions
                            resolution_in={reportDetail?.resolution}
                            setRerenderAll={setRerenderAll}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                ) : (
                  reportDetail?.status?.current === "closed" &&
                  changeStatus === "closed" && (
                    <Conclusions
                      report_details={reportDetail?.status?.details}
                      setRerenderAll={setRerenderAll}
                      tracking_code={reportDetail?.tracking_code}
                    />
                  )
                )}
              </>
            )}
          </Col>
          {/* History report changes*/}
          <Col lg="4" className="my-3">
            <h5 className="dyTheme2 px-4 py-3 mb-0 rounded d-flex align-items-center justify-content-between">
              {t("Care.change_history")}
            </h5>
            <div className="p-3 care__statusHistory" id="recent-reports">
              {showHistory && history?.length > 2 && (
                <div onClick={toggleShowHistory} className="--showHistory">
                  <span className="--showHistory__line"></span>
                  <span className="--showHistory__lineText">{`${t(
                    "Care.view_less"
                  )}`}</span>
                  <span className="--showHistory__line"></span>
                </div>
              )}
              <div className="care__history">
                {Array.isArray(history) && history?.length === 0 ? (
                  <div>
                    <h6 className="text-center">{t("Care.no_history")}</h6>
                  </div>
                ) : (
                  history
                    ?.slice(
                      0,
                      showHistory ? history?.length : showDefaultHistory
                    )
                    .map((item, idx) => (
                      <div
                        className={`report ${item.status} reportHystory mb-20 dyTheme1 dyBorder1 rounded`}
                        key={idx}
                      >
                        <div className="py-2 report">
                          <strong>
                            {getStatus(item.status, status_locales)}
                          </strong>
                          {item.comment ? (
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                paddingTop: "1em",
                              }}
                            >
                              {/* {item.comment} */}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <small>{`${t("Care.assigned_at")}:`}</small>
                        <small className="pb-2">
                          {parseDateToLgComun3(
                            item.assigned,
                            t("Care.locale"),
                            -1
                          )}
                        </small>
                        <small>{`${t("Care.changed_at")}:`}</small>
                        <small className="pb-2">
                          {parseDateToLgComun3(
                            item.updated_at,
                            t("Care.locale"),
                            -1
                          )}
                        </small>
                      </div>
                    ))
                )}
                {!showHistory &&
                  reportDetail?.status?.history?.length >
                  showDefaultHistory && (
                    <div onClick={toggleShowHistory} className="--showHistory">
                      <span className="--showHistory__line"></span>
                      <span className="--showHistory__lineText">
                        {viewMore}
                      </span>
                      <span className="--showHistory__line"></span>
                    </div>
                  )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
