import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import "./TableIndicator.scss";

import TableWithGrouping from "./ExpandableTable"

import { innova, innovaMultiVerticalGeneral, innovaDistribution, innovaStacked, innovaMexico, innovaSpecific, innovaMexicoZona } from "./InnovaFunctions"
import {reportByMonth} from "./SpecialFunctions"

export const TableIndicator = ({ dataTable }) => {



  const [t] = useTranslation("dashboard");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});


  const columns = [
    {
      name: t("TableIndicator.indicator"),
      selector: (row) => row.title,
      sortable: true,
      grow: 2,
    },
    {
      name: t("TableIndicator.amount"),
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: t("TableIndicator.percentage"),
      selector: (row) => row.percentage,
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #E5E5E5",
      },
    },
  };

  let catalogo = "";
  let tipoGrafica = "NAN";
  if (dataTable.show_graphic == "special") {
    tipoGrafica = dataTable.show_graphic_graphic;
  }else{
    tipoGrafica = dataTable.show_graphic_graphic + "NOINNOVA";
  }
  

  if (dataTable.show_graphic_graphic) {

    let newCatalogo = "donde_sucedieron_los_hechos";
    if (newCatalogo && newCatalogo !== catalogo) {
      catalogo = 'C::OWN::' + newCatalogo;
    }
  }



  let infoForTable = {};
  if (tipoGrafica == "28" || tipoGrafica == "29" || tipoGrafica == "30" || tipoGrafica == "31" || tipoGrafica == "32") {
    infoForTable = innova(dataTable, catalogo);
  }
  else if (tipoGrafica == "43") {
    infoForTable = innovaMultiVerticalGeneral(dataTable, catalogo);
  }
  else if (tipoGrafica == "23" || tipoGrafica == "46") {
    infoForTable = innovaDistribution(dataTable, catalogo, tipoGrafica);
  }
  else if (tipoGrafica == "38") {
    infoForTable = innovaStacked(dataTable, catalogo);
  }
  else if (tipoGrafica == "33") {
    infoForTable = innovaMexico(dataTable, catalogo);
  }
  else if (tipoGrafica == "44") {
    infoForTable = innovaSpecific(dataTable, catalogo);
  }
  else if (tipoGrafica == "45") {
    infoForTable = innovaMexicoZona(dataTable, catalogo);
  }
  else if (tipoGrafica == "23NOINNOVA") {
    infoForTable = reportByMonth(dataTable, catalogo);
  }
  else {
    infoForTable = {};
  }






  useEffect(() => {

    let totalData = 0;

    if (tipoGrafica == "28" || tipoGrafica == "29" || tipoGrafica == "30" || tipoGrafica == "31" || tipoGrafica == "32" || tipoGrafica == "33" || tipoGrafica == "45" ) {

      totalData = Object.values(infoForTable).reduce((acc, curr) => acc + curr, 0);

      const itemData = Object.entries(infoForTable)
        .map(([title, count]) => {
          if (count > 0) {
            const percentage = `${((count * 100) / totalData).toFixed(2)}%`;

            return {
              title,
              count,
              percentage,
            };
          }
          return null;
        })
        .filter(item => item !== null);

      const sortedProcessedData = itemData.sort((a, b) => a.title.localeCompare(b.title));


      setData(sortedProcessedData);
      setTotal({
        title: "Total",
        count: totalData,
        percentage: "100%",
      });
    }
    else if (tipoGrafica == "43" || tipoGrafica == "23" || tipoGrafica == "44" || tipoGrafica == "46"|| tipoGrafica == "23NOINNOVA" ) {

      const reportMap = {};

      totalData = Object.values(infoForTable).reduce((acc, subData) => {
        return acc + Object.values(subData).reduce((subAcc, count) => subAcc + count, 0);
      }, 0);



      Object.entries(infoForTable).forEach(([title, subData]) => {
        reportMap[title] = {};

        Object.entries(subData).forEach(([subTitle, count]) => {
          if (count > 0) {
            const percentage = `${((count * 100) / totalData).toFixed(2)}%`;

            reportMap[title][subTitle] = {
              count,
              percentage,
            };
          }
        });
      });
      const processedData = Object.entries(reportMap).flatMap(([title, subData]) =>
        Object.entries(subData).map(([subTitle, { count, percentage }]) => ({
          title: title.replace(/_/g, " "), // Reemplazar "_" por " " en `title`
          subtitle: subTitle.replace(/_/g, " "),
          count,
          percentage
        }))
      );

      processedData.forEach(item => {
        item.title = item.title.charAt(0).toUpperCase() + item.title.slice(1);
      });

      const sortedProcessedData = processedData.sort((a, b) => a.title.localeCompare(b.title));

      setData(sortedProcessedData);
      setTotal({
        title: "Total",
        count: totalData,
        percentage: "100%",
      });




    }
    else if (tipoGrafica == "38") {

      const reportMap = {};

      const totalData = infoForTable.reduce((acc, { high, low, medium }) => acc + high + low + medium, 0);



      infoForTable.forEach(({ state, high, low, medium }) => {
        reportMap[state] = {};

        if (low > 0) {
          const percentage = `${((low * 100) / totalData).toFixed(2)}%`;
          reportMap[state].low = { count: low, percentage };
        }

        if (medium > 0) {
          const percentage = `${((medium * 100) / totalData).toFixed(2)}%`;
          reportMap[state].medium = { count: medium, percentage };
        }

        if (high > 0) {
          const percentage = `${((high * 100) / totalData).toFixed(2)}%`;
          reportMap[state].high = { count: high, percentage };
        }
      });

      // Convertir `reportMap` en `processedData` para la tabla
      const processedData = Object.entries(reportMap).flatMap(([state, severities]) =>
        Object.entries(severities).map(([severity, { count, percentage }]) => {
          let translatedSeverity;

          // Traducir severidad
          switch (severity) {
            case 'low':
              translatedSeverity = t("TableIndicator.low");
              break;
            case 'medium':
              translatedSeverity = t("TableIndicator.medium");
              break;
            case 'high':
              translatedSeverity = t("TableIndicator.high");
              break;
            default:
              translatedSeverity = severity;
          }

          return {
            title: state,
            subtitle: translatedSeverity,
            count,
            percentage
          };
        })
      );


      const sortedProcessedData = processedData.sort((a, b) => a.title.localeCompare(b.title));


      setData(processedData);

      const totalValue = totalData || 0; // Asigna 0 si totalData es falsy



      setTotal((prev) => ({
        ...prev,
        title: "Total",
        count: totalValue,
        percentage: "100%",
      }));



    }
    else {
      totalData = dataTable.data.reduce((a, b) => a + b.data[0].length, 0);
      const itemData = dataTable.data
        .map((item) => {
          const count = item.data[0].length;

          if (count > 0) {
            const percentage = `${((count * 100) / totalData).toFixed(2)}%`;

            return {
              title: item.label,
              count,
              percentage,
            };
          }
          return null;
        })
        .filter(item => item !== null);

      const sortedProcessedData = itemData.sort((a, b) => a.title.localeCompare(b.title));


      setData(sortedProcessedData);

      setTotal({
        title: "Total",
        count: totalData,
        percentage: "100%",
      });

    }

  }, []);

  return (
    <div className="table-container">
      {tipoGrafica === "43" || tipoGrafica === "23" || tipoGrafica === "38" || tipoGrafica === "44" || tipoGrafica === "46"|| tipoGrafica == "23NOINNOVA" ? (
        <TableWithGrouping data={data} />
      ) : (
        <>
          <DataTable columns={columns} data={data} customStyles={customStyles} />
        </>
      )}
      <>

        <DataTable
          noHeader={true}
          noTableHead={true}
          columns={columns}
          data={[total]}
        />

      </>
    </div>
  );
};
