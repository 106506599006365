import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../../colors"; // Importar los colores definidos
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";



export const InnovaMultiVerticalBars = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`MultiVerticalBars-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate;
    const diaFin = endDate;

    // console.log("CHARAHRARHAHRAHR", chartData);




    // const stateReportMap = {};
    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const tipoCentroTrabajoMap = {};


    chartData.data[0].data[2].forEach((subArray) => {
        let tipoCentro = "unknown";
        let tipoDenuncia = "unknown";

        subArray.forEach((item) => {
            // console.log("ITEM.KEY", item.key);
            
            if (item.key === 'C::OWN::donde_sucedieron_los_hechos') {
                // Capitaliza y limpia el tipo de centro de trabajo
                // tipoCentro = item.value.replace(/type::/g, '').replace(/_/g, ' ');
                // tipoCentro = capitalizeWords(tipoCentro);

                
                
                
                
                
                
                tipoCentro = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                tipoCentro = tipoCentro.split(" ");
                // console.log("TIPO DE CENTRO", tipoCentro);
                if (tipoCentro[0] === "INNO"){
                    tipoCentro = tipoCentro[1];
                    tipoCentro = capitalizeWords(tipoCentro);
                } else {
                    tipoCentro = "unknown";
                    
                }
                
                // Filtra solo los que empiezan con 'Sucursal::' y elimina el prefijo
                // if (tipoCentro.startsWith('Sucursal::')) {
                    //     tipoCentro = tipoCentro.replace('Sucursal::', '');
                    //     tipoCentro = capitalizeWords(tipoCentro);
                    
                    // } else {
                        //     tipoCentro = "unknown";  // Ignorar si no comienza con 'Sucursal::'
                        // }
                        // console.log("TIPO CENTRO", tipoCentro);
                        
            }

            if (item.key === 'C::OWN::tipo_de_reporte') {
                // Capitaliza y limpia el tipo de denuncia
                tipoDenuncia = item.value.replace(/type::/g, '');
                tipoDenuncia = capitalizeWords(tipoDenuncia);
                tipoDenuncia = tipoDenuncia.replace(/_/g, ' ')
                if (tipoDenuncia.includes("::")){
                    tipoDenuncia = tipoDenuncia.split("::")[1];
                    tipoDenuncia = capitalizeWords(tipoDenuncia);
                }
            }
        });

        // Solo agrega si el centro de trabajo es válido
        if (tipoCentro !== "unknown") {
            if (!tipoCentroTrabajoMap[tipoCentro]) {
                tipoCentroTrabajoMap[tipoCentro] = {};
            }

            if (!tipoCentroTrabajoMap[tipoCentro][tipoDenuncia]) {
                tipoCentroTrabajoMap[tipoCentro][tipoDenuncia] = 0;
            }
            tipoCentroTrabajoMap[tipoCentro][tipoDenuncia]++;
        }
    });
















    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {



                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([am5themes_Animated.new(root)]);
                // root.setThemes([MyTheme.new(root)]);

                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panY: false,
                        layout: root.verticalLayout,
                    })
                );




                var legend = newChart.children.push(
                    am5.Legend.new(root, {
                        centerX: am5.p50,
                        x: am5.p50
                    })
                );




                const yAxis = newChart.yAxes.push(am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 0.1
                    })
                }));
                
                // Configuración del eje X (para los centros de trabajo)
                const xRenderer = am5xy.AxisRendererX.new(root, {
                    cellStartLocation: 0.1,
                    cellEndLocation: 0.9,
                    minorGridEnabled: true
                });
                
                const xAxis = newChart.xAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "centroTrabajo",
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                }));
                
                xRenderer.grid.template.setAll({
                    location: 1
                });
                
                // Preparar los datos para el gráfico
                const data = [];
                for (let centro in tipoCentroTrabajoMap) {
                    const centroData = { centroTrabajo: centro };
                    for (let tipoDenuncia in tipoCentroTrabajoMap[centro]) {
                        centroData[tipoDenuncia] = tipoCentroTrabajoMap[centro][tipoDenuncia];
                    }
                    data.push(centroData);
                }
                
                // Configurar los datos en el eje X
                xAxis.data.setAll(data);
                
                // Función para crear una serie de columnas por cada tipo de denuncia
                function makeSeries(name, fieldName) {
                    const series = newChart.series.push(am5xy.ColumnSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: fieldName,
                        categoryXField: "centroTrabajo"
                    }));
                
                    series.columns.template.setAll({
                        tooltipText: "{name}: {valueY}",
                        width: am5.percent(90),
                        tooltipY: 0,
                        strokeOpacity: 0
                    });
                
                    series.data.setAll(data);
                
                    series.appear();
                
                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            locationY: 0,
                            sprite: am5.Label.new(root, {
                                text: "{valueY}",
                                fill: root.interfaceColors.get("alternativeText"),
                                centerY: 0,
                                centerX: am5.p50,
                                populateText: true
                            })
                        });
                    });
                
                    legend.data.push(series);
                }
                
                // Crear series de acuerdo con los tipos de denuncia
                const tiposDenuncia = new Set();
                data.forEach((centro) => {
                    Object.keys(centro).forEach((key) => {
                        if (key !== "centroTrabajo") {
                            tiposDenuncia.add(key);
                        }
                    });
                });
                
                tiposDenuncia.forEach((tipo) => makeSeries(tipo, tipo));



                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);
                cursor.lineY.set("visible", false);


                //   series.columns.template.adapters.add("fill", function (fill, target) {
                //     return newChart.get("colors").getIndex(series.columns.indexOf(target));
                //   });
                //   series.columns.template.adapters.add("stroke", function (stroke, target) {
                //     return newChart.get("colors").getIndex(series.columns.indexOf(target));
                //   });
                //   series.data.setAll(newData);


                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });

                //   series.appear();
                newChart.appear(1000, 100);


                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== 'undefined') {
            generateChart();
        }

        return () => {

            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });



            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};


export default InnovaMultiVerticalBars;