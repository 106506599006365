

import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const MonthsReportsHistoricBarChart = ({ chartData, startDate, endDate }) => {
  const [chart, setChart] = useState(null);
  const chartDivId = useRef(`MonthsReportsHistoric-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
  const diaInicio = startDate
  const diaFin = endDate
  const graphicTitle = chartData.title



  useEffect(() => {
    let newChart = null;

    const generateChart = async () => {
      try {



        var startDate = new Date(diaInicio).getTime();
        var endDate = new Date(diaFin).getTime();
        const endDatePlusOneDay = new Date(endDate);
        endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


        const root = am5.Root.new(chartDivId.current);
        // root.setThemes([am5themes_Animated.new(root)]);
        root.setThemes([MyTheme.new(root)]);

        newChart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panY: false,
            layout: root.verticalLayout,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0
          })
        );


        var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {
          behavior: "zoomX"
        }));
        cursor.lineY.set("visible", true);
        const daysOpenArray = chartData.data[0].data[1];

        const groupedData = daysOpenArray.map((daysOpen) => {
          // Verificamos que daysOpen sea un número válido
          if (isNaN(daysOpen)) {
            return null;
          }
        
          // Calcular la fecha de apertura del reporte
          const openDate = new Date(Date.now() - (daysOpen * 24 * 60 * 60 * 1000));
          // Incrementar el mes en 1
          const nextMonthDate = new Date(openDate.getFullYear(), openDate.getMonth() + 1);
        
          const monthYear = `${nextMonthDate.getFullYear()}-${String(nextMonthDate.getMonth() + 1).padStart(2, '0')}`; // Formato "YYYY-MM"
          return {
            date: monthYear,
            value: 1, // Cada elemento representa un reporte abierto
          };
        }).filter(item => item !== null); // Filtrar elementos nulos
        
        // Paso 2: Agrupar los reportes por mes y sumar
        const monthlyReportData = groupedData.reduce((acc, { date, value }) => {
          if (!acc[date]) {
            acc[date] = 0;
          }
          acc[date] += value; // Sumar los reportes abiertos en el mes
          return acc;
        }, {});
        
        const newData = Object.entries(monthlyReportData)
          .map(([date, value]) => {
            const timestamp = new Date(`${date}-01`).getTime();
            return {
              date: !isNaN(timestamp) ? timestamp : null, // Validar timestamp
              value: value,
            };
          })
          .filter(data => data.date !== null) // Filtrar elementos con date inválido
          .sort((a, b) => a.date - b.date); // Ordenar por fecha
        
        const yAxis = newChart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
          })
        );

        const xAxis = newChart.xAxes.push(am5xy.DateAxis.new(root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: "month",
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 200,
            minorLabelsEnabled: true
          }),
          tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(newData.map(item => item.data));

        
        const series = newChart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Value",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{valueY}"
            })


          })
        );


        series.columns.template.adapters.add("fill", function (fill, target) {
          return newChart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.columns.template.adapters.add("stroke", function (stroke, target) {
          return newChart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.setAll({
          width: am5.percent(100)
        });
        series.data.setAll(newData);


        var title = newChart.children.push(am5.Label.new(root, {
          text: graphicTitle,
          fontSize: 20,
          x: am5.percent(50),
          y: am5.percent(0),
          centerX: am5.percent(50),
          visible: false // Ocultar el título inicialmente
        }));

        // Configuración de exportación
        var exporting = am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            align: "right",
            valign: "bottom"
          }),
          pdfOptions: {
            addURL: false
          }
        });

        // Evento para mostrar el título solo durante la exportación a PDF
        exporting.events.on("exportstarted", function (event) {
          if (event.format === "pdf") {
            title.set("visible", true); // Mostrar el título solo al exportar a PDF
          }
        });

        exporting.events.on("exportfinished", function (event) {
          title.set("visible", false); // Ocultar el título nuevamente después de exportar
        });



        series.appear();
        newChart.appear(1000, 100);


        if (chart) {
          chart.dispose();
        }

        setChart(newChart);
      } catch (error) {
        console.error("Error al cargar las bibliotecas de AmCharts:", error);
      }
    };

    if (typeof window !== 'undefined') {
      generateChart();
    }

    return () => {

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root) {
          if (root.dom.id == chartDivId.current) {
            root.dispose();
          }
        }
      });



      if (chart) {
        chart.dispose();
      }
    };
  }, [chartData, startDate, endDate]);

  return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default MonthsReportsHistoricBarChart;