import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Tabs, Tab, Modal } from "react-bootstrap";
import {
  BoldMessageText,
  ProfileIcon,
  OutlineLogin,
  Settings,
  NotificationIcon,
  OutlineEdit2,
  OutlineArrowRight5,
  DollarCircle,
  TwentyFourSupport,
  OutlinePeople,
  OutlineNote,
} from "../Icon";
import {
  CDBDropDown,
  CDBDropDownItem,
  CDBDropDownMenu,
  CDBDropDownToggle,
  CDBNavbar,
  CDBNavBrand,
  CDBNavItem,
  CDBNavbarNav,
  CDBIcon,
} from "cdbreact";
import { startLoggedOut } from "../../store/auth/authThunks";
import { startSetTheme } from "../../store/theme/themeThunks";
import MobileLogo from "../../assets/images/imagotipo-ethics.png";
import TabletLogo from "../../assets/images/logotipo-ethics-copy.png";
import Search from "../../assets/images/vuesax-outline-search-normal2.png";
import Hamburger from "../../assets/images/hamburger.png";
import Profile from "../../assets/images/profile1.png";
import MobileSearch from "../MobileNavBarSearchBar/MobileSearch";
import SearchBar from "../NavBarSearchBar/Search";
import { MCTooltip } from "../MainComponents/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import "./GlobalComponents.scss";




import { NotificationByUserView, MarkAllAsRead } from "../../apis";
import NotificationsCards from "./NotificationsCards";
import NotificationsReadCards from "./NotificationsReadCards";
import NotificationsUnreadCards from "./NotificationsUnreadCards";

import Form from "react-bootstrap/Form";

export const Toggler = () => {
  const [t] = useTranslation("components");
  const { gTheme } = useSelector((state) => state.theme);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [activeKey, setActiveKey] = useState('Todas');
  const location = useLocation();

  // const navigate = useNavigate();
  // const handleChangeTheme = () => {
  //     dispatch( startSetTheme() );
  // }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await NotificationByUserView();
        setNotificationData(response.results);
        rechargeData("20");
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [location]);

  const rechargeData = async (registros) => {
    try {
      const response = await NotificationByUserView(registros);
      setNotificationData(response.results); 
    } catch (error) {
      console.error("Error:", error); 
    }
  };


  const NoNotification = () => {
    return (
      <h6 className="notifications__noNotification">
        {t("Navbar.no_notifications")}...
      </h6>
    );
  };

  const handleTabClick = (key) => {
    setActiveKey(key);
    setShowNotifications(false);
  };


  const handleSelectChange = (e) => {
    const selectedValue = e.target.value; 
    rechargeData(selectedValue);
    
  };

  const handleMarkAllAsRead = async () => {
    try {
      await MarkAllAsRead();
      // Refetch notifications
      const response = await NotificationByUserView();
      setNotificationData(response.results);
    } catch (error) {
      console.error("Error marking all as read:", error);
    }
  };


  const NavItems = () => {
    const id = gTheme === "dark" ? "dark-setting" : "";
    const iconTheme = gTheme === "dark" ? "sun" : "bold_moon";
    return (
      <>
         <MCTooltip text={t("Navbar.notifications")} position="bottom">
          <div
            className="d-flex desktop-setting"
            onClick={() => setShowNotifications(true)}
          >
            <NotificationIcon id={id} />
          </div>
        </MCTooltip>

        {/* <MCTooltip text="Cambiar tema" position="bottom">
          <div className="d-flex desktop-setting" onClick={handleChangeTheme}>
            <Icon name={iconTheme} id={id} />
          </div>
        </MCTooltip> */}

        <MCTooltip text={t("Navbar.settings")} position="bottom">
          <Link className="d-flex desktop-setting" to="/settings">
            <Settings id={id} />
          </Link>
        </MCTooltip>
      </>
    );
  };

  return (
    <>
      <div className="control-theme">
        <NavItems gTheme={gTheme} />
      </div>
      {/* Notification Modal */}
      <Modal
        show={showNotifications}
        onHide={() => setShowNotifications(false)}
        size="lg"
        id="client-detail"
        className={`${gTheme !== "light" ? "dark-mode" : ""}`}
        centered
      >

        <Modal.Header
          closeButton
          className={`${gTheme !== "light" ? "btn-close-white" : ""}`}
        >

          <NotificationIcon className="mx-2" />
          <h4 className="m-0">{t("Navbar.notifications")}</h4>
                  
          <Form.Select
        size="sm"
        className="me-2"
        style={{ minWidth: '20%', fontSize: '12px', maxWidth: '20%', marginLeft: '3%' }}
        onChange={handleSelectChange} // Función para manejar el cambio de selección
      >
        <option value={"20"}>{t("Navbar.last_20")}</option>
        <option value={"30"}>{t("Navbar.last_30")}</option>
        <option value={"50"}>{t("Navbar.last_50")}</option>
      </Form.Select>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleMarkAllAsRead();
        }}
        style={{
          position: 'absolute',
          top: '70px',
          right: '8%',
          fontSize: '12px',
          textDecoration: 'none',
          color: '#007bff',
        }}
        className="mark-all-link"
      >
        { activeKey == "No leídos" ? (
          <>
          {t("Navbar.mark_all_as_read")}
          </>
        ):(
          <></>
        )}
        
      </a>
        </Modal.Header>
        <Modal.Body>
        <Tabs
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
          className="notifications"
        >
          <Tab eventKey="No leídos" title={t("Navbar.unread")} onClick={() => handleTabClick('No leídos')}>
            <div className="notifications__tab">
              {notificationData ? (
                <NotificationsUnreadCards reportsData={notificationData} />
              ) : (
                <NoNotification />
              )}
            </div>
          </Tab>

          <Tab eventKey="Leidas" title={t("Navbar.read")} onClick={() => handleTabClick('Leidas')}>
            <div className="notifications__tab">
              {notificationData ? (
                <NotificationsReadCards reportsData={notificationData} />
              ) : (
                <NoNotification />
              )}
            </div>
          </Tab>

          <Tab eventKey="Todas" title={t("Navbar.all")} onClick={() => handleTabClick('Todas')}>
            <div className="notifications__tab">
              {notificationData ? (
                <NotificationsCards reportsData={notificationData} />
              ) : (
                <NoNotification />
              )}
            </div>
          </Tab>
                {/* Tab "Customized" */}
                {/* <Tab
              eventKey="Personalizadas"
              title={t("Navbar.customized")}
              className="notifications__tab"
            >
              <div className="notifications__tab">
                <NoNotification />
              </div>
            </Tab> */}
          
        </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );

};

export const Navbar = () => {
  const [t] = useTranslation("components");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(startLoggedOut());
    navigate("/login");
  };

  return (
    <div className={`dyBackground tenant_navbar dashboard_nav`}>
      <CDBNavbar light expand="md" scrolling>
        <CDBNavBrand className="tablet-logo">
          <img src={TabletLogo} alt="logo" />
        </CDBNavBrand>
        <CDBNavBrand className="d-sm-none">
          <img src={MobileLogo} className="d-sm-none" alt="mobile-logo" />
        </CDBNavBrand>
        <CDBNavbarNav
          className="justify-content-end"
          style={{ width: "100%" }}
          right
        >
          <CDBNavItem>
            <div className="desktop-search">
              <SearchBar placeholder={t("Navbar.search_reports")} />
            </div>
            {/* <MobileSearch className="mobile-search"></MobileSearch> */}
            {/* <img src={Search} className="mobile-search" alt="search" /> */}
            <MobileSearch className="mobile-search"></MobileSearch>
            {/* Start Switch Color Setting */}
            <Toggler />
            {/* End Switch Color Setting */}
            <Link to="/reports/new">
              <Button className="btn-form">{t("Navbar.new_report")}</Button>
            </Link>
            {/* Start Profile Dropdown */}
            <CDBDropDown dropleft id="profile-dropdown" className="mr-2" isOpen>
              <CDBDropDownToggle>
                <MCTooltip text={t("Navbar.menu")} position="bottom">
                  <div>
                    <img
                      src={Profile}
                      className="d-none d-md-flex mx-2"
                      alt={t("Navbar.profile")}
                    />
                  </div>
                </MCTooltip>
              </CDBDropDownToggle>
              <CDBDropDownMenu>
                <div className="profile-header">
                  <BoldMessageText />
                  <div className="ml-2">
                    <a
                      href="https://webservice.atlassian.net/servicedesk/customer/portal/"
                      target="_blank"
                    >
                      <h6>{t("Navbar.send_suggestions")} </h6>
                      <small>{t("Navbar.we_want_opinion")} </small>
                    </a>
                  </div>
                </div>

                 <CDBDropDownItem onClick={() => navigate("/profile")}>
                  <OutlineEdit2 />
                  <span className="profileItem">
                    {t("Navbar.edit_profile")}{" "}
                  </span>
                </CDBDropDownItem> 
                {/* <CDBDropDownItem onClick={() => navigate("/profile")}>
                  <DollarCircle />
                  <span className="profileItem">{t("Navbar.payments")}</span>
                </CDBDropDownItem>
                <CDBDropDownItem onClick={() => navigate("/profile")}>
                  <TwentyFourSupport />
                  <span className="profileItem">{t("Navbar.support")}</span>
                </CDBDropDownItem>
                <hr />
                <CDBDropDownItem onClick={() => navigate("/users")}>
                  <OutlinePeople />
                  <span className="profileItem">{t("Navbar.users")}</span>
                </CDBDropDownItem>
                <CDBDropDownItem onClick={() => navigate("/")}>
                  <OutlineNote />
                  <span className="profileItem">{t("Navbar.logbook")}</span>
                </CDBDropDownItem>  */}

                <CDBDropDownItem onClick={handleLogout}>
                  <span className="profileItem">{t("Navbar.logout")} </span>
                </CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
            {/* End Profile Dropdown */}

            <CDBDropDown
              dropleft
              id="profile-dropdown"
              className="menu-dropdown"
            >
              <CDBDropDownToggle>
                <img src={Hamburger} className="hamburger" alt="hamburger" />
              </CDBDropDownToggle>
              <CDBDropDownMenu>
                <CDBDropDownItem>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <ProfileIcon className="profile-svg" />
                    </div>
                    <div className="icons">
                      <Link className="settings" to="/settings">
                        <Settings />
                      </Link>
                      <Link className="login" onClick={handleLogout}>
                        <OutlineLogin />
                      </Link>
                    </div>
                  </div>
                </CDBDropDownItem>
                {/* <CDBDropDownItem>
                              <BoldElement2 /><Link to="/dashboard" className='dashboard'>Dashboard</Link>
                          </CDBDropDownItem>
                          <CDBDropDownItem>
                              <BoldDocumentText /><Link to="/reports/all" className='reportes'>Reportes</Link>
                          </CDBDropDownItem>
                          <CDBDropDownItem>
                              <BarChart2Bold /><Link to="/" className='estadisticas'>Estadísticas</Link>
                          </CDBDropDownItem>
                          <CDBDropDownItem>
                              <NotificationIconBold /><Link to="/" className='notificaciones'>Notificaciones</Link>
                          </CDBDropDownItem>
                          <CDBDropDownItem>
                              <BoldDirect /><Link to="/" className='buzon'>Buzón</Link>
                          </CDBDropDownItem> */}
                {/* <hr /> */}
                {/* <CDBDropDownItem>
                              <BoldMessageQuestion2 /><Link to="/" className='ayuda'>Ayuda</Link>
                          </CDBDropDownItem>
                          <CDBBtn className="powered_by">Powered by EthicsGlobal</CDBBtn> */}
              </CDBDropDownMenu>
            </CDBDropDown>
          </CDBNavItem>
        </CDBNavbarNav>
      </CDBNavbar>
    </div>
  );
};
