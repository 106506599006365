import DataTable from "react-data-table-component";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";




const TableWithGrouping = ({ data }) => {


    const [t] = useTranslation("dashboard");

    // Configurar datos en formato expandible
    const groupedData = data.reduce((acc, item) => {
        const { title, subtitle, count, percentage } = item;

        // Encontrar o crear el grupo principal
        let group = acc.find((g) => g.title === title);
        if (!group) {
            group = { title, subItems: [] };
            acc.push(group);
        }

        // Añadir el subitem al grupo
        group.subItems.push({ subtitle, count, percentage });
        return acc;
    }, []);

    // Configurar columnas principales y subcolumnas
    const columns = [
        {
            name: "",
            selector: (row) => row.title,
            sortable: true,
            grow: 2,
            style: {
                fontWeight: "bold", 
            }
        },
        {
            name: t("TableIndicator.amount"),
            selector: (row) => row.count,
            sortable: true,
            omit: true, 
        },
        {
            name: t("TableIndicator.percentage"),
            selector: (row) => row.percentage,
            sortable: true,
            omit: true, 
        },
    ];

    const subColumns = [
        {
            name: "",
            selector: (row) => row.subtitle,
            sortable: false,
            grow: 2,
        },
        {
            name: t("TableIndicator.amount"),
            selector: (row) => row.count,
            sortable: false,
        },
        {
            name: t("TableIndicator.percentage"),
            selector: (row) => row.percentage,
            sortable: false,
        },
    ];

    const ExpandableComponent = ({ data }) => (
        <DataTable
            columns={subColumns}
            data={data.subItems}
            noHeader
            pagination={false}
        />
    );

    return (
        <DataTable
            columns={columns}
            data={groupedData}
            expandableRows
            expandableRowExpanded={(row) => !!row.subItems}
            expandableRowsComponent={ExpandableComponent}
        />
    );
};

export default TableWithGrouping;