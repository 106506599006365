export const innova = (dataTable, catalogo) => {



    const processDenuncias = (dataTable, catalogo) => {
        const reportMap = {};

        const capitalizeWords = (str) => {
            return str
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };

        dataTable.data[0].data[2].forEach((subArray) => {
            let tipoDenuncia = "unknown";
            let catalogoEncontrado = false;

            subArray.forEach((item) => {
                if (item.key === catalogo) {
                    catalogoEncontrado = true;
                }
                if (item.key === 'C::OWN::relacion_con_la_empresa') {
                    tipoDenuncia = item.value.replace(/type::/g, '');
                }
            });

            if (catalogoEncontrado) {
                tipoDenuncia = capitalizeWords(tipoDenuncia);

                if (!reportMap[tipoDenuncia]) {
                    reportMap[tipoDenuncia] = 0;
                }

                reportMap[tipoDenuncia]++;
            }
        });

        return reportMap;
    };
    const reportMap = processDenuncias(dataTable, catalogo);
    return reportMap;
}




export const innovaMultiVerticalGeneral = (dataTable, catalogo) => {

    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const reportMap = {};

    dataTable.data[0].data[2].forEach((subArray) => {
        let tipoCentro = "unknown";
        let tipoDenuncia = "unknown";

        subArray.forEach((item) => {

            if (item.key === 'C::OWN::donde_sucedieron_los_hechos') {
                tipoCentro = item.value.replace(/type::/g, '').replace(/_/g, ' ');
                tipoCentro = capitalizeWords(tipoCentro);



                if (tipoCentro.startsWith('INNO')) {
                    tipoCentro = tipoCentro.replace('INNO', '');
                    tipoCentro = capitalizeWords(tipoCentro);


                } else {
                    tipoCentro = "unknown";
                }
            }

            if (item.key === 'C::OWN::tipo_de_reporte') {
                tipoDenuncia = item.value.replace(/type::/g, '');
                tipoDenuncia = item.value.split("::").pop();
                tipoDenuncia = capitalizeWords(tipoDenuncia);
                tipoDenuncia = tipoDenuncia.replace(/_/g, ' ')

            }
        });

        if (tipoCentro !== "unknown") {
            if (!reportMap[tipoCentro]) {
                reportMap[tipoCentro] = {};
            }

            if (!reportMap[tipoCentro][tipoDenuncia]) {
                reportMap[tipoCentro][tipoDenuncia] = 0;
            }
            reportMap[tipoCentro][tipoDenuncia]++;
        }
    });



    return reportMap;

}


export const innovaDistribution = (dataTable, catalogo, tipoGrafica) => {
    
    
    const reportMap = {};
    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    dataTable.data[0].data[2].forEach((subArray) => {
        let estado = "unknown";
        let tipoDenuncia = "unknown";

        subArray.forEach((item) => {

            if (item.key === catalogo) {
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = capitalizeWords(estado);
            }
            if (item.key === 'C::OWN::tipo_de_reporte') {
                tipoDenuncia = item.value.replace(/type::/g, '');
                tipoDenuncia = item.value.split("::").pop();
                tipoDenuncia = capitalizeWords(tipoDenuncia);
            }
        });


        
        if (estado.split(" ")[0] == "INNO"){
            if (tipoGrafica == 46) {
                if (estado.split(" ")[1] == "CDMX") {
                    if (estado !== "unknown") {
                        if (!reportMap[estado]) {
                            reportMap[estado] = {};
                        }

                        if (!reportMap[estado][tipoDenuncia]) {
                            reportMap[estado][tipoDenuncia] = 0;
                        }
                        reportMap[estado][tipoDenuncia]++;
                    }
                }
            }
            else {
                if (estado !== "unknown") {
                    if (!reportMap[estado]) {
                        reportMap[estado] = {};
                    }

                    if (!reportMap[estado][tipoDenuncia]) {
                        reportMap[estado][tipoDenuncia] = 0;
                    }
                    reportMap[estado][tipoDenuncia]++;
                }
            }
        }
    });


    return reportMap;

}


export const innovaStacked = (dataTable, catalogo) => {
    const reportMap = {};

    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    dataTable.data[0].data[2].forEach((subArray) => {
        let estado = "unknown";
        let pathValue = "";

        const pathIndex = dataTable.data[0].data[5].findIndex((item, idx) => idx === dataTable.data[0].data[2].indexOf(subArray));
        if (pathIndex !== -1) {
            pathValue = dataTable.data[0].data[5][pathIndex].path || "";
        }

        subArray.forEach((item) => {
            if (item.key === catalogo) {
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = capitalizeWords(estado);
            }
        });

        if (estado !== "unknown") {
            const key = `${estado}|${pathValue}`;

            if (!reportMap[key]) {
                reportMap[key] = 0;
            }
            reportMap[key]++;
        }
    });

    const result = Object.entries(reportMap).map(([key, count]) => {
        const [estado, path] = key.split('|');
        return {
            estado: estado,
            path: path,
            count: count
        };
    });


    const newDataValue = Object.entries(reportMap).map(([key, count]) => {
        const [estado, path] = key.split('|');

        return {
            category: estado,
            value: count,
            severity: [{ path }]
        };
    });



    const listita = newDataValue.filter(element => element.value !== 0);

    let listitaCompleta = [];

    listita.forEach(element => {
        let countLow = 0;
        let countMedium = 0;
        let countHigh = 0;

        element.severity.forEach(subelement => {
            switch (subelement.path) {
                case "type::low":
                    countLow += element.value;
                    break;
                case "type::medium":
                    countMedium += element.value;
                    break;
                case "type::hight":
                    countHigh += element.value;
                    break;
                default:
                    break;
            }
        });

        if (countLow === 0) countLow = undefined;
        if (countMedium === 0) countMedium = undefined;
        if (countHigh === 0) countHigh = undefined;

        if (countLow !== undefined || countMedium !== undefined || countHigh !== undefined) {
            listitaCompleta.push({
                state: element.category,
                low: countLow,
                medium: countMedium,
                high: countHigh,
            });
        }
    });


    const combinedResults = {};

    listitaCompleta.forEach(item => {
        const { state, low, medium, high } = item;

        if (!combinedResults[state]) {
            combinedResults[state] = {
                state,
                low: low || 0,
                medium: medium || 0,
                high: high || 0,
            };
        } else {
            if (low) combinedResults[state].low += low;
            if (medium) combinedResults[state].medium += medium;
            if (high) combinedResults[state].high += high;
        }
    });

    const finalResults = Object.values(combinedResults);

    listitaCompleta = finalResults

    return listitaCompleta;

}


export const innovaMexico = (dataTable, catalogo) => {
    const reportMap = {};


    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    dataTable.data[0].data[2].forEach((subArray) => {
        let estado = "unknown";
        let tipoDenuncia = "unknown";

        subArray.forEach((item) => {
            if (item.key === catalogo) {
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = capitalizeWords(estado);
            }
            if (item.key === 'C::OWN::tipo_de_reporte') {
                tipoDenuncia = item.value.replace(/type::/g, '');
                tipoDenuncia = capitalizeWords(tipoDenuncia);
            }
        });

        if (estado !== "unknown") {
            const estadoKey = estado;

            if (estadoKey) {
                if (!reportMap[estadoKey]) {
                    reportMap[estadoKey] = 0;
                }
                reportMap[estadoKey]++;

            }
        }

    });
    return reportMap;
}
export const innovaMexicoZona = (dataTable, catalogo) => {



    const reportMap = {
        Norte: 0,
        Centro: 0,
        Sur: 0
    };

    const zonas = {
        norte: ["AGS", "BC", "BCS", "CHIH", "COAH", "DGO", "NL", "SIN", "SON", "TAMPS", "ZAC"],
        centro: ["CDMX", "EDOMEX", "GTO", "Hidalgo", "JAL", "MICH", "QRO", "SLP", "NAY", "HGO", "COL"],
        sur: ["CAM", "GRO", "MOR", "PUE", "QROO", "TAB", "VER", "YUC", "OAX", "CHP"]
    };




    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    dataTable.data[0].data[2].forEach((subArray) => {
        let estado = "unknown";

        subArray.forEach((item) => {
            if (item.key === catalogo) {
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = capitalizeWords(estado);
            }
        });

        if (estado !== "unknown") {
            if (estado.split(" ")[0] == "INNO") {
                // Determina la zona del estado y suma al total de la zona correspondiente
                estado = estado.split(" ")[1];

                if (zonas.norte.includes(estado)) {
                    reportMap.Norte++;
                } else if (zonas.centro.includes(estado)) {
                    reportMap.Centro++;
                } else if (zonas.sur.includes(estado)) {
                    reportMap.Sur++;
                }
            }
        }
    });

    return reportMap;
};

export const innovaSpecific = (dataTable, catalogo) => {
    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    let transformedMap = {};

    const reportMap = {};

    dataTable.data[0].data[2].forEach((subArray) => {
        let tipoCentro = "unknown";
        let tipoDenuncia = "unknown";
        let catalogo = "unknown";
        let banner = "unknown";

        subArray.forEach((item) => {
            // Filtra únicamente los centros de trabajo que contengan 'innovasport'

            
            if (item.key === 'C::OWN::donde_sucedieron_los_hechos' && item.value && item.value.includes("INNO")) {
                tipoCentro = item.value.replace(/type::/g, '').replace(/_/g, ' ').split("::").pop();
                tipoCentro = capitalizeWords(tipoCentro); // Capitalizar adecuadamente el nombre
                banner = tipoCentro.split(" ")[0];
                tipoCentro = tipoCentro.split(" ")[1];
            }

            // Identificar el catálogo (padre) al que pertenece cada tipo de denuncia
            // if (["C::OWN::falta_de_probidad", "C::OWN::dignidad_humana", "C::OWN::diversidad_e_inclusión"].includes(item.key) && item.value) {
            //     catalogo = item.key.replace("C::OWN::", ""); // Extrae el nombre del catálogo
            //     tipoDenuncia = capitalizeWords(item.value.replace(/type::/g, '').replace(/_/g, ' ')); // Limpia y capitaliza el value
            // }

            if (["C::OWN::tipo_de_reporte"].includes(item.key) && item.value) {
                catalogo = item.key.replace("C::OWN::", ""); // Extrae el nombre del catálogo
                tipoDenuncia = capitalizeWords(item.value.replace(/type::/g, "").replace(/_/g, " "));
              }
        });

        // Solo agregar datos si tipoCentro es 'innovasport' y catalogo y tipoDenuncia no son desconocidos
        if (banner === "INNO" && catalogo !== "unknown" && tipoDenuncia !== "unknown") {

            if (tipoDenuncia === "Conflicto De Interés") {
              tipoDenuncia = "Falta de Probidad::Conflicto De Interés"
            }
      
            if (!reportMap[catalogo]) {
                reportMap[catalogo] = {};
            }
      
            if (!reportMap[catalogo][tipoDenuncia]) {
                reportMap[catalogo][tipoDenuncia] = 0;
            }

            reportMap[catalogo][tipoDenuncia]++;
          }
      
    });


    for (const key in reportMap.tipo_de_reporte) {
        const value = reportMap.tipo_de_reporte[key];
    
        // Divide las claves que contienen "::"
        const [generalCategory, subCategory] = key.split("::");
    
        if (!transformedMap[generalCategory]) {
          transformedMap[generalCategory] = {};
        }
    
        transformedMap[generalCategory][subCategory || ""] = value;
      }
    
      // Agregar totales por categoría al transformedMap
      for (const generalCategory in transformedMap) {
        let total = 0;
    
        // Sumar valores de todas las subcategorías
        for (const subCategory in transformedMap[generalCategory]) {
          total += transformedMap[generalCategory][subCategory];
        }
    
        // Agregar el total al inicio con el mismo nombre de la categoría
        transformedMap[generalCategory][generalCategory] = total;
      }


    return transformedMap;
}