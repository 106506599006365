import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos

export const LineDoubleOpenClose = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(
        `LineDoubleOpenClose-${Math.random().toString(36).substr(2, 9)}`
    ); // Genera un ID único para cada gráfico
    const diaInicio = startDate;
    const diaFin = endDate;
    const graphicTitle = chartData.title

    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {
                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

                const root = am5.Root.new(chartDivId.current);
                // root.setThemes([am5themes_Animated.new(root)]);
                root.setThemes([MyTheme.new(root)]);

                newChart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panX: true,
                        panY: true,
                        layout: root.verticalLayout,
                        wheelX: "panX",
                        wheelY: "zoomX",
                        paddingLeft: 0,
                        pinchZoomX: true
                    })
                );

                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", true);

                var date = new Date();
                date.setHours(0, 0, 0, 0);

                const newData = chartData.data.reduce((acc, item) => {
                    if (item.label === "CERRADO" || item.label === "Todos los Reportes") {
                        item.data[0].forEach((_, index) => {
                            if (item.label === "Todos los Reportes") {
                                var date = new Date(Date.now() - item.data[1][index] * 24 * 60 * 60 * 1000).toDateString();
                            } else {
                                const reportDates = item.data[3][index]['assigned'];
                                const today = new Date();
                                const oneDay = 24 * 60 * 60 * 1000; // Milisegundos en un día
                                var lista = [];
                                lista.push(reportDates);
                                const daysSinceToday = lista.map((dateString) => {
                                    const reportDate = new Date(dateString.replace(/-/g, "/")); // Convertir a formato de fecha
                                    const diffDays = Math.round((today - reportDate) / oneDay); // Calcular diferencia en días
                                    return diffDays; // Devolver solo el número de días
                                });
                                daysSinceToday.sort((a, b) => b - a); // Ordenar la lista de diferencias en días
                                var date = new Date(Date.now() - daysSinceToday[0] * oneDay).toDateString();
                            }
                            const existingData = acc.find((data) => data.date === date);
                            if (!existingData) {
                                acc.push({
                                    date: date,
                                    value: item.label === "CERRADO" ? 0 : 0,
                                    value2: item.label === "Todos los Reportes" ? 0 : 0,
                                });
                            }
                            if (item.label === "CERRADO") {
                                const dataIndex = acc.findIndex((data) => data.date === date);
                                acc[dataIndex].value += 1;
                            }
                            if (item.label === "Todos los Reportes") {
                                const dataIndex = acc.findIndex((data) => data.date === date);
                                acc[dataIndex].value2 += 1;
                            }
                        });
                    }
                    return acc;
                }, []);

                // Obtener la fecha más antigua y la más reciente
                const allDates = newData.map(data => new Date(data.date));
                const minDate = new Date(Math.min(...allDates));
                const maxDate = new Date(Math.max(...allDates));

                // Crear un rango de fechas desde la más antigua hasta la más reciente
                const allDatesInRange = [];
                let currentDate = new Date(minDate);
                while (currentDate <= maxDate) {
                    allDatesInRange.push(new Date(currentDate).toDateString());
                    currentDate.setDate(currentDate.getDate() + 1); // Incrementar la fecha en un día
                }

                // Agregar todas las fechas al conjunto de datos
                allDatesInRange.forEach(date => {
                    const existingData = newData.find(data => data.date === date);
                    if (!existingData) {
                        newData.push({
                            date: date,
                            value: 0,
                            value2: 0
                        });
                    }
                });

                // Ordenar los datos por fecha
                newData.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Transformar los datos a un formato final
                const transformedData = newData.map((data) => ({
                    value: data.value2,
                    value2: data.value,
                    date: new Date(data.date).getTime(),
                }));
                transformedData.sort((a, b) => b.date - a.date);


                var cursor = newChart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "none"
                }));
                cursor.lineY.set("visible", true);



                const xAxis = newChart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        maxDeviation: 0.3,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minorGridEnabled: true
                        }),
                        tooltip: am5.Tooltip.new(root, {}),
                    })
                );

                xAxis.set("minorDateFormats", {
                    day: "dd",
                    month: "MM"
                });

                xAxis.data.setAll(transformedData.map(item => item.data));

                const yAxis = newChart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom" }),
                        maxDeviation: 0.3,
                    })
                );

                const series = newChart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        valueXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}"
                        })
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2
                });

                series.get("tooltip").get("background").set("fillOpacity", 0.5);

                newChart.set("scrollbarX", am5.Scrollbar.new(root, {
                    orientation: "horizontal"
                }));


                const series2 = newChart.series.push(am5xy.LineSeries.new(root, {
                    name: "Series 2",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value2",
                    valueXField: "date",

                }));
                series2.strokes.template.setAll({
                    strokeDasharray: [2, 2],
                    strokeWidth: 2
                });
                root.dateFormatter.setAll({
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["valueX"]
                });

                series.data.setAll(
                    transformedData.map((item) => ({
                        value: item.value,
                        value2: item.value2,
                        date: item.date
                    }))
                );
                series2.data.setAll(
                    transformedData.map((item) => ({
                        value: item.value,
                        value2: item.value2,
                        date: item.date
                    }))
                );


                // var exporting = am5plugins_exporting.Exporting.new(root, {
                //     menu: am5plugins_exporting.ExportingMenu.new(root, {
                //         align: "right",
                //         valign: "bottom",
                //     }),
                // });


                var title = newChart.children.push(am5.Label.new(root, {
                    text: graphicTitle,
                    fontSize: 20,
                    x: am5.percent(50),
                    y: am5.percent(10),
                    centerX: am5.percent(50),
                    visible: false // Ocultar el título inicialmente
                }));

                // Configuración de exportación
                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    }),
                    pdfOptions: {
                        addURL: false
                    }
                });

                // Evento para mostrar el título solo durante la exportación a PDF
                exporting.events.on("exportstarted", function (event) {
                    if (event.format === "pdf") {
                        title.set("visible", true); // Mostrar el título solo al exportar a PDF
                    }
                });

                exporting.events.on("exportfinished", function (event) {
                    title.set("visible", false); // Ocultar el título nuevamente después de exportar
                });


                series.appear(1000);
                series2.appear(1000);
                newChart.appear(1000, 100);

                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };

        if (typeof window !== "undefined") {
            generateChart();
        }

        return () => {
            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });

            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return (
        <div
            id={chartDivId.current}
            style={{ width: "100%", height: "500px" }}
        ></div>
    );
};

export default LineDoubleOpenClose;
