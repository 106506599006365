import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map.js";
import map from "@amcharts/amcharts5-geodata/mexicoLow.js";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "../../colors"; // Importar los colores definidos



export const InnovaMexicoGeoChart = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`mexicochart-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate

// console.log("CHARTDATA", chartData);


    let catalogo = "";


    if (chartData.show_graphic_graphic) {
        let newCatalogo = "donde_sucedieron_los_hechos";
        // let newCatalogo = "";
        // const tipo = chartData.show_graphic_graphic;

        // switch (tipo) {
        //     case '33':
        //         newCatalogo = "estado_innovasport";
        //         break;
        //     case '34':
        //         newCatalogo = "estado_invictus";
        //         break;
        //     case '35':
        //         newCatalogo = "estado_overtime";
        //         break;
        //     case '36':
        //         newCatalogo = "estado_culto";
        //         break;
        //     case '37':
        //         newCatalogo = "ameshop_estado";
        //         break;
        //     default:
        //         newCatalogo = catalogo;
        // }

        if (newCatalogo && newCatalogo !== catalogo) {
            catalogo = 'C::OWN::' + newCatalogo;
        }
    }

     // "Tlaxcala": "MX-TLA",
    const stateMapping = {
        "ZAC": "MX-ZAC",
        "YUC": "MX-YUC",
        "VER": "MX-VER",
        "TAMPS": "MX-TAM",
        "TAB": "MX-TAB",
        "SON": "MX-SON",
        "SIN": "MX-SIN",
        "SLP": "MX-SLP",
        "QROO": "MX-ROO",
        "QRO": "MX-QUE",
        "PUE": "MX-PUE",
        "OAX": "MX-OAX",
        "NL": "MX-NLE",
        "NAY": "MX-NAY",
        "MOR": "MX-MOR",
        "MICH": "MX-MIC",
        "EDOMEX": "MX-MEX",
        "JAL": "MX-JAL",
        "HGO": "MX-HID",
        "GTO": "MX-GUA",
        "DGO": "MX-DUR",
        "CDMX": "MX-CMX",
        "COL": "MX-COL",
        "COAH": "MX-COA",
        "CHIH": "MX-CHH",
        "Chiapas": "MX-CHP",
        "CAM": "MX-CAM",
        "BCS": "MX-BCS",
        "BC": "MX-BCN",
        "AGS": "MX-AGU",
        "GRO":"MX-GRO"
    };

    const stateReportMap = {};
    const estadoAbreviadoYCompleto = []; 

    function capitalizeWords(str) {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    chartData.data[0].data[2].forEach((subArray) => {
        let estado = "unknown";
        let tipoDenuncia = "unknown";

        // subArray.forEach((item) => {
        //     if (item.key === catalogo) {
        //         estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
        //         estado = capitalizeWords(estado);  // Capitaliza el estado
        //     }
        //     if (item.key === 'C::OWN::seleccione_el_tipo_de_denuncia_que_va_a_reportar') {
        //         tipoDenuncia = item.value.replace(/type::/g, '');
        //         tipoDenuncia = capitalizeWords(tipoDenuncia);  // Capitaliza el tipo de denuncia
        //     }
        // });

        subArray.forEach((item) => {
            if (item.key === catalogo) {
                
                estado = item.value.replace(/type::/g, '').replace(/_/g, " ").split('::')[0];
                estado = estado.split(" ");
                
                if (estado[0] === "INNO"){
                estado = estado[1];
                estado = capitalizeWords(estado);
                } else {
                    estado = "unknown";
                    
                }
                
            }
            if (item.key === 'C::OWN::seleccione_el_tipo_de_denuncia_que_va_a_reportar') {
                tipoDenuncia = item.value.replace(/type::/g, '');
                tipoDenuncia = capitalizeWords(tipoDenuncia);  // Capitaliza el tipo de denuncia
            }
        });

        if (estado !== "unknown") {
            // Obtener la nomenclatura correcta para el estado
            
            const estadoKey = stateMapping[estado];

            if (estadoKey) {
                // Aumentar el conteo total para el estado
                if (!stateReportMap[estadoKey]) {
                    stateReportMap[estadoKey] = 0;
                }
                stateReportMap[estadoKey]++;

                // Agregar la relación de estado abreviado y completo al nuevo arreglo
                estadoAbreviadoYCompleto.push({ abreviado: estadoKey, completo: estado });
            }
        }
    });

    // console.log("Mapa de estados y cantidades totales:", stateReportMap);
    // console.log("Relación de estados abreviados y completos:", estadoAbreviadoYCompleto);



    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {
                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);

                newChart = root.container.children.push(
                    am5map.MapChart.new(root, {
                        projection: am5map.geoMercator()
                    })
                );

                var bubbleSeries = newChart.series.push(
                    am5map.MapPointSeries.new(root, {
                        calculateAggregates: true,
                        valueField: "value",
                        polygonIdField: "id",
                    })
                );

                function interpolateColor(value, min, max) {
                    const segmentSize = (max - min) / 3;
                    const firstSegment = min + segmentSize;
                    const secondSegment = firstSegment + segmentSize;

                    if (value <= firstSegment) {
                        return "rgb(68, 203, 103)";
                    } else if (value <= secondSegment) {
                        return "rgb(254, 175, 0)";
                    } else {
                        return "rgb(222, 70, 61)";
                    }
                }

                const minLength = 1;

                const maxLength = Math.max(
                    ...Object.values(stateReportMap)
                        .map(cantidad => {
                            return typeof cantidad === 'number' ? cantidad : 0;
                        })
                );

                const finalMaxLength = maxLength === -Infinity ? 0 : maxLength;

                var polygonSeries = newChart.series.push(
                    am5map.MapPolygonSeries.new(root, {
                        geoJSON: map
                    })
                );

                polygonSeries.mapPolygons.template.setAll({
                    strokeWidth: 0.5,
                    stroke: root.interfaceColors.get("background"),
                    tooltipText: "{name}: {value}", 
                    templateField: "polygonTemplate"
                });

                const processedData = Object.entries(stateReportMap).map(([estadoAbreviado, cantidadTotal]) => {
                    const estadoCompleto = Object.keys(stateMapping).find(key => stateMapping[key] === estadoAbreviado) || estadoAbreviado;

                    const polygonColor = interpolateColor(cantidadTotal, minLength, maxLength);

                    return {
                        id: estadoAbreviado, 
                        name: estadoCompleto,
                        value: cantidadTotal,
                        polygonTemplate: {
                            fill: am5.color(polygonColor), 
                            tooltipText: `${estadoCompleto}: {value}` 
                        }
                    };
                });






                const rango = (maxLength - minLength) / 3;

                // Contenedor de la leyenda
                var legendContainer = root.container.children.push(
                    am5.Container.new(root, {
                        layout: root.verticalLayout,
                        x: am5.p100,
                        centerX: am5.p100,
                        marginRight: 15,
                        y: am5.p50,
                        centerY: am5.p50
                    })
                );

                // Crear los items de la leyenda usando los rangos
                function createLegendItem(color, label, min, max) {
                    var legendItem = legendContainer.children.push(
                        am5.Container.new(root, {
                            layout: root.horizontalLayout,
                            paddingTop: 5,
                            paddingBottom: 5
                        })
                    );

                    // Crear el cuadro de color
                    legendItem.children.push(
                        am5.Rectangle.new(root, {
                            width: 15,
                            height: 15,
                            fill: am5.color(color),
                            stroke: am5.color(0x000000),
                            strokeWidth: 1
                        })
                    );

                    // Añadir el texto de la leyenda con valores redondeados hacia abajo
                    legendItem.children.push(
                        am5.Label.new(root, {
                            text: `${label}: ${Math.floor(min)} - ${Math.floor(max)}`,
                            x: 10,  // espacio entre el cuadro y el texto
                            y: -4,
                            fontSize: 14,
                            fill: am5.color(0x000000)
                        })
                    );
                }

                // Añadir los tres niveles de gravedad con los rangos
                createLegendItem(0x44CB67, "Cantidad de denuncias", minLength, minLength + rango);           // Verde
                createLegendItem(0xFEAF00, "Cantidad de denuncias", minLength + rango, minLength + 2 * rango); // Amarillo
                createLegendItem(0xDE463D, "Cantidad de denuncias", minLength + 2 * rango, maxLength);


                // Configura el color de fondo del mapa a negro para los estados sin datos
                polygonSeries.mapPolygons.template.setAll({
                    fill: am5.color(0xD3D3D3),
                    fillOpacity: 1,
                    strokeWidth: 0.5,
                    stroke: root.interfaceColors.get("background"),
                });

                // Asignar los datos procesados a la serie de polígonos
                polygonSeries.data.setAll(processedData);

                bubbleSeries.data.setAll(processedData);

                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    })
                });


                // Add legend
                newChart.legend = am5.Legend.new(root, {});

                newChart.appear(1000, 100);

                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };


        generateChart();




        return () => {

            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });


            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default InnovaMexicoGeoChart;