import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FileUpload,
  MCButton,
  MCInput,
  MCLabel,
} from "../../../MainComponents";
import { Loading } from "../../../Loading/String/Loading";
import "./Resolution.scss";
import { FileUploadJustLinks } from "../../../MainComponents/FileUpload/FileUploadJustLinks";


export const GeneralDataResolution = ({ resolution, setActiveTab, reportDetail }) => {
  const [t] = useTranslation("report");
  const {
    date,
    setDate,
    setObservations,
    observations,
    comment,
    setComment,
    files,
    setFiles,
    filesToRender,
    setFilesToRender,
    loading,
    sendResolutionData,
  } = resolution;



  const [enableReadMode, setEnableReadMode] = useState(false);

  useEffect(() => {
    if (reportDetail != "finish")
      setEnableReadMode(true);

  }, [])


  return (
    enableReadMode === false ? (
      <div className="dyBorder1 rounded">
        <div className="resolutions__title mt-4 mb-3 px-3">
          <h4>{t("Resolution.general_details_resolution_report")}</h4>
          <MCButton
            label={t("Resolution.direct_message_to_complainant")}
            icon={"outline_messages_4"}
            variant="light"
            className="resolutions__btnAction"
            onClick={() => {
              setActiveTab("Mensajes");
            }}
          />
        </div>

        <div className="px-3">
          <MCLabel
            className="px-3 py-2 mt-1"
            text={`${t("Resolution.determine_resolution_date")}:`}
            htmlFor="scheduleResolutionDate"
          />
          {date ? date.toLocaleDateString() :
            (<>
              <MCInput
                className="px-3"
                id="scheduleResolutionDate"
                type="date"
                selected={date}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setDate(date)}
              />
            </>)}
          <MCLabel
            htmlFor="observations_status_change"
            className="my-2 mx-3"
            text={`${t("Resolution.observations_status_change")}:`}
          />
          <MCInput
            type="textarea"
            rows={4}
            id="observations_status_change"
            onChange={(e) => {
              setObservations(e.target.value);
            }}
            value={observations}
            placeholder={t(
              "Resolution.include_relevant_features_status_change_resolution"
            )}
          />
          <hr />
          <MCLabel
            text={`${t("Resolution.resolution_general_comments")}:`}
            className="mb-2 mx-3"
          />

          <MCInput
            type="textarea"
            placeholder={t(
              "Resolution.describes_detail_general_comments_resolution_report"
            )}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={5}
          />

          <hr />
          <MCLabel
            text={`${t("Resolution.resolution_support_file_upload")} (${t(
              "Resolution.optional"
            )}):`}
            className="my-2 mx-3"
          />
          <FileUpload
            files={files}
            setFiles={setFiles}
            filesToRender={filesToRender}
            setFilesToRender={setFilesToRender}
          />
        </div>
        <div className="resolutions__sendResolutions">
          {loading ? (
            <Loading />
          ) : (
            <MCButton
              label={t("Resolution.save")}
              variant="success"
              icon={`${observations.trim() === "" ? "" : "check_circle"}`}
              disabled={
                observations.trim() === "" &&
                comment.trim() === "" &&
                files.length === 0
              }
              outline={
                observations.trim() === "" &&
                comment.trim() === "" &&
                files.length === 0
              }
              onClick={sendResolutionData}
            />
          )}
        </div>
      </div>
    ) : (

















      <div className="readMode dyBorder1 rounded">
        <div className="px-3">
          <MCLabel
            className="px-3 py-2 mt-1"
            text={`${t("Resolution.determine_resolution_date")}:`}
            htmlFor="scheduleResolutionDate"
          />
          {date ? date.toLocaleDateString() :
            (<>
              <MCInput
                className="px-3"
                id="scheduleResolutionDate"
                type="date"
                selected={date}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setDate(date)}
              />
            </>)}
          <MCLabel
            htmlFor="observations_status_change"
            className="my-2 mx-3"
            text={`${t("Resolution.observations_status_change")}:`}
          />
          {observations ? observations :
            (<>

              <MCLabel
                text={`${t("Resolution.no_observations")}:`}
                className="mb-2 mx-3"
              />
            </>)}

          <hr />
          <MCLabel
            text={`${t("Resolution.resolution_general_comments")}:`}
            className="mb-2 mx-3"
          />
          {comment ? comment :
            (<>
              <MCLabel
                text={`${t("Resolution.no_comments")}:`}
                className="mb-2 mx-3"
              />
            </>)}

          <hr />
          <MCLabel
            text={`${t("Resolution.resolution_support_file_upload")} (${t(
              "Resolution.optional"
            )}):`}
            className="my-2 mx-3"
          />
          <FileUploadJustLinks
            files={files}
            setFiles={setFiles}
            filesToRender={filesToRender}
            setFilesToRender={setFilesToRender}

          />
        </div>
      </div>
    )
  );
};
