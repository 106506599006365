import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting.js";
import { MyTheme } from "./../colors"; // Importar los colores definidos




export const DistibutionStateType = ({ chartData, startDate, endDate }) => {
    const [chart, setChart] = useState(null);
    const chartDivId = useRef(`DistibutionStateType-${Math.random().toString(36).substr(2, 9)}`); // Genera un ID único para cada gráfico
    const diaInicio = startDate
    const diaFin = endDate
    const graphicTitle = chartData.title



    useEffect(() => {
        let newChart = null;

        const generateChart = async () => {
            try {


                var startDate = new Date(diaInicio).getTime();
                var endDate = new Date(diaFin).getTime();
                const endDatePlusOneDay = new Date(endDate);
                endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);


                const root = am5.Root.new(chartDivId.current);
                root.setThemes([MyTheme.new(root)]);


                var zoomableContainer = root.container.children.push(
                    am5.ZoomableContainer.new(root, {
                        width: am5.p100,
                        height: am5.p100,
                        wheelable: true,
                        pinchZoom: true
                    })
                );

                // var zoomTools = zoomableContainer.children.push(am5.ZoomTools.new(root, {
                //     target: zoomableContainer
                // }));

                newChart = root.container.children.push(
                    am5percent.PieChart.new(root, {
                        layout: root.verticalLayout,
                    })
                );

                var series = zoomableContainer.contents.children.push(
                    am5hierarchy.Treemap.new(root, {
                        maskContent: false, //!important with zoomable containers
                        sort: "descending",
                        singleBranchOnly: false,
                        downDepth: 2,
                        upDepth: 0,
                        initialDepth: 2,
                        valueField: "value",
                        categoryField: "name",
                        childDataField: "children",
                        nodePaddingOuter: 0,
                        nodePaddingInner: 0
                    })
                );

                series.nodes.template.events.on("click", function (ev) {
                    var depth = ev.target.dataItem.get("depth");
                    if (depth == 2) {
                        series.selectDataItem(ev.target.dataItem.get("parent"));
                    }
                })

                series.labels.template.set("minScale", 0);
                series.get("colors").set("step", 1);



                const newDataValue = chartData.data.map((item) => {
                    return {
                        category: item.label,
                        value: item.data[0].filter((value, index) => {
                            const date = new Date(Date.now() - (parseInt(item.data[1][index]) * 24 * 60 * 60 * 1000)).getTime();
                            if (startDate === 0 && endDate === 0) {
                                return true;
                            } else {
                                return date >= startDate && date <= endDatePlusOneDay;
                            }
                        }).length,
                        metadata: item.data[4]

                    };
                });
                let listita = [];
                newDataValue.forEach(element => {
                    if (element.value !== 0) {
                        listita.push({
                            category: element.category,
                            value: element.value,
                            metadata: element.metadata
                        })
                    }
                });


                let listitaCompleta = [];

                listita.forEach(element => {
                    let counters = {};

                    element.metadata.forEach(item => {
                        if (item.catalogue === "RC-100") {
                            let tipo;

                            if (item.value) {
                                tipo = item.value.split("::")[1];
                            } else {
                                tipo = "Unknown";
                            }

                            if (!counters[tipo]) {
                                counters[tipo] = 0;
                            }

                            counters[tipo]++;
                        }
                    });

                    let result = {
                        state: element.category
                    };

                    for (let key in counters) {
                        result[key] = counters[key];
                    }

                    let existing = listitaCompleta.find(entry => entry.state === element.category);

                    if (existing) {
                        for (let key in result) {
                            if (key !== 'state') {
                                if (!existing[key]) {
                                    existing[key] = 0;
                                }
                                existing[key] += result[key];
                            }
                        }
                    } else {
                        listitaCompleta.push(result);
                    }
                });


                function sumaDenuncias(item) {
                    return (item.low || 0) + (item.medium || 0) + (item.high || 0);
                }

                listitaCompleta.sort((a, b) => sumaDenuncias(a) - sumaDenuncias(b));



                let data = listitaCompleta
                function processData(data) {
                    var treeData = [];


                    var smallBrands = { name: "Other", children: [] };
                    var index = 0;

                    am5.object.eachOrdered(
                        data,
                        (brand) => {
                            var brandData = { name: data[index].state, children: [] };
                            var brandTotal = 0;

                            for (let key in data[index]) {
                                if (key !== 'state' && data[index][key] != undefined) {
                                    brandTotal += data[index][key];
                                }
                            }

                            for (let key in data[index]) {

                                if (key !== 'state' && data[index][key] != undefined) {

                                    let tipo = key.replace(/type::/g, "\n");
                                    tipo = tipo.replace(/::/g, "")
                                    brandData.children.push({
                                        // name: key.replace(/type::/g, "\n"), 
                                        name: data[index].state + " " + tipo,
                                        value: data[index][key],
                                        fill: am5.color(0x677935)
                                    });
                                }
                            }
                            if (brandTotal >= 1) {
                                treeData.push(brandData);
                            } else {
                                smallBrands.children.push(brandData);
                            }

                            index++;
                        },
                        (a, b) => {
                            let aval = 0;
                            let bval = 0;
                            am5.object.each(data[a], (key, val) => (aval += val));
                            am5.object.each(data[b], (key, val) => (bval += val));
                            if (aval > bval) return -1;
                            if (aval < bval) return 1;
                            return 0;
                        }
                    );

                    if (smallBrands.children.length > 0) {
                        treeData.push(smallBrands);
                    }


                    return [{
                        name: "Root",
                        children: treeData
                    }];
                }

                series.data.setAll(processData(data));
                series.set("selectedDataItem", series.dataItems[0]);


                // var exporting = am5plugins_exporting.Exporting.new(root, {
                //     menu: am5plugins_exporting.ExportingMenu.new(root, {
                //         align: "right",
                //         valign: "bottom"
                //     })
                // });





                var title = newChart.children.push(am5.Label.new(root, {
                    text: graphicTitle,
                    fontSize: 20,
                    x: am5.percent(50),
                    y: am5.percent(0),
                    centerX: am5.percent(50),
                    visible: false // Ocultar el título inicialmente
                }));

                // Configuración de exportación
                var exporting = am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {
                        align: "right",
                        valign: "bottom"
                    }),
                    pdfOptions: {
                        addURL: false
                    }
                });

                // Evento para mostrar el título solo durante la exportación a PDF
                exporting.events.on("exportstarted", function (event) {
                    if (event.format === "pdf") {
                        title.set("visible", true); // Mostrar el título solo al exportar a PDF
                    }
                });

                exporting.events.on("exportfinished", function (event) {
                    title.set("visible", false); // Ocultar el título nuevamente después de exportar
                });




                // // Add legend
                newChart.legend = am5.Legend.new(root, {});


                if (chart) {
                    chart.dispose();
                }

                setChart(newChart);
            } catch (error) {
                console.error("Error al cargar las bibliotecas de AmCharts:", error);
            }
        };


        generateChart();




        return () => {

            am5.array.each(am5.registry.rootElements, function (root) {
                if (root) {
                    if (root.dom.id == chartDivId.current) {
                        root.dispose();
                    }
                }
            });


            if (chart) {
                chart.dispose();
            }
        };
    }, [chartData, startDate, endDate]);

    return <div id={chartDivId.current} style={{ width: "100%", height: "500px" }}></div>;
};

export default DistibutionStateType;